import { deepOrange, orange, red, yellow } from "@material-ui/core/colors";
import palette from "theme/palette";


const maxWidth = 1200;
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: "objektiv-mk2",
  },
  rootPaper: {
    maxWidth: `${maxWidth}px !important`,
    maxHeight: "calc(100% - 32px) !important",
    fontFamily: "objektiv-mk2",
  },
  rootPaperMainDialog: {
    height: "calc(100% - 38px) !important",
  },
  rootPaperDecorative: {
    borderRadius: "0px !important",
    fontFamily: "objektiv-mk2",
    border: "1.5px solid #8EA2AD", //#757374
    margin: "0",
    background: "#FBFBF5",
    fontFamily: "objektiv-mk2",
  },
  scrollPaperHeight: {
    height: "calc(100% - 32px) !important",
  },
  rootPaperRadius: {
    borderRadius: "4px !important",
  },
  backDropBackgroundDark: {
    background: "rgba(0, 0, 0, 0.8)",
  },
  backDropBackgroundLightBlue: {
    background: 'rgba(233, 247, 254, 0.8)',
  },
  headerMain: {
    padding: 0,
    margin: 0,
    width: "100%",
    fontFamily: "objektiv-mk2 !important",
  },
  logoHeader: {
    backgroundColor: "#4A7396 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    padding: "8px 16px",
    "&>*:nth-child(2)": {
      "&>*:nth-child(1)": {
        fontstyle: "italic",
        fontSize: "10px",
        //fontWeight: '400'
      },
      "&>*:nth-child(2)": {
        //fontstyle: "italic",
        fontSize: "17.5px",
        fontWeight: "800",
        letterSpacing: "0.1em",
      },
    },
    "&>*:nth-child(3)": {
      fontSize: "13px",
      fontWeight: "450",
    },
  },
  imageLogo: {
    width: "140px",
  },
  headerExtraDetails: {
    backgroundColor: "#EDF7F8",
    // -webkit-box-shadow: 0 8px 6px -6px black;
    //    -moz-box-shadow: 0 8px 6px -6px black;
    boxShadow: "-4px 8px 8px -6px #666",
    marginBottom: "8px",
  },
  finishLaterDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 64px",
  },
  disabledButton: {
    pointerEvents: 'none',
    opacity: '0.7'
  },
  outlinedButton: {
    fontSize: "11px",
    borderRadius: "16px",
    padding: "4px 16px",
    border: "1px solid #4A7396",
    background: "white",
    fontWeight: "200",
    cursor: "pointer",
    color: "#4A7396",
    "&:hover": {
      background: "#edf7f8",
    },
  },
  filledButton: {
    fontSize: "15px",
    borderRadius: "4px",
    padding: "1px 16px",
    border: "1px solid #4A7396",
    background: "#4A7396",
    fontWeight: "500",
    color: "#4A7396",
    letterSpacing: "0.025em",
    cursor: "pointer",
  },
  textButton: {
    fontSize: "12px",
    color: "#4A7396",
    fontWeight: "500",
    textDecoration: "underline",
    cursor: "pointer",
  },
  mailingDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 32px",
  },
  nameContainer: {
    fontSize: "12px",
    letterSpacing: "0.025em",
    fontWeight: "800",
    minHeight: "32px",
  },
  centerCenterAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addressContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  verticalLineBorder: {
    borderRight: "1.5px solid #4A7396",
    height: "100%",
    paddingRight: "12px",
    marginRight: "12px",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  addressHeading: {
    color: "#4A7396",
    fontSize: "10.5px",
    lineHeight: "14.5px",
  },
  addressDetail: {
    lineHeight: "18px",
    letterSpacing: "0.01em",
    fontSize: "11px",
    //fontWeight: "800",
    textTransform: "capitalize",
  },
  headerButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 32px",//64px
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start !important'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end !important'
  },
  headerButtonandExtraFirst: {
    width: "25%",
  },
  headerButtonandExtraSecond: {
    width: "44%",
  },
  headerButtonandExtraThird: {
    width: "31%",
  },
  headerButtonandExtraDisplay: {
    display: "flex",
    justifyContent: "flex-end",
  },
  headerExtra: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    fontSize: "12px",
    padding: "16px 32px 12px 32px",
  },
  specialGlowText: {
  //textShadow: '0 0 1px orangered',
  color: red[800],
  fontWeight: 800,
  fontSize: "13px",
  letterSpacing: "0.1em",
  marginTop: '8px',
  },
  absenteeHO: {
    fontWeight: 800,
    fontSize: "13px",
    letterSpacing: "0.1em",
    marginTop: '8px',
    color: red[800],
  },
  tagHO: {
    fontWeight: 800,
    fontSize: "11px",
    letterSpacing: "0.1em",
    color: palette.primary.currentLightBlue,
    textDecoration: 'underline',
    marginTop: '8px',
    cursor: 'pointer'
  },
  extraPurchaseDate: {
    fontSize: "10px",
  },
  dialogContentMain: {
    background: "#FBF9F7",
  },
  aaNoteContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 32px",
  },
  errorText: {
    textAlign: "center",
    fontSize: "12.5px",
    letterSpacing: "0.025em",
    marginTop: '12px'
  },
  errorLight: {
    color: red[400],
  },
  notePSView: {
    fontSize: '12px',
    color: 'black',
    fontFamily: "objektiv-mk2",
  },  wordBreakNotePS: {
    wordBreak: "break-word"
  },
  viewNoteUnfocused: {
    borderBottom: "1px solid #4A7396",//#8EA2AD
  },
  labelLikeText: {
    opacity: '1',
    color: '#A2A19F',
    fontSize: '14px',
    fontFamily: "objektiv-mk2",
    lineHeight: '17px'
  },
  noteTextareaSides: {
    width: "10%",
  },
  noteTextareaContainer: {
    width: "70%",
    borderLeft: "1.5px solid #4A7396",
    borderRight: "1.5px solid #4A7396",
    padding: "0 16px",
  },
  skippedMessage: {
    fontSize: '13px',
    color: red[800],//palette.primary.currentMailProColor,
    margin: '8px 0',
    textAlign: 'center',
    fontWeight: 500,
    lineHeight: '18px'
  },
  noteTextarea: {
    "& label": {
      fontFamily: "objektiv-mk2",
    },
    "& .MuiInputBase-input": {
      fontSize: "12px",
      lineHeight: "17px",
      fontFamily: "objektiv-mk2",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #4A7396",
    },
  },
  maxCharacterLimit: {
    textAlign: "right",
    fontSize: "10.5px",
    letterSpacing: "0.025em",
    fontStyle: "italic",
    color: "#4A7396",
    opacity: "0.7",
  },

  footerContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    flexDirection: "row",
    padding: "16px 0",
    background: "#FBF9F7",
  },
  footerContainerZero: {
      width: '15%',
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
  },
  footerContainerFirst: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "70%",
  },
  footerContainerSecond: {
    display: "flex",
    width: "15%",
    alignItems: "flex-start",
  },
  // footerOverrideNote: {
  //   color: deepOrange[500],
  //   padding: "4px 8px",
  //   lineHeight: "18px",
  //   fontSize: '13px',
  //   fontWeight: 500,
  // },

  addSubmitButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backButton: {
    textAlign: 'left',
    width: '100%',
    //marginLeft:' -10%'

  },
  anniversaryNoteCount: {
    textAlign: "center",
    fontSize: "12px",
    lineHeight: "13px",
  },
  //editor dialog
  editorDialog: {
    //16 both side padding
    width: `${maxWidth-32}px !important`,
    //16 top bottom each padding
    maxHeight: "calc(100% - 64px)",
  },
  editorDialogHeader: {
    //padding: '16px',
    textAlign: "center",
    // '&>*': {
    //     fontFamily: "objektiv-mk2"
    // },
    "&>*>div:first-child": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#4A7396",
      letterSpacing: "0.01em",
    },
    "&>*>div:last-child": {
      marginTop: "8px",
      fontSize: "16px",
      color: "#4A7396",
    },
  },
  editorDialogContent: {
    padding: '0',
    marginTop: '-8px'
  },
  extraGreetingText: {
    fontSize: "14px",
    lineHeight: "17px",
    color: "black !important",
    marginBottom: "12px",
  },
  editorDialogFooter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '16px'
  },
  //homeowner list
  homeownerListContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 32px",
  },
  redNoteText: {
    color: `${red[800]} !important`,
    fontSize: "13px",
    letterSpacing: "0.1em",
    fontWeight: 800,
  },
});

export default styles;
