import React, { useEffect, useState, Fragment, Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Button,
  Typography,
  CircularProgress,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Slide from "@material-ui/core/Slide";
import classNames from "classnames";
import styles from "../../styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Filter from "bad-words";
import { pmaActions } from "_store/_actions";
import CustomNoteTextMakerV2 from "./CustomNoteTextMakerV2";
import moment from "moment";
import CustomNoteDialogHeading from "./CustomNoteDialogHeading";
import { GetStateStorage } from "common/storage";
import { GlobalNotePSReviewDialogComponent } from "components";
import MailProNoteWarningDialog from "../../MailProNoteWarningDialog";
import { RichTextEditorV2Component } from "components";
import {
  agentBarSwitchClosureV2,
  MailProBlockedEditingDisabledBar,
  MailProCloseButton,
} from "../../MailProHTMLShortMethods";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";
import { noteUtils } from "../Common/NoteUtils";
import PersonalNoteOverride from "../Common/PersonalNoteOverride";
import { MaxAllowedLinesInNote, perLineComfortableNoteCharCount } from "../Common/NotesConfig";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CustomNoteDialogV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      personalNote: "", //the the personal note
      plainNote: "",
      personalPS: "", //thia is the note PS
      beforeEditNote: "",
      beforeEditPS: "",
      textPSDate: "",
      personalPSDate: "",
      submitted: false,
      error: "",
      isLoading: false,
      editMode: false,
      //lastPrintDate: null,
      isOverride: false,
      //defaultedToGlobalNoteText: false,
      overrideCameFrom: "",
      isBlocked: false,
      greetTextTop: "",
      signOffTextBottom: "",
      showNotePSReviewDialog: false,
      textForGrammerCheck: "",
      showGrammerSection: false,
      grammerChecked: true,
      grammerCheckLoading: false,
      showPSWarningDialog: false,
      currentEditMode: "",
    };
    //define note, ps lengths
    Object.assign(this, noteUtils.getPersonalNoteSizes());
    /**bind methods */
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    // this.getAfterPrintDateNoteByType = this.getAfterPrintDateNoteByType.bind(
    //   this
    // );
    this.getNoteDataObject = this.getNoteDataObject.bind(this);
    this.updateNote = this.updateNote.bind(this);
    this.resetNote = this.resetNote.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onOverride = this.onOverride.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.saveNoteChanges = this.saveNoteChanges.bind(this);
    this.filterNote = this.filterNote.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.updatePlainNote = this.updatePlainNote.bind(this);
    this.closeGlobalPSReviewDialog = this.closeGlobalPSReviewDialog.bind(this);
    this.showGlobalNotePSReviewDialog = this.showGlobalNotePSReviewDialog.bind(
      this
    );
    //this.setGrammerLoading = this.setGrammerLoading.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  // componentDidMount = () => {
  //   this.setState({
  //     lastPrintDate: propertyUtil.getLastPrintDate(),
  //   });
  // };

  handleClickOpen = () => {
    const {
      cameFrom,
      changeDetails,
      mailingDetails,
      agentData,
      property,
      isMailProLocked,
    } = this.props;

    let {
      textPS, //personal note
      textPSDate,
      personalPS, //personal ps
      personalPSDate,
      typePS,
    } = changeDetails ? changeDetails : {};
    /**check if we have last note or not and than check type. if type mismatch it's overide text view */
    /**get from user data also */
    const { listgenUserFirstName, teamMembers } = agentData;
    let lastNoteText = textPS && typePS === cameFrom ? textPS : "";
    let lastNotePS = personalPS && typePS === cameFrom ? personalPS : "";
    let globalNote = this.getCurrentAreaGlobalNoteText();
    //without type check
    this.setState(
      {
        isBlocked: isMailProLocked,
        //we have note but for another type. activate override view
        isOverride: noteUtils.shouldOveridePersonalNote(property, cameFrom),
        overrideCameFrom: typePS ? typePS : "",
        //Newly added logic:
        //If there no data for last note then we can show the note from global note area wise like this one: this.getCurrentAreaGlobalNoteText().Applicable to plain note and personal PS only
        //defaultedToGlobalNoteText: lastNoteText ? false : true,
        personalNote:
          lastNoteText && lastNoteText !== "-" ? lastNoteText : globalNote, //''
        plainNote:
          lastNoteText && lastNoteText !== "-" ? lastNoteText : globalNote, //''
        personalPS: lastNotePS && lastNotePS !== "-" ? lastNotePS : "",
        beforeEditNote:
          lastNoteText && lastNoteText !== "<p></p>" && lastNoteText !== "-"
            ? lastNoteText
            : "",
        beforeEditPS:
          lastNotePS && lastNotePS !== "<p></p>" && lastNotePS !== "-"
            ? lastNotePS
            : "",
        textPSDate: textPSDate && textPSDate !== "-" ? textPSDate : "",
        personalPSDate:
          personalPSDate && personalPSDate !== "-" ? personalPSDate : "",
      },
      () => {
        /**build ho name */
        //let hoName = propertyUtil.HONickname(changeDetails, mailingDetails);
        //get the note nicknames
        let hoName = renderLogicUtil.getNoteGreetingTextV4(
          property.changeDetails,
          "&"
        );
        const { agentSignoffForGlobalNote } = propertyUtil;
        //let greetingTop = hoName; // 'Hi ' + hoName + ',';
        let agentSignoff = agentSignoffForGlobalNote(agentData, null, false);

        let signOffTextBottom = agentSignoff; // `Warm Regards, ${agentSignoff}`;
        // let greetingBottom = 'Warm Regards, ' + hoName;
        /***open the modal now */
        this.setState({
          open: true,
          greetTextTop: hoName,
          signOffTextBottom: signOffTextBottom,
        });
      }
    );
  };

  handleClose = () => {
    this.setState({
      open: false,
      personalNote: "",
      personalPS: "",
      submitted: false,
      error: "",
      isLoading: false,
      editMode: false,
      isOverride: false,
      isBlocked: false,
      overrideCameFrom: "",
      greetTextTop: "",
      greetTextBottom: "",
      textForGrammerCheck: "",
      showGrammerSection: false,
      grammerChecked: false,
      grammerCheckLoading: false,
      showPSWarningDialog: false,
      plainNote: "",
      textPSDate: "",
      personalPSDate: "",
      beforeEditNote: "",
      beforeEditPS: "",
      currentEditMode: "",
      //defaultedToGlobalNoteText: false
    });
  };

  getNoteDataObject = () => {
    const { changeDetails, propertyDetails } = this.props;
    const { personalPSHistory } = changeDetails
      ? changeDetails
      : propertyDetails
      ? propertyDetails
      : {};
    return personalPSHistory;
  };

  updateNote = (htmlData, plainText) => {
    const lineBreaks = noteUtils.countLineBreaksInHtmlNote(htmlData, perLineComfortableNoteCharCount);
    if (lineBreaks > MaxAllowedLinesInNote) {
      this.setState({
        error: `A maximum of ${MaxAllowedLinesInNote} lines can be added for PERSONAL NOTE.`,
        submitted: false,
      });
      return;
    }
    this.setState({
      personalNote: htmlData,
      plainNote: plainText,
      submitted: false,
      error: "",
      submitted: false,
      //showGrammerSection: false,
      //grammerChecked: false,
      //grammerCheckLoading: false,
      //defaultedToGlobalNoteText: false
    });
  };

  updatePlainNote = (plainData) => {
    this.setState({
      plainNote: plainData,
      //showGrammerSection: false,
      //grammerChecked: false,
      //grammerCheckLoading: false,
      error: "",
      submitted: false,
    });
  };

  updateNotePS = (notePS) => {
    this.setState({
      personalPS: notePS,
      error: "",
      submitted: false,
    });
  };

  setCurrentEditNoteMode = (mode) => {
    this.setState({
      currentEditMode: mode,
      error: "",
      submitted: false,
    });
  };

  resetNote = (e, mode) => {
    if (this.state.isBlocked) {
      this.setState({
        error: "Mailpro has been locked for this mailing.",
      });
    } else {
      this.setState(
        mode === "note"
          ? {
              personalNote: "",
              plainNote: "",
              textPSDate: "",
              beforeEditNote: "",
            }
          : {
              personalPS: "",
              personalPSDate: "",
              beforeEditPS: "",
            },
        () => {
          this.onSubmit(e, true, mode);
        }
      );
    }
  };

  onUpdate = () => {};

  onOverride = (event) => {
    this.setState({
      isOverride: false,
    });
  };

  /**
   * Grammer Check Method
   */
  // setGrammerLoading = (isLoading) => {
  //   this.setState({
  //     grammerCheckLoading: isLoading,
  //   })
  // };

  // applyGrammerCheck = e => {
  //   const { plainNote } = this.state;
  //   this.setState({
  //     showGrammerSection: false,
  //     grammerCheckLoading: true,
  //   }, () => {
  //     this.setState({
  //       showGrammerSection: true,
  //       grammerChecked: true,
  //       textForGrammerCheck: plainNote
  //     })
  //   });
  // };

  showGlobalNotePSReviewDialog = (e) => {
    this.setState({
      showPSWarningDialog: false,
      showNotePSReviewDialog: true,
    });
  };

  closeGlobalPSReviewDialog = (e) => {
    this.setState({
      showNotePSReviewDialog: false,
    });
  };

  openWarningDialog = () => {
    const { personalPS, plainNote, currentEditMode } = this.state;
    //one more thing bypass the warning dialog if thers no global PS added. Dialog is only meant for PS so.
    /**
     * Just put a logic here if both personal note and personal ps are empty then bypass review dialog also becuase there would be nothing to review
     * If PS is there then show warning dialog
     * If No PS but note is there go to review dialog
     */
    if (personalPS && currentEditMode === "personalPS") {
      //only show dialog is its coming from ps edit form means edited ps editor
      this.setState({
        showPSWarningDialog: true,
      });
    } else {
      this.showGlobalNotePSReviewDialog();
    }
  };

  onSubmit = async (e, resetMode = false, resetType = "note") => {
    /**Check for reset mode */
    resetMode = resetMode ? resetMode : false;
    /**get vars */
    const { cameFrom, changeDetails } = this.props;
    /**fetch variables from changedetails */
    const { textPSDate, personalPSDate } = changeDetails;
    /**state vars */
    const {
      error,
      isBlocked,
      currentEditMode,
      beforeEditNote,
      beforeEditPS,
    } = this.state;
    let { personalNote, personalPS } = this.state;
    /**
     * Based on currentEditMode we need to get correct value of personal note and personal PS.
     * Lets suppose i edited something in note and note submitted and then changed ps now submitting ps so for note old data should be there
     */
    //check global note and ps for refactoring
    personalNote = propertyUtil.refactorNotePSWithRemovalBeforeSubmit(
      personalNote
    );
    personalPS = propertyUtil.refactorNotePSWithRemovalBeforeSubmit(personalPS);

    //(globalNote && globalNote.length >= 1 ||  globalPS && globalPS.length > 1)
    if (
      ((personalNote.length >= this.personalNoteMinLength ||
        (personalPS && personalPS.length >= this.personalPSMinLength)) &&
        error.length === 0) ||
      resetMode
    ) {
      /**call this method if reset mode is also true */
      this.setState(
        {
          isLoading: true,
        },
        () => {
          setTimeout(async () => {
            let myDate = moment().format("YYYY-MM-DD H:m:s"); // `${thisYear}-${thisMonth}-${today}`;
            //only update one of ps or note based on currentEditMode other use before edit keys
            //check for reset mode
            if (resetMode) {
              let updatedChangeDetails = Object.assign({}, changeDetails, {
                textPS: resetType === "note" ? null : beforeEditNote,
                textPSDate: resetType === "note" ? null : textPSDate,
                personalPS: null, //resetType === "ps" ? null : beforeEditPS, //ToDo: When we enable PS again uncomment this
                personalPSDate: null, //resetType === "ps" ? null : personalPSDate, //TODo: When we enable PS again uncomment this
                typePS:
                  (resetType === "note" && beforeEditPS === "") ||
                  (resetType === "ps" && beforeEditNote === "")
                    ? null
                    : cameFrom,
              });
              //we edited the chagne details lets not call the quick reset api
              this.saveNoteChanges(
                updatedChangeDetails,
                true,
                resetType === "note" ? "personalNote" : "personalPS",
                false
              );
            } else {
              let updatedChangeDetails = Object.assign({}, changeDetails, {
                textPS:
                  currentEditMode === "personalNote"
                    ? personalNote
                    : beforeEditNote,
                textPSDate:
                  currentEditMode === "personalNote" ? myDate : textPSDate,
                personalPS:
                  currentEditMode === "personalPS" ? personalPS : beforeEditPS,
                personalPSDate:
                  currentEditMode === "personalPS" ? myDate : personalPSDate,
                typePS: cameFrom,
              });
              /**save the note now */
              this.saveNoteChanges(
                updatedChangeDetails,
                true,
                currentEditMode,
                false
              );
            }
          }, 300);
        }
      );
    } else {
      this.setState({
        error: "Error submitting, please try again.",
        isLoading: false,
      });
    }
  };

  saveNoteChanges = async (
    updatedChangeDetails,
    closeModalMode = true,
    currentEditMode = null,
    resetNoteandPS = false
  ) => {
    /**get vars */
    const { propertyData, property } = this.props;
    const {
      areaID,
      mailerProID,
      agentID,
      mailingStatus,
      imbarcode,
      isPropertyMapped,
      numericIMBarcode,
      parcelNumber,
    } = property || {};

    let { personalNote, personalPS } = this.state;

    /**Get the storage & mailing count */
    let actualHomeowners = propertyData.filter(
      (prop) => parseInt(prop.agentID) === 0
    );
    //filters out just the agents
    let agentList = propertyData.filter((prop) => parseInt(prop.agentID) > 0)
      .length;
    //filters out the homeowners that are NOT blocked (the ones we are sending a pma to)
    let unblockedHO = actualHomeowners.filter((prop) => {
      if (!prop.changeDetails) {
        return true;
      }
      if (prop.changeDetails && prop.changeDetails.blocked === null) {
        return true;
      }
      if (!prop.changeDetails.blocked) {
        return true;
      }
      return false;
    }).length;
    //combines the unblocked homeowners + ALL the agents, this will be the total number of pma's we are sending out
    let totalUnblocked = agentList + unblockedHO;
    let userData = GetStateStorage();
    const hoData = {
      agentID,
      areaID,
      changeDetails: updatedChangeDetails,
      imbarcode,
      isPropertyMapped,
      mailerProID,
      mailingStatus,
      numericIMBarcode,
      parcelNumber,
      listgenUserID: userData.userID ? userData.userID : "",
      numOfPMAs: totalUnblocked,
    };

    await this.props.updateQuickPersonalNotePS(
      hoData,
      currentEditMode === "personalNote" ? "note" : "ps",
      resetNoteandPS
    );

    /** no need to call updateCompleteMailerProDataAsync as we dont have to update entire store just a property */

    setTimeout(async () => {
      await this.props.updateRecentUpdatedMailProId(mailerProID);
    }, 10);

    let updateKey =
      currentEditMode === "personalNote" ? "beforeEditNote" : "beforeEditPS";
    this.setState(
      {
        ...{
          submitted: true,
          isLoading: false,
        },
        ...(resetNoteandPS
          ? {
              beforeEditNote: "",
              beforeEditPS: "",
            }
          : {
              [updateKey]:
                currentEditMode === "personalNote" ? personalNote : personalPS,
            }),
      },
      () => {
        setTimeout(() => {
          /**close if that mode is set */
          if (closeModalMode) {
            this.handleClose();
          }
        }, 1000);
      }
    );
  };

  getCurrentAreaGlobalNoteText = () => {
    let { currentArea, mlsAreas } = this.props;
    currentArea = propertyUtil.getCurrentArea(mlsAreas, currentArea);
    return currentArea &&
      currentArea.globalNoteCurrent &&
      currentArea.globalNoteCurrent !== null &&
      currentArea.globalNoteCurrent !== "<p></p>" &&
      currentArea.globalNoteCurrent !== "-"
      ? currentArea.globalNoteCurrent
      : "";
  };

  getLastNoteByType = (applyTypeCheck = true) => {
    //check this method
    //let isHavingNote = false;
    let lastNote = null;
    //let personalPSHistory = this.getNoteDataObject();
    let xTableNotes = this.getAllNewNotes();
    lastNote = Array.isArray(xTableNotes) ? xTableNotes : [];
    return lastNote ? lastNote[lastNote.length - 1] : {};
  };

  // getAfterPrintDateNoteByType = (applyTypeCheck = true) => {
  //   //check this method
  //   //let isHavingNote = false;
  //   let afterDateNote = null;
  //   let personalPSHistory = this.getNoteDataObject();
  //   //const { lastPrintDate } = this.state;
  //   const { cameFrom, lastPMAPrintDate } = this.props;
  //   return renderLogicUtil.getCurrentMailingPersonalNote(personalPSHistory, lastPMAPrintDate, cameFrom, applyTypeCheck);
  // };

  getAllNewNotes = () => {
    let personalPSHistory = this.getNoteDataObject();
    let newNotes = [];
    (personalPSHistory ? personalPSHistory : []).map((item) => {
      if (item && item.type) {
        newNotes.push(item);
      }
    });
    return newNotes;
  };

  filterNote = (personalNote) => {
    const filter = new Filter();
    return filter.clean(personalNote);
  };

  formatDate = (d) => {
    return moment(new Date(d)).format("M/D/YYYY");
    //let split = d.split('-');
    //return `${split[1]}/${split[2].slice(0, 2)}/${split[0]}`;
  };

  submitErrors = (event, editMode) => {
    const {
      plainNote,
      grammerChecked,
      personalPS,
      isBlocked,
      personalNote,
      beforeEditNote,
      beforeEditPS,
    } = this.state;
    let errors = [];
    //checl for block status
    if (isBlocked) {
      errors.push("Mailpro has been locked for this mailing.");
    } else if (editMode === "personalNote") {
      //check for persona note always because it would be required before submitting personal note
      //we need to check for beforeEditNote also if to ahndle edit only not submitted case and only check if its NOT coming from note
      if (
        !plainNote ||
        (!beforeEditNote && editMode !== "personalNote") ||
        (plainNote && plainNote.length < this.personalNoteMinLength)
      ) {
        errors.push(
          `A minimum of ${this.personalNoteMinLength} characters is required for PERSONAL NOTE.`
        );
      } else if (plainNote && plainNote.length > this.personalNoteMaxLength) {
        errors.push(
          `A maximum of ${this.personalNoteMaxLength} characters is can be added for PERSONAL NOTE.`
        );
      } else if( noteUtils.countLineBreaksInHtmlNote(personalNote, perLineComfortableNoteCharCount) > MaxAllowedLinesInNote){
        errors.push(`A maximum of ${MaxAllowedLinesInNote} lines can be added for PERSONAL NOTE.`);
      }
      // else if (this.filterNote(plainNote).includes("*")) {
      //   errors.push('Please remove blacklisted word from your PERSONAL NOTE.');
      // }
      //this block only get execute if they added any personal PS
    } else if (editMode === "personalPS") {
      //check for P.S
      if (
        (!personalPS && !beforeEditPS) ||
        (personalPS && personalPS.length < this.personalPSMinLength)
      ) {
        errors.push(
          `A minimum of ${this.personalPSMinLength} characters is required for Personal P.S.`
        );
      } else if (personalPS && personalPS.length > this.personalPSMaxLength) {
        errors.push(
          `A maximum of ${this.personalPSMaxLength} characters is can be added for Personal P.S.`
        );
      }
    }
    if (errors.length !== 0) {
      this.setState({
        error: errors.join(" "),
      });
    } else {
      //call the methods accoridngly
      this.openWarningDialog();
      //!grammerChecked ? this.applyGrammerCheck(event) : this.openWarningDialog();
    }
  };

  render() {
    /**props variables */
    const { classes } = this.props;
    const {
      areaID,
      agentID,
      mailerProID,
      cameFrom,
      formattedSold,
      lastSalePrice,
      changeDetails,
      mailingDetails,
      mailingStatus,
      propertyDetails,
      propertyData,
      agentData,
      expiryDate,
      cancelledDate,
      mailingVersion,
      version,
      property,
      propertySequenceNumber,
      forceBlockEditing,
      currentArea,
    } = this.props;

    /**state vars */
    const {
      open,
      personalNote,
      plainNote,
      personalPS,
      beforeEditNote,
      beforeEditPS,
      submitted,
      error,
      isLoading,
      isOverride,
      isBlocked,
      overrideCameFrom,
      //defaultedToGlobalNoteText,
      greetTextTop,
      signOffTextBottom,
      showNotePSReviewDialog,
      showGrammerSection,
      grammerCheckLoading,
      grammerChecked,
      textForGrammerCheck,
      showPSWarningDialog,
      currentEditMode,
    } = this.state;

    /**get table notes */
    let xTableNotes = this.getAllNewNotes();
    //let greetTextTopFull = `Hi ${greetTextTop},`;
    //let signOffTextBottomFull = `Warm Regards, ${signOffTextBottom}`;

    return (
      <Fragment>
        {showNotePSReviewDialog ? (
          <GlobalNotePSReviewDialogComponent
            version={version}
            greetingTop={greetTextTop}
            greetingBottom={signOffTextBottom}
            globalNoteToReview={
              currentEditMode === "personalNote" ? personalNote : beforeEditNote
            }
            confirmationID={null}
            closeFn={this.closeGlobalPSReviewDialog}
            methodToCallOnConfirmation={this.onSubmit}
            cameFrom={"pps"}
            globalPS={
              currentEditMode === "personalPS" ? personalPS : beforeEditPS
            }
            globalPSTeam={""}
            listgenUserID={agentID}
            agentData={agentData}
            currentArea={currentArea}
          />
        ) : (
          ""
        )}
        {showPSWarningDialog ? (
          <MailProNoteWarningDialog
            closeFn={this.handleClose}
            submitFn={this.showGlobalNotePSReviewDialog}
            type={"pps"}
            areaName={""}
          />
        ) : (
          ""
        )}
        <div
          className={classNames("tableTdFlex")}
          //className={classes.cursorPointer}
        >
          {/* put a check for personal note */}
          <CustomNoteTextMakerV2
            cameFrom={cameFrom}
            lastNoteData={this.getLastNoteByType()}
            //afterDateNote={cameFrom === 'PersonalPS' ? this.getAfterPrintDateNoteByType(false) : this.getAfterPrintDateNoteByType()}//we show any added note in personal note that's why we need to check after note with false option. means we are not checking by specific personal note type. and if came from id PersonalPS thenw e will check for type also
            //anyNoteAfterDate={this.getAfterPrintDateNoteByType(false)}//this is only for to handle add/edit in personal note mail pro links
            formattedSold={formattedSold}
            lastSalePrice={lastSalePrice}
            personalPSHistory={this.getAllNewNotes()} //{this.getNoteDataObject()}//there's a change now we gonna show all note count and last date without any type check
            openDialog={this.handleClickOpen}
            mailingDetails={mailingDetails}
            areaID={areaID} //extra data due to mailpro links changes
            agentID={agentID}
            mailerProID={mailerProID}
            mailingStatus={mailingStatus}
            changeDetails={changeDetails}
            propertyDetails={propertyDetails}
            propertyData={propertyData}
            agentData={agentData}
            expiryDate={expiryDate}
            cancelledDate={cancelledDate}
            property={property}
            propertySequenceNumber={propertySequenceNumber}
            newMailingsHOMode={this.props.newMailingsHOMode}
            isMailProLocked={this.props.isMailProLocked}
          />
        </div>
        {open ? (
          <Dialog
            open={open}
            onClose={(event) => this.handleClose()}
            scroll={"paper"}
            aria-labelledby="custom-note-dialog"
            aria-describedby="custom-note-dialog-description"
            maxWidth={"md"}
            TransitionComponent={Transition}
            keepMounted={true}
            fullWidth={true}
          >
            <DialogTitle id="scroll-dialog-title">
              <div className={classes.propertyFactHeader}>
                <div></div>
                <div>
                  <CustomNoteDialogHeading
                    cameFrom={cameFrom}
                    property={property}
                  />
                </div>
                {!isLoading ? (
                  <MailProCloseButton onClick={this.handleClose} />
                ) : (
                  <div></div>
                )}
              </div>
            </DialogTitle>
            {/* Ho Dynamic section html begin */}
            {agentBarSwitchClosureV2(classes).call(this)}
            {/* Ho Dynamic section html end */}
            <DialogContent
              dividers={true}
              className={classNames(
                classes.customNoteDialogContent,
                classes.dialogContentPadding
              )}
            >
              <MailProBlockedEditingDisabledBar
                isBlocked={isBlocked}
                classes={classes}
              />
              {isOverride ? (
                <PersonalNoteOverride
                  typePS={overrideCameFrom}
                  onOverride={(event) => this.onOverride(event)}
                  onCancel={(event) => this.handleClose(event)}
                />
              ) : (
                <Fragment>
                  <div className={classes.field}>
                    <RichTextEditorV2Component
                      preText={`Hi ${greetTextTop},`}
                      postText={`${propertyUtil.getSalutation(
                        "cn",
                        agentData.listgenUserID
                      )}, ${signOffTextBottom}`}
                      showInlineButton={true}
                      //placeHolder={"Please enter Global Note, which will be sent to all homeowners who do not have a personal note assigned to them."}
                      minLength={this.personalNoteMinLength}
                      maxLength={this.personalNoteMaxLength}
                      onChange={this.updateNote}
                      updatePlainNoteFn={this.updatePlainNote}
                      // preData={personalNote ? personalNote : this.getCurrentAreaGlobalNoteText()}
                      preData={personalNote}
                      readOnly={!(currentEditMode === "personalNote")}
                      returnTextMode={"html"}
                      type={"personalNote"}
                      category={"note"}
                    >
                      <div>
                        {!(currentEditMode === "personalNote") ? (
                          <Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            className={classes.richEditorButton}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.setCurrentEditNoteMode.bind(this)(
                                "personalNote"
                              );
                            }}
                            disabled={isLoading}
                          >
                            {
                              "Click here to CREATE OR EDIT A PERSONAL NOTE FOR THIS SPECIFIC HOMEOWNER"
                            }
                          </Button>
                        ) : (
                          <Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            className={classes.editNoteButton}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.submitErrors.bind(this)(
                                event,
                                "personalNote"
                              );
                            }}
                            disabled={isLoading}
                          >
                            SUBMIT PERSONAL NOTE TO THIS SPECIFIC HOMEOWNER
                          </Button>
                        )}
                      </div>
                    </RichTextEditorV2Component>
                    {beforeEditNote ? (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <p
                          className={classes.restoreGlobalPSButton}
                          style={{ pointerEvents: isLoading ? "none" : "auto" }}
                          onClick={(event) => this.resetNote(event, "note")}
                        >
                          Clear Personal Note
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {submitted && currentEditMode === "personalNote" && (
                    <Typography variant="subtitle2" align="center">
                      <div
                        className={classNames(
                          classes.greenColor,
                          classes.centerAlign,
                          classes.lightBoldText
                        )}
                      >
                        Submitted Successfully!
                      </div>
                    </Typography>
                  )}
                  {error && currentEditMode === "personalNote" && (
                    <Typography
                      variant="subtitle2"
                      align="center"
                      style={{ margin: "8px 0" }}
                    >
                      <div
                        className={classNames(
                          classes.darkRedColor,
                          classes.centerAlign,
                          classes.lightBoldText
                        )}
                      >
                        {error}
                      </div>
                    </Typography>
                  )}
                  <div style={{ marginTop: "16px", display: "none" }}>
                    <RichTextEditorV2Component
                      preText={"P.S."}
                      postText={``}
                      showPlaceholder={true}
                      showInlineButton={true}
                      showToolbar={false}
                      placeholder={"Type your Personal P.S. here"}
                      minLength={this.personalPSMinLength}
                      maxLength={this.personalPSMaxLength}
                      onChange={this.updateNotePS}
                      updatePlainNoteFn={this.updateNotePS}
                      preData={personalPS}
                      readOnly={!(currentEditMode === "personalPS")}
                      type={"personalPS"}
                      category={"ps"}
                      returnTextMode={"plain"}
                    >
                      <div>
                        {!(currentEditMode === "personalPS") ? (
                          <Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            className={classes.richEditorButton}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.setCurrentEditNoteMode.bind(this)(
                                "personalPS"
                              );
                            }}
                            disabled={isLoading}
                          >
                            Click here to CREATE A P.S. TO THIS SPECIFIC
                            HOMEOWNER
                          </Button>
                        ) : (
                          <Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            className={classes.editNoteButton}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.submitErrors.bind(this)(event, "personalPS");
                            }}
                            disabled={isLoading}
                          >
                            SUBMIT A PERSONAL P.S. TO THIS SPECIFIC HOMEOWNER
                          </Button>
                        )}
                      </div>
                    </RichTextEditorV2Component>
                    {beforeEditPS ? (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <p
                          className={classes.restoreGlobalPSButton}
                          style={{ pointerEvents: isLoading ? "none" : "auto" }}
                          onClick={(event) => this.resetNote(event, "ps")}
                        >
                          Clear Personal P.S.
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <TextField
                    id="custom-note-ps"
                    label="Type your Personal P.S. here"
                    placeholder="Type your Personal P.S. here"
                    className={classNames(classes.textField)}
                    value={personalPS}
                    onChange={event => { this.updateNotePS(event.target.value) }}
                    variant="outlined"
                    margin="dense"
                    fullWidth={true}
                    inputProps={{
                      maxLength: this.personalPSMaxLength,
                      minLength: this.personalPSMinLength,
                      className: classes.lightBlueBackground
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment className={classNames(classes.lightBlueBackground, classes.hoverBgLightBlue)} position="start"><span className={classes.warningPrimaryText}>P.S.</span></InputAdornment>,
                      className: classNames(classes.lightBlueBackground, classes.hoverBgLightBlue)
                    }}
                    helperText={`[Optional] Min ${this.personalPSMinLength} characters and Max ${this.personalPSMaxLength} characters allowed`}
                    style={{ marginTop: '16px' }}
                  />
                  <Typography variant="subtitle2" align="center" color="primary">
                    P.S. Character count {personalPS && personalPS.length ? personalPS.length : '0'}/{this.personalPSMaxLength}
                  </Typography> */}
                  {/* <TextField
                    id="outlined-multiline-flexible"
                    //label="Enter a Note"
                    multiline
                    rows="3"
                    rowsMax="6"
                    value={error ? this.filterNote(personalNote) : personalNote}
                    onChange={event => this.updateNote(event)}
                    className={classes.textField}
                    margin="normal"
                    placeholder="Type a PERSONAL NOTE to your homeowner here. Please note: There's no need to type a greeting with your homeowner's name(s) or a 'sign-off'' with your name(s). The system automatically does this for you. Just type your note only. --- Your greeting and sign-off are taken care of.
                    "
                    variant="outlined"
                    inputProps={{ maxLength: this.personalNoteMaxLength }}
                  /> */}
                  {/* <Typography variant="body1" align="left" className={classes.greetingText}>
                        {signOffTextBottom}
                      </Typography> */}
                  {/* <div
                        className={classNames(
                          classes.fullWidth,
                          classes.centerAlign
                        )}
                      >
                        <Typography variant="caption" align="center" className={classes.textCountSize}>
                          Character count {personalNote && personalNote.length ? personalNote.length : '0'}/this.personalNoteMaxLength
                    </Typography>
                      </div> */}
                  {/* {showGrammerSection ?
                    <div className={classes.grammerInnerContainer}>
                      <GrammerChecker
                        textToCheck={textForGrammerCheck}
                        onSuccessFn={this.openWarningDialog}//call the warning dialog on no grammer message //this.showGlobalNotePSReviewDialog
                        validateButtonLoadingMethod={this.setGrammerLoading}
                      />
                    </div>
                    : ''} */}
                  {submitted && currentEditMode === "personalPS" && (
                    <Typography variant="subtitle2" align="center">
                      <div
                        className={classNames(
                          classes.greenColor,
                          classes.centerAlign,
                          classes.lightBoldText
                        )}
                      >
                        Submitted Successfully!
                      </div>
                    </Typography>
                  )}
                  {error && currentEditMode === "personalPS" && (
                    <Typography
                      variant="subtitle2"
                      align="center"
                      style={{ margin: "8px 0" }}
                    >
                      <div
                        className={classNames(
                          classes.darkRedColor,
                          classes.centerAlign,
                          classes.lightBoldText
                        )}
                      >
                        {error}
                      </div>
                    </Typography>
                  )}
                  {isLoading ? (
                    <div
                      className={classNames(
                        classes.fullWidth,
                        classes.cProgressStopBlinking
                      )}
                    >
                      <CircularProgress className={classes.cProgress} />
                    </div>
                  ) : (
                    <Fragment>
                      <div className={classes.portletFooter}>
                        <div className={classes.footerButtons}>
                          <div className={classes.footerButtonsLeftRight}>
                            {/* <Button
                              variant="outlined"
                              color="default"
                              disabled={!beforeEditPS && !beforeEditNote}
                              onClick={event => this.resetNote(event)}
                            >
                              Clear Personal Note &amp; Personal P.S
                            </Button> */}
                          </div>
                          <div className={classes.footerButtonsCenter}>
                            {/* <Button
                              variant="contained"
                              color="primary"
                              disabled={grammerCheckLoading}
                              onClick={event => { this.submitErrors(event) }}
                            >
                              {!grammerCheckLoading ? 'Submit' : 'Analyzing note...'}
                            </Button> */}
                          </div>
                          <div className={classes.footerButtonsLeftRight}></div>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              )}
              {/* <div className="divider">
              <Divider />
            </div> */}

              {/* {submitted && !error && personalNote && personalNote.length >= 1 && (
                <Typography variant="subtitle2" align="center">
                  <div
                    className={classNames(
                      classes.greenColor,
                      classes.centerAlign,
                      classes.lightBoldText
                    )}
                  >
                    Submitted Successfully!
                  </div>
                </Typography>
              )} */}
              {/* {Array.isArray(xTableNotes) && xTableNotes.length > 0 ? ( */}
              <div className={classes.extraMarginTableSection}>
                {/* <Typography variant="h5" align="center">
                    Added Notes
                  </Typography> */}
                {/* <div className="divider">
                    <Divider />
                  </div> */}
                <Fragment>
                  <Table
                    className={classNames(
                      classes.fullWidth,
                      classes.tableMarginBottom
                    )}
                    size="small"
                  >
                    <TableHead>
                      <TableRow className={classes.fullWidthTableRow}>
                        <TableCell align="center" style={{ width: "10%" }}>
                          Created on
                        </TableCell>
                        <TableCell align="center" style={{ width: "70%" }}>
                          OLD PERSONAL NOTES History
                        </TableCell>
                        <TableCell align="center" style={{ width: "20%" }}>
                          OLD PERSONAL NOTES Type
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {xTableNotes.length === 0 ? (
                        <TableCell
                          component="td"
                          scope="row"
                          align="center"
                          colSpan={3}
                        >
                          <Typography variant="subtitle2">
                            No OLD PERSONAL NOTE has been added for this
                            Homeowner yet.
                          </Typography>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {propertyUtil
                        .reverseNotes(
                          Array.isArray(xTableNotes) ? xTableNotes : []
                        )
                        .map((item, index) => {
                          return (
                            <TableRow
                              key={index}
                              className={classes.fullWidthTableRow}
                              hidden={!item.type ? true : false}
                            >
                              <TableCell
                                component="td"
                                scope="row"
                                align="center"
                              >
                                {(item.date && this.formatDate(item.date)) ||
                                  (item.psDate && this.formatDate(item.psDate))}
                              </TableCell>
                              <TableCell
                                component="td"
                                scope="row"
                                align="center"
                              >
                                <Typography
                                  variant="subtitle2"
                                  className={classNames(
                                    "breakAll",
                                    classes.lightBlueBackground
                                  )}
                                  style={{
                                    padding: "8px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  {item.note && (
                                    <div
                                      className="richTextEditorGlobalCss"
                                      dangerouslySetInnerHTML={{
                                        __html: item.note,
                                      }}
                                    />
                                  )}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  className={classNames(
                                    "breakAll",
                                    classes.lightBlueBackground,
                                    item.ps
                                      ? classes.greenColor
                                      : classes.redColor
                                  )}
                                  align="left"
                                  style={{
                                    marginTop: "8px",
                                    padding: "8px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  P.S.:&nbsp;{item.ps ? item.ps : "Not added"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="td"
                                scope="row"
                                align="center"
                              >
                                {/* <p className="breakAll"> */}
                                {item.type && item.type
                                  ? noteUtils.getPersonalNoteTextFromCameFrom(
                                      item.type
                                    )
                                  : ""}
                                {/* </p> */}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Fragment>
              </div>
              {/* ) : (
                  <div className={classes.extraMarginTableSection}>
                    <Typography variant="body2" align="center">No note added for this property yet.</Typography>
                  </div>
                )} */}
            </DialogContent>
          </Dialog>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mailingVersion: state.pma.mailingVersion,
    currentArea: state.pma.currentArea,
    mlsAreas: state.pma.mlsAreas,
    version: state.pma.version,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateQuickPersonalNotePS: (hoData, mode, resetMode) => {
      dispatch(pmaActions.updateQuickPersonalNotePS(hoData, mode, resetMode));
    },
    updateRecentUpdatedMailProId: (mailerProID) => {
      dispatch(pmaActions.updateRecentUpdatedMailProId(mailerProID));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withStyles(styles))(CustomNoteDialogV2));
