import React, { Component } from "react";

import QVFourPhotoSection from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotoSection";
import QVFourPS from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPS";
import QVFourAgentLogoHeader from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourAgentLogoHeader";
import QVFourProducedByFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPresentedByFooter";

class MQThreeSecondRight extends Component {
  render() {
    const {
      agentData,
      hoData,
      qArea,
      currentArea,
      printVersion,
      version,
      mlsAreas,
      MQCityDetails,
      aprilData,
      quarterlyAreaTable,
      quarterlyCityTable,
    } = this.props;
    const { lastPMAPrintDate, listgenUserID } = agentData;
    //console.log("mq3 props", this.props);
    //console.log("mq3 second right", this.props);
    return (
      <div className="mq3-second-right">
        <QVFourAgentLogoHeader
          agentData={agentData}
          currentArea={currentArea}
        />

        <QVFourPS
          agentData={agentData}
          hoData={hoData}
          currentArea={currentArea}
          mlsAreas={mlsAreas}
          version={version}
          printVersion={printVersion}
          mailingNum="qv4"
        />
        <QVFourPhotoSection
          currentArea={currentArea}
          agentData={agentData}
          printVersion={printVersion}
          version={version}
          MQCityDetails={MQCityDetails}
          quarterlyAreaTable={quarterlyAreaTable}
          hoData={hoData}
          //currentSoldListings={quarterlyAreaTable.currentSoldListings} Revert this if not required, if BE will send data in areaDTO for rhoda
          currentSoldListings={
            listgenUserID === "101003"
              ? quarterlyCityTable.currentSoldListings
              : quarterlyAreaTable.currentSoldListings
          }
        />
        <QVFourProducedByFooter
          agentData={agentData}
          printVersion={printVersion}
        />
      </div>
    );
  }
}

export default MQThreeSecondRight;
