import React, { Component, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "views/Pma/PmaEditor/TableQuadrants/table-quadrants-editor.scss";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { AddUpdatePmaAdmin } from "services/agent";

import { propertyUtil } from "views/Pma/PropertyUtil";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditQuadrantModal = (props) => {
  const {
    oldQuadrantData,
    handleInputChange,
    editQuadrantFormData,
    onCloseFn,
    modalType,
    areaID,
    onEditFormSubmit,
    selectedBubbleNumber,
    handleRestoreDefault,
    titleEdit,
    footerEdit,
    maxMainTitleLength,
    maxFooter1Length,
    maxFooter2Length,
    mlsAreas,
    maxMainSubtitleLength,
    savingMessageShowing,
  } = props;

  const transformInput = (str) => {
    if (!str) return "";
    let capital = str.charAt(0).toUpperCase() + str.slice(1);
    return capital;
  };

  // const { }

  if (footerEdit) {
    console.log("**inside footer edit");
    const { footerLine1, footerLine2 } = editQuadrantFormData;

    let overMaxFooterLine1Length =
      footerLine1 &&
      footerLine1.length &&
      footerLine1.length >= maxFooter1Length;

    let overMaxFooterLine2Length =
      footerLine2 &&
      footerLine2.length &&
      footerLine2.length >= maxFooter2Length;
    return (
      <Fragment>
        <div className="edit-quadrant-container">
          <div className="edit-quadrant-form new-props">
            <div className="edit-quadrant-form-title-container">
              <p className="edit-quadrant-form-title-text">
                You Can Make Changes to the Footers Line 1 & 2 Here
              </p>
            </div>

            <div className="edit-quadrant-form-body">
              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Footer Line 1</p>
                  </div>
                </div>
                <div className="edit-form-input subtitle-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id={`footerLine1`}
                      name={`footerLine1`}
                      className="title-input"
                      value={footerLine1 || ""}
                      onChange={handleInputChange}
                      placeholder={footerLine1}
                      maxLength={maxFooter1Length}
                    ></input>
                    <label
                      htmlFor="quadrantsTitle"
                      className={
                        overMaxFooterLine1Length ? "character-count-error" : ""
                      }
                    >
                      Character count:{" "}
                      {footerLine1 && footerLine1.length
                        ? footerLine1.length
                        : "0"}
                      /{maxFooter1Length}
                    </label>
                  </div>
                </div>
              </div>

              <div className="edit-form-body-col">
                <div className="edit-form-thead subtitle-thead">
                  <div>
                    <p>Footer Line 2</p>
                  </div>
                </div>
                <div className="edit-form-input main-title-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id={`footerLine2`}
                      name={`footerLine2`}
                      className="title-input"
                      value={transformInput(footerLine2) || ""}
                      onChange={handleInputChange}
                      placeholder={footerLine2 || ""}
                      maxLength={maxFooter2Length}
                    ></input>
                    <label
                      htmlFor="mktValSubTitle"
                      className={
                        overMaxFooterLine2Length ? "character-count-error" : ""
                      }
                    >
                      Character count:{" "}
                      {footerLine2 && footerLine2.length
                        ? footerLine2.length
                        : "0"}
                      /{maxFooter2Length}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="edit-form-button-container">
            <div className="button-col">
              <div className="edit-form-cancel-button" onClick={onCloseFn}>
                <p>Close</p>
              </div>
            </div>
            <div className="button-col">
              <div
                className="edit-form-submit-button"
                onClick={onEditFormSubmit}
              >
                <p>Save</p>
              </div>
            </div>
          </div>
          {savingMessageShowing && (
            <div className="saving-message">Your work has been saved!</div>
          )}
        </div>
      </Fragment>
    );
  } else if (titleEdit) {
    const { mktValSubTitle, mktValAreaTitle } = editQuadrantFormData;

    let overMaxMainTitleLength =
      mktValAreaTitle &&
      mktValAreaTitle.length &&
      mktValAreaTitle.length >= maxMainTitleLength;

    let overMaxMainSubtitleLength =
      mktValSubTitle &&
      mktValSubTitle.length &&
      mktValSubTitle.length >= maxMainSubtitleLength;
    return (
      <Fragment>
        <div className="edit-quadrant-container">
          <div className="edit-quadrant-form new-props">
            <div className="edit-quadrant-form-title-container">
              {/*<p className="edit-quadrant-form-title-text">
                You Can Make Changes to the Title and Subtitle Here
              </p>*/}
              <p className="edit-quadrant-form-title-text">
                You Can Make Changes to the Title Here
              </p>
            </div>

            <div className="edit-quadrant-form-body">
              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Title </p>
                  </div>
                </div>
                <div className="edit-form-input main-title-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id={`main-title`}
                      name={`mktValAreaTitle`}
                      className="title-input input-uppercase"
                      value={mktValAreaTitle || ""}
                      onChange={handleInputChange}
                      placeholder={mktValAreaTitle}
                      maxLength={maxMainTitleLength}
                    ></input>
                    <label
                      htmlFor="quadrantsTitle"
                      className={
                        overMaxMainTitleLength ? "character-count-error" : ""
                      }
                    >
                      Character count:{" "}
                      {mktValAreaTitle && mktValAreaTitle.length
                        ? mktValAreaTitle.length
                        : "0"}
                      /{maxMainTitleLength}
                    </label>
                  </div>
                </div>
              </div>

              {/*<div className="edit-form-body-col">
                <div className="edit-form-thead subtitle-thead">
                  <div>
                    <p>Subtitle</p>
                  </div>
                </div>
                <div className="edit-form-input subtitle-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id={`mktValSubTitle`}
                      name={`mktValSubTitle`}
                      className="title-input"
                      value={transformInput(mktValSubTitle) || ""}
                      onChange={handleInputChange}
                      placeholder={mktValSubTitle}
                      maxLength={maxMainSubtitleLength}
                    ></input>
                    <label
                      htmlFor="mktValSubTitle"
                      className={
                        overMaxMainSubtitleLength ? "character-count-error" : ""
                      }
                    >
                      Character count:{" "}
                      {mktValSubTitle && mktValSubTitle.length
                        ? mktValSubTitle.length
                        : "0"}
                      /{maxMainSubtitleLength}
                    </label>
                  </div>
                </div>
              </div>*/}
            </div>
          </div>

          <div className="edit-form-button-container">
            <div className="button-col">
              <div className="edit-form-cancel-button" onClick={onCloseFn}>
                <p>Close</p>
              </div>
            </div>
            <div className="button-col">
              <div
                className="edit-form-submit-button"
                onClick={onEditFormSubmit}
              >
                <p>Save</p>
              </div>
            </div>
          </div>
          {savingMessageShowing && (
            <div className="saving-message">Your work has been saved!</div>
          )}
        </div>
      </Fragment>
    );
  } else {
    let quadrantTitle =
      editQuadrantFormData[`mktValBox${selectedBubbleNumber}Title`];
    let quadrantText =
      editQuadrantFormData[`mktValBox${selectedBubbleNumber}Text`];
    let quadrantSubText =
      editQuadrantFormData[`mktValBox${selectedBubbleNumber}SubText`];

    let maxQuadrantTextLength = 300;
    let overMaxQuadrantTextLength =
      quadrantText &&
      quadrantText.length &&
      quadrantText.length >= maxQuadrantTextLength;

    let maxQuadrantTitleLength = 50;
    let overMaxQuadrantTitleLength =
      quadrantTitle &&
      quadrantTitle.length &&
      quadrantTitle.length >= maxQuadrantTitleLength;

    let maxQuadrantSubTextLength = 70;
    let overMaxQuadrantSubTextLength =
      quadrantSubText &&
      quadrantSubText.length &&
      quadrantSubText.length >= maxQuadrantSubTextLength;

    return (
      <Fragment>
        <div className="edit-quadrant-container">
          <div className="edit-quadrant-form new-props">
            <div className="edit-quadrant-form-title-container">
              <p className="edit-quadrant-form-title-text">
                You Can Make Changes to the Title {selectedBubbleNumber} and
                Text {selectedBubbleNumber} Here
              </p>
            </div>
            <div className="edit-quadrant-form-body row-body">
              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Title</p>
                  </div>
                </div>
                <div className="edit-form-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id={`mktValBox${selectedBubbleNumber}Title`}
                      name={`mktValBox${selectedBubbleNumber}Title`}
                      className="title-input"
                      value={quadrantTitle || ""}
                      onChange={handleInputChange}
                      placeholder={quadrantTitle}
                      maxLength={maxQuadrantTitleLength}
                    ></input>
                    <label
                      htmlFor={`mktValBox${selectedBubbleNumber}Title`}
                      className={
                        overMaxQuadrantTitleLength
                          ? "character-count-error"
                          : ""
                      }
                    >
                      Character count:{" "}
                      {quadrantTitle && quadrantTitle.length
                        ? quadrantTitle.length
                        : "0"}
                      /{maxQuadrantTitleLength}
                    </label>
                  </div>
                </div>

                {/*<div className="edit-form-thead">
                  <div>
                    <p>Sub Text</p>
                  </div>
                </div>
                <div className="edit-form-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id={`mktValBox${selectedBubbleNumber}SubText`}
                      name={`mktValBox${selectedBubbleNumber}SubText`}
                      className="title-input"
                      value={quadrantSubText || ""}
                      onChange={handleInputChange}
                      placeholder={quadrantSubText}
                      maxLength={maxQuadrantSubTextLength}
                    ></input>
                    <label
                      htmlFor={`mktValBox${selectedBubbleNumber}SubText`}
                      className={
                        overMaxQuadrantSubTextLength
                          ? "character-count-error"
                          : ""
                      }
                    >
                      Character count:{" "}
                      {quadrantSubText && quadrantSubText.length
                        ? quadrantSubText.length
                        : "0"}
                      /{maxQuadrantSubTextLength}
                    </label>
                  </div>
                </div>*/}
              </div>

              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Text</p>
                  </div>
                </div>
                <div className="edit-form-input">
                  <div className="address-input-col">
                    <textarea
                      id={`mktValBox${selectedBubbleNumber}Text`}
                      placeholder={quadrantText}
                      onChange={handleInputChange}
                      name={`mktValBox${selectedBubbleNumber}Text`}
                      value={quadrantText || ""}
                      className="quadrant-textarea"
                      maxLength={maxQuadrantTextLength}
                    ></textarea>

                    <label
                      htmlFor={`quad${selectedBubbleNumber}Text`}
                      className={
                        overMaxQuadrantTextLength ? "character-count-error" : ""
                      }
                    >
                      Character count:{" "}
                      {quadrantText && quadrantText.length
                        ? quadrantText.length
                        : "0"}
                      /{maxQuadrantTextLength}
                    </label>
                    {/*<input
                    type="textbox"
                    id={`text-${selectedBubbleNumber}`}
                    name={`text-${selectedBubbleNumber}`}
                    value={
                      editQuadrantFormData[selectedBubbleNumber].text || ""
                    }
                    onChange={handleInputChange}
                    placeholder={(e) =>
                      editQuadrantFormData[selectedBubbleNumber].text(
                        e,
                        "text"
                      )
                    }
                  ></input>*/}
                    {/*<input
                    type="textbox"
                    id={`text-${selectedBubbleNumber}`}
                    name={`text-${selectedBubbleNumber}`}
                    value={
                      editQuadrantFormData[selectedBubbleNumber].text || ""
                    }
                    onChange={handleInputChange}
                    placeholder={(e) =>
                      editQuadrantFormData[selectedBubbleNumber].text(
                        e,
                        "text"
                      )
                    }
                  ></input>*/}
                    {/*<input
                    type="textbox"
                    id={`text-${selectedBubbleNumber}`}
                    name={`text-${selectedBubbleNumber}`}
                    value={
                      editQuadrantFormData[selectedBubbleNumber].text || ""
                    }
                    onChange={handleInputChange}
                    placeholder={(e) =>
                      editQuadrantFormData[selectedBubbleNumber].text(
                        e,
                        "text"
                      )
                    }
                  ></input>*/}
                    {/*<input
                    type="textbox"
                    id={`text-${selectedBubbleNumber}`}
                    name={`text-${selectedBubbleNumber}`}
                    value={
                      editQuadrantFormData[selectedBubbleNumber].text || ""
                    }
                    onChange={handleInputChange}
                    placeholder={(e) =>
                      editQuadrantFormData[selectedBubbleNumber].text(
                        e,
                        "text"
                      )
                    }
                  ></input>*/}
                    {/*<input
                    type="textbox"
                    id={`text-${selectedBubbleNumber}`}
                    name={`text-${selectedBubbleNumber}`}
                    value={
                      editQuadrantFormData[selectedBubbleNumber].text || ""
                    }
                    onChange={handleInputChange}
                    placeholder={(e) =>
                      editQuadrantFormData[selectedBubbleNumber].text(
                        e,
                        "text"
                      )
                    }
                  ></input>*/}
                  </div>
                </div>
                {/*<div className="edit-form-body-col">
                <textarea
                  // name="textarea"
                  style={{ width: "250px", height: "150px" }}
                  placeholder={
                    editQuadrantFormData[selectedBubbleNumber].text
                  }
                  onChange={handleInputChange}
                  name={`text-${selectedBubbleNumber}`}
                  value={
                    editQuadrantFormData[selectedBubbleNumber].text || ""
                  }
                  className="quadrant-textarea"
                ></textarea>
              </div>*/}
              </div>
            </div>
          </div>

          <div className="edit-form-button-container">
            <div className="button-col">
              <div className="edit-form-cancel-button" onClick={onCloseFn}>
                <p>Close</p>
              </div>
              {/*<div className="restore-default" onClick={handleRestoreDefault}>
                <p>Restore Default</p>
              </div>*/}
            </div>
            <div className="button-col">
              <div
                className="edit-form-submit-button"
                onClick={onEditFormSubmit}
              >
                <p>Save</p>
              </div>
            </div>
          </div>
          {savingMessageShowing && (
            <div className="saving-message">Your work has been saved!</div>
          )}
        </div>
        {/*</Dialog>*/}
      </Fragment>
    );
  }
};

export default compose(withSnackbar, withStyles(styles))(EditQuadrantModal);
