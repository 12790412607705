import React, { Component } from "react";
import { connect } from "react-redux";
import { Store } from "Store";
import { pmaActions } from "_store/_actions";
import {
  GetStateStorage,
  IsValidNavbarData,
  GetNavbarDataFromStorage,
  SetNavbarDataInStorage,
} from "common/storage";
import { Link, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { WebsiteLayout } from "layouts";
import { CircularProgress, Typography, Button, Grid } from "@material-ui/core";
import querystring from "querystring";
import PMAIssueReportsDialog from "views/Pma/PmaEditor/PMAIssueReportsDialog";
// import PMAEditorInformationDialog from "./components/PMAEditorInformationDialog";
import { handleLogoutFn } from "common/helpers";
import { Alert } from "@material-ui/lab";
import VerificationScreen from "./VerificationScreen";

class DataVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbarData: {},
      updatedLocalStorage: false,
      resetLocalState: false,
      openDrawer: false,
      isFullMode: true,
      enableMailProApprovalProcess: false, //it's also linked with mailpro approval button is also enable
      openPMAIssuesDialog: false,
      enablePMAInformationDialog: false,
      // enablePMAInformationDialog: true,// true means system will display the PMA info dialog. When we push Year End PMA then make it false
      openPMAInformationDialog: false,
      isMounted: false,
      isLoading: false,
    };
    /**bind methods */
    // this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    // this.handlePMAIssuesDialog = this.handlePMAIssuesDialog.bind(this);
    // this.getVerificationApi = this.getVerificationApi.bind(this);
  }

  // async getVerificationApi(mlsAreaID, mlsCityID, agentData) {
  //   this.setState({
  //     isLoading: true,
  //   });
  //   console.log("this.prams", this.prams);
  //   console.log("inside get verification api", mlsAreaID, mlsCityID, agentData);
  //
  //   const stateData = GetStateStorage();
  //   let date = new Date();
  //   let thisYear = date.getFullYear();
  //   // const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
  //   // const today = `${date.getDate()}`.padStart(2, 0);
  //   const thisMonth = 5;
  //   const today = 17;
  //   const areaAndDate = `${mlsAreaID}-${thisYear}-${thisMonth}-${today}`;
  //
  //   let cityAndDate = `${mlsCityID}-${thisYear}-${thisMonth}-${today}`;
  //
  //   const { version, typeOfMQVersion, typeOfQVersion } = this.props;
  //   const { listgenUserID } = agentData;
  //   try {
  //     setTimeout(async () => {
  //       await this.props.getMailerProDetails(
  //         mlsAreaID,
  //         cityAndDate,
  //         agentData,
  //         true,
  //         "mq",
  //         "mq3",
  //         typeOfQVersion,
  //         listgenUserID
  //       );
  //     }, 2500);
  //   } catch (err) {
  //     console.log("there was an error", err);
  //   }
  //   this.setState({
  //     isLoading: false,
  //   });
  // }
  // async componentDidMount() {
  //   this.setState({
  //     isMounted: true,
  //   });
  //   const { navbarData } = this.state;
  //   const stateData = GetStateStorage();
  //   console.log("stateData", stateData);
  //   const agentData = stateData.userData;
  //   const { mlsAreas, switchArea, currentArea } = this.props;
  //   console.log("inside data ", this.props);
  //   let navbarDataStorage = GetNavbarDataFromStorage();
  //
  //   console.log("navbarDataStorage", navbarDataStorage);
  //   const isValid = IsValidNavbarData();
  //   let navData;
  //   if (navbarDataStorage && Object.keys(navbarDataStorage).length >= 1) {
  //     navData = navbarDataStorage;
  //   } else if (
  //     agentData &&
  //     agentData.navbarInfo &&
  //     agentData.navbarInfo.length >= 1
  //   ) {
  //     navData = agentData.navbarInfo;
  //   } else if (mlsAreas.length >= 1) {
  //     navData = mlsAreas[0];
  //   } else {
  //     console.log("^^^^none of the above");
  //   }
  //   this.setState(
  //     {
  //       navbarData: navData,
  //     },
  //     () => {
  //       this.props.getNavbarInfo(stateData.userData.listgenUserID, navData);
  //       SetNavbarDataInStorage(navData);
  //     }
  //   );
  //
  //   this.getVerificationApi();
  //   if (navbarDataStorage && Object.keys(navbarDataStorage).length >= 1) {
  //     console.log("has navbarDataStorage", navbarDataStorage);
  //     this.setState(
  //       {
  //         navbarData: navbarDataStorage,
  //       },
  //       async () => {
  //         await this.props.getNavbarInfo(
  //           stateData.userData.listgenUserID,
  //           navbarDataStorage
  //         );
  //         await SetNavbarDataInStorage(navbarDataStorage);
  //         await this.getVerificationApi();
  //       }
  //     );
  //   } else if (
  //     agentData &&
  //     agentData.navbarInfo &&
  //     agentData.navbarInfo.length >= 1
  //   ) {
  //     //console.log("has agentData navbar", agentData.navbarInfo);
  //     this.setState(
  //       {
  //         navbarData: agentData.navbarInfo[0],
  //       },
  //       () => {
  //         this.props.getNavbarInfo(
  //           stateData.userData.listgenUserID,
  //           agentData.navbarInfo[0]
  //         );
  //         SetNavbarDataInStorage(agentData.navbarInfo[0]);
  //         this.getVerificationApi();
  //       }
  //     );
  //   } else {
  //     console.log("mlsAreas[0]", mlsAreas[0]);
  //     this.setState(
  //       {
  //         navbarData: mlsAreas[0],
  //       },
  //       () => {
  //         SetNavbarDataInStorage(mlsAreas[0]);
  //         this.props.getNavbarInfo(
  //           stateData.userData.listgenUserID,
  //           mlsAreas[0]
  //         );
  //         this.getVerificationApi();
  //       }
  //     );
  //   }
  //   if (this.state.navbarData && this.state.navbarData.length >= 1) {
  //     SetNavbarDataInStorage(navbarData);
  //   } else {
  //     console.log("no navbar data available", this.state);
  //   }
  //   console.log("navData", navData);
  //   const { mlsAreaID, mlsCityID } = navData;
  //   console.log("&&&& before", this.props);
  //   if (isValid && mlsAreaID && mlsCityID && agentData) {
  //     console.log("about to call get verification");
  //     /**get next pma print date */
  //     this.getVerificationApi(mlsAreaID, mlsCityID, agentData);
  //   } else {
  //     console.log("No MLS areas found", mlsAreas);
  //   }
  //
  //   /**now scroll to top also */
  //   window.scrollTo(0, 0);
  //   //show the pma information dialog
  //   if (this.state.enablePMAInformationDialog) {
  //     this.setState({
  //       openPMAInformationDialog: true,
  //     });
  //   }
  // }

  componentWillReceiveProps(props) {
    console.log("will receive props", props);
    const { currentArea } = this.props;
    console.log("this.props.currentArea", this.props.currentArea);
    console.log("currentArea", currentArea);
    if (this.props.currentArea !== currentArea) {
      this.forceUpdate();
    }
    // if (props.refresh !== refresh) {
    //   this.fetchShoes(id)
    //     .then(this.refreshShoeList)
    // }
  }

  componentWillMount = () => {
    /** check for default step */
    try {
      let parsedParams = querystring.parse(
        this.props.location.search.substring(1)
      );
      // if (
      //   parsedParams &&
      //   parsedParams.isEditorMode &&
      //   parsedParams.isEditorMode === 'editor'
      // ) {
      //   // this.setState({
      //   //   isFullMode: true,
      //   // });
      //   this.props.switchStep(1);
      // }
      //console.log("parsedParams logged==", parsedParams);
      if (parsedParams && parsedParams.step && parsedParams.step === "1") {
        this.props.switchStep(1);
        setTimeout(() => {
          //replace the history data without refrshing the page
          if (window && window.history.pushState) {
            let newurl =
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?step=0";
            window.history.pushState({ path: newurl }, "", newurl);
          }
        }, 1000);
      } else if (
        this.props !== 0 &&
        parsedParams &&
        parsedParams.step &&
        parsedParams.step === "0"
      ) {
        //this one we required if first we will go to mailpro and again we are going to home screena and then going to PMA
        this.props.switchStep(0);
      }
    } catch (err) {}
  };

  componentWillReceiveProps(newProps) {}

  componentDidUpdate() {
    //console.log('index did update');
    const { mlsAreas, switchArea } = this.props;
    if (
      mlsAreas &&
      Object.keys(mlsAreas).length > 0 &&
      !this.state.updatedLocalStorage
    ) {
      SetNavbarDataInStorage(mlsAreas[0]);
      this.setState(
        {
          navbarData: mlsAreas[0],
          updatedLocalStorage: true,
        },
        () => {
          switchArea(mlsAreas[0]);
        }
      );
    }
  }

  async componentDidMount() {
    const stateData = GetStateStorage();
    const agentData = stateData.userData;
    //check if state data not founf
    const { mlsAreas, switchArea, currentArea } = this.props;
    const { listgenUserID } = agentData;
    /**Get session storage */
    let navbarData = GetNavbarDataFromStorage();
    console.log("componentDidMount props", this.props);
    const { areaID, cityID } = this.props.match.params;
    //  console.log("!!!!!areaID is", areaID);
    const isValid = IsValidNavbarData();
    let navInfo = await this.props.getNavbarInfo(
      stateData.userData.listgenUserID
    );
    // await SetNavbarDataInStorage(navbarDataStorage);
    // await this.getVerificationApi();
    //const navData = await this.props.getNavbarInfo(listgenUserID);
    /**get next pma print date */
    let date = new Date();

    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);
    // console.log("***inside componentDidMount", this.props, this.state);
    // console.log("navInfo", navInfo);
    // console.log("after nav props", this.props);
    console.log("currentArea", currentArea);
    const { mlsCityID } = currentArea;
    const areaAndDate = `${areaID}-${thisYear}-${thisMonth}-${today}`;
    const cityAndDate = `${cityID}-${thisYear}-${thisMonth}-${today}`;
    console.log("cityAndDate", cityAndDate);
    // console.log("****listgenUserID", listgenUserID);
    // console.log("stateData", stateData);
    // console.log("agentData", agentData);
    const dataVerificationCall = await this.props.getDataVerification(
      areaAndDate,
      "-",
      listgenUserID
    );

    try {
      {
        // areaId,
        //   cityAndDate,
        //   agentData,
        //   forEditor,
        //   version,
        //   typeOfMQVersion,
        //   typeOfQVersion,
        //   listgenUserID,
        //   typeOfYEVersion,
        //   isDataVerification;

        const propertyRecords = await this.props.getMailerProDetails(
          areaID,
          cityAndDate,
          agentData,
          true,
          "qv4",
          // differentVersion ? differentVersionName : version,
          "qv4",
          "qv4",
          listgenUserID,
          "qv4",
          true
        );
      }
      // const dataVerification = await this.props.getDataVerification(
      //   areaAndDate,
      //   "-"
      // );
    } catch {
      // console.log("***error with mailpro api");
    }

    // console.log("navbarData", navbarData);

    // if (
    //   agentData.listgenUserID === "100210" ||
    //   agentData.listgenUserID === "100134"
    // ) {
    //   this.setState({
    //     enableMailProApprovalProcess: true //it's also linked with mailpro approval button is also enable
    //   });
    // }
    if (isValid) {
      // this.setState({
      //   navbarData: navbarData
      // });
      // switchArea(navbarData);
    } else {
      console.log("No MLS areas found", mlsAreas);
    }
    /**now scroll to top also */
    window.scrollTo(0, 0);
    //show the pma information dialog
    if (this.state.enablePMAInformationDialog) {
      this.setState({
        openPMAInformationDialog: true,
      });
    }
  }
  componentWillUnmount() {
    this.setState({
      isMounted: false,
    });
  }
  handleDrawerOpen = () => {
    this.setState({
      openDrawer: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      openDrawer: false,
    });
  };

  handlePMAIssuesDialog = (openDialog = false) => {
    this.setState({
      openPMAIssuesDialog: openDialog,
    });
  };

  // switchVersion = v => {
  //   console.log('version is switching to ', v, typeof v);
  //   const switchVersion = this.props.switchVersion(v);
  // };

  render() {
    const {
      mlsAreas,
      currentStep,
      propertyData,
      mailingVersion,
      quarterlyAreaTable,
      currentArea,
      navbarData,
      dataVerification,
    } = this.props;
    const { isLoading } = this.state;
    const stateData = GetStateStorage();
    const agentData = stateData.userData;

    console.log("after render in agent data verification", this.props);
    // // let isValid = true;
    // console.log(
    //   "currentArea && Object.keys(currentArea).length >= 1",
    //   currentArea && Object.keys(currentArea).length >= 1
    // );
    let isValid =
      currentArea &&
      Object.keys(currentArea).length >= 1 &&
      dataVerification &&
      Object.keys(dataVerification).length >= 1 &&
      quarterlyAreaTable &&
      Object.keys(quarterlyAreaTable).length >= 1 &&
      !isLoading;
    // console.log("****isLoading", isLoading);
    //console.log("isValid", isValid);
    return (
      <WebsiteLayout
        sidebarMode={true}
        openSidebarModeSidebar={this.state.openDrawer}
        sidebarModeHandleClose={this.handleDrawerClose}
        hideFooter={true}
      >
        {this.state.openPMAIssuesDialog ? (
          <PMAIssueReportsDialog
            handlePMAIssuesDialog={this.handlePMAIssuesDialog}
            currentArea={currentArea}
          />
        ) : (
          ""
        )}
        {/* {this.state.openPMAInformationDialog &&
        currentStep === 0 &&
        propertyData &&
        propertyData.length > 1 ? (
          <PMAEditorInformationDialog />
        ) : (
          ""
        )} */}

        {isValid ? (
          <VerificationScreen
            agentData={agentData}
            mlsAreas={mlsAreas}
            currentArea={currentArea}
            navbarData={navbarData}
            enableMailProApprovalProcess={
              this.state.enableMailProApprovalProcess
            }
            handlePMAIssuesDialog={this.handlePMAIssuesDialog}
            handleDrawerOpen={this.handleDrawerOpen}
            propertyData={propertyData}
            mailingVersion={mailingVersion}
            quarterlyAreaTable={quarterlyAreaTable}
            // hoData={hoData}
            agentData={agentData}
            isVerification={true}
            dataVerification={dataVerification}
          />
        ) : (
          <div
            style={{
              height: "80vh",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              background: "inherit",
            }}
          >
            <div>
              <CircularProgress />
            </div>
            {/* style={{ marginTop: '2vh', marginLeft: '2vh' }} */}
            <div>
              <Typography variant="subtitle2">
                &nbsp;&nbsp;Loading...
              </Typography>
            </div>
          </div>
        )}
      </WebsiteLayout>
    );
  }
}

const mapStateToProps = (state) => {
  // return { isLoading: state.pma.isLoading };
  return {
    mlsAreas: state.pma.mlsAreas,
    localStorageData: state.localStorageData,
    version: state.pma.version,
    mailingVersion: state.pma.mailingVersion,
    currentStep: state.pma.currentStep,
    propertyData: state.pma.propertyData,
    currentArea: state.pma.currentArea,
    pma: state.pma,
    mlsAreaIds: state.pma.mlsAreaIds,
    marketActivity: state.pma.marketActivity,
    MQCityDetails: state.pma.MQCityDetails,

    quarterlyAreaTable: state.pma.quarterlyAreaTable,
    quarterlyCityTable: state.pma.quarterlyCityTable,
    localSellingAgents: state.pma.localSellingAgents,

    typeOfVersion: state.pma.typeOfVersion,
    typeOfMQVersion: state.pma.typeOfMQVersion,
    typeOfQVersion: state.pma.typeOfQVersion,
    dataVerification: state.pma.dataVerification,
    viewingGeneratedCL: state.pma.viewingGeneratedCL,
    coverPageDetails: state.pma.coverPageDetails,
    testimonials: state.pma.testimonials,
    agentDesignations: state.pma.agentDesignations,
    agentAdvantages: state.pma.agentAdvantages,
    salePriceForCities: state.pma.salePriceForCities,
    yearEnd: state.pma.yearEnd,
    qArea: state.pma.qArea,
    qCity: state.pma.qCity,
    aprilData: state.pma.aprilData,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getMailerProDetails: (
      areaId,
      cityAndDate,
      agentData,
      forEditor,
      version,
      typeOfMQVersion,
      typeOfQVersion,
      listgenUserID,
      typeOfYEVersion,
      isDataVerification
    ) => {
      dispatch(
        pmaActions.getMailerProDetails(
          areaId,
          cityAndDate,
          agentData,
          forEditor,
          version,
          typeOfMQVersion,
          typeOfQVersion,
          listgenUserID,
          typeOfYEVersion,
          isDataVerification
        )
      );
    },
    getNavbarInfo: (listgenUserID) =>
      dispatch(pmaActions.getNavbarInfo(listgenUserID)),
    switchArea: (area) => dispatch(pmaActions.switchArea(area)),
    switchStep: (step) => dispatch(pmaActions.switchStep(step)),
    updateLocalStorageDataForRedux: (localStorageData) =>
      dispatch(pmaActions.updateLocalStorageDataForRedux(localStorageData)),
    switchVersion: (version) => {
      dispatch(pmaActions.switchVersion(version));
    },
    getDataVerification: (areaAndDate, subdivision, listgenUserID) => {
      dispatch(
        pmaActions.getDataVerification(areaAndDate, subdivision, listgenUserID)
      );
    },
  };
}
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DataVerification);
