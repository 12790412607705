import { CommonAxiosAPIHandler } from "common/helpers";

const RESET_HOME_VALUATION = "/admin/resetHoValuationForMailerProID";

/**
 * @name resetHomeValuation
 * @param {*} mailerProID 
 * @description Reset home valuation for the given mailerProID
 * @returns 
 */
export const resetHomeValuation = (mailerProID) => {
    return CommonAxiosAPIHandler("GET", [
        `${RESET_HOME_VALUATION}?mailerProID=${mailerProID}`
    ]);
};