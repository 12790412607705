import React, { Component } from "react";

class KaterynaSeptember2024 extends Component {
  render() {
    const { listgenUserID } = this.props.agentData;
    console.log("*listgenUserID", listgenUserID);
    if (listgenUserID === "105047") {
      return (
        <div className="kateryna-and-trish-september-2024-container">
          <img
            src="https://pma-coverphotos.s3.amazonaws.com/non-covers/sep23_KaterynaDavydova_Halloween_2_tiny.jpg"
            className="kateryna-and-trish-september-img"
            alt="kateryna-september-img"
          />
        </div>
      );
    } else {
      return (
        <div className="kateryna-september-2024-container">
          <img
            src="https://pma-coverphotos.s3.amazonaws.com/non-covers/sep24_KaterynaDavydova_Halloween_tiny.jpg"
            className="kateryna-september-img"
            alt="kateryna-september-img"
          />
        </div>
      );
    }
  }
}

export default KaterynaSeptember2024;
