import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";
import RemoveModal from "./RemoveModal";

class VerificationRow extends Component {
  state = {
    removeModalOpen: false,
  };
  formatAddressName(address, agentListing) {
    function capitalizeFirstLetter(word) {
      word = word.toLowerCase();
      const firstLetter = word.charAt(0);
      const firstLetterCap = firstLetter.toUpperCase();
      const remainingLetters = word.slice(1);
      const capitalizedWord = firstLetterCap + remainingLetters;

      return capitalizedWord;
    }

    let split = address.split(" ");
    let mappedSplit = split.map((name) => {
      let isAllUpperCase = name === name.toUpperCase();
      if (isAllUpperCase) {
        return capitalizeFirstLetter(name);
      }
      return name;
    });
    return mappedSplit.join(" ");
  }
  getMonthAndYearString = (inputTime) => {
    try {
      let month = moment(inputTime).format("MMM");
      let day = moment(inputTime).format("D");
      let year = moment(inputTime).format("YY");

      return (
        <div className="date-td">
          <div>{month}</div>
          <div>{day},</div>
          <div>{year}</div>
        </div>
      );
      // return moment(inputTime).format("MMM D, YYYY");
    } catch (err) {
      return "";
    }
  };

  calculatePricePerSqft(calculation, saleP, sqft) {
    if (calculation === "-") {
      let res = Math.round(saleP / sqft);
      return res.toLocaleString();
    } else {
      return Math.round(calculation).toLocaleString();
    }
  }

  removeRow = (prop) => {
    console.log("clicking remove row", prop);
    this.setState({
      removeModalOpen: true,
    });
  };
  handleCloseDialog = (open) => {
    console.log("open is", open);
    this.setState({
      removeModalOpen: open,
    });
  };
  render() {
    const {
      formatBaths,

      propTypeAcronym,
      shortenStatus,
    } = propertyUtil;
    const { removeRow, handleCloseDialog } = this;
    const { removeModalOpen } = this.state;
    const { solds, mlsAreaID, older, isSingleTable, agentData } = this.props;
    const { listgenUserID } = agentData;
    let isGailPearl = listgenUserID === "103455";
    let isCountryClubGate = mlsAreaID === "132" ? true : false;

    const listMap =
      solds &&
      solds.map((list, i) => {
        let isAgentListing = list.isAgentFiltered === true;
        if (isGailPearl) {
          isAgentListing = false;
        }

        let showAddressNumber = true;

        let hasUnitNum = list.unitNumber && list.unitNumber !== "-";

        let addressName = "";
        if (
          list.streetNumber !== "-" &&
          list.streetNumber !== "0" &&
          list.streetNumber !== "." &&
          showAddressNumber
        ) {
          addressName += list.streetNumber;
        }
        addressName += ` ${list.streetName}`;
        if (list.streetSuffix && list.streetSuffix !== "-") {
          addressName += ` ${list.streetSuffix}`;
        }
        if (hasUnitNum && showAddressNumber) {
          if (list.unitNumber.includes("Unit")) {
            addressName += `, ${list.unitNumber}`;
          } else if (list.unitNumber.includes("#")) {
            addressName += `, ${list.unitNumber}`;
          } else {
            addressName += `, #${list.unitNumber}`;
          }
        }
        let addressSize = addressName.length > 25 ? "smaller-row-address" : "";
        console.log("*****list", list);
        let hasOffMarket =
          list.PotentialOffMarketSaleWithMultiPhotos === "1" ||
          list.PotentialOffMarketSaleWithSinglePhoto === "1" ||
          list.isOffMarketSale;
        return (
          <tr
            key={`${list.Address} ${list.ListPrice} ${list.SaleDate} ${i}`}
            className={`${list.Status && list.Status.toLowerCase()} ${
              older ? "presolds-row" : "recent-row"
            } ${isSingleTable ? "single-table-row" : ""}`}
          >
            {removeModalOpen && (
              <RemoveModal
                property={list}
                handleCloseDialog={handleCloseDialog}
              />
            )}
            <td className="remove-row-box" onClick={() => removeRow(list)}>
              <div>
                <p>X</p>
              </div>
            </td>
            <td>
              <p>{i + 1}</p>
            </td>

            <td
              // className={`status-text`}
              className={`status-text ${
                list.status && list.status.toLowerCase()
              }`}
              align="left"
            >
              <p>
                {hasOffMarket && "*"}
                {list.status ? "-" : shortenStatus(list.status, "pendingOnly")}
              </p>
            </td>

            {!list.saleDate || list.saleDate === "-" ? (
              <td></td>
            ) : (
              <td className="market-table-sale-date-row">
                {this.getMonthAndYearString(list.saleDate)}
              </td>
            )}

            <td className="prop-type-text">
              {propTypeAcronym(list.propertyType)}
            </td>
            <td>
              <p>{this.formatAddressName(addressName)}</p>
            </td>

            <td className="price-td">
              <div>
                <p>$</p>
                <p>{Number(list.listPrice).toLocaleString()}</p>
              </div>
            </td>
            <td className="price-td">
              {list.salePrice === "-" ? (
                <div className="off-centered-dash">
                  <p>—</p>
                </div>
              ) : (
                <div>
                  <p>$</p>
                  <p>{Number(list.salePrice).toLocaleString()}</p>
                </div>
              )}
            </td>
            <td>{list.daysOnMarket}</td>
            <td>{Math.round(list.percentOfReceiving)}%</td>

            <td className="square-feet-td">
              {list.pricePerSquareFt === "-" ? (
                <div className="off-centered-dash">
                  <p>—</p>
                </div>
              ) : (
                <div>
                  <p>$</p>
                  <p>{Number(list.pricePerSquareFt).toLocaleString()}</p>
                </div>
              )}
            </td>
            <td></td>
          </tr>
        );
      });
    return <tbody className="solds-tbody">{listMap}</tbody>;
  }
}

export default VerificationRow;
// import React, { Component } from "react";
// import { propertyUtil } from "views/Pma/PropertyUtil";
// import moment from "moment";
//
// class VerificationRow extends Component {
//   getMonthAndYearString = (inputTime) => {
//     try {
//       let month = moment(inputTime).format("MMM");
//       let day = moment(inputTime).format("D");
//       let year = moment(inputTime).format("YYYY");
//
//       return (
//         <div>
//           <p>{month}</p>
//           <p>{day},</p>
//           <p>{year}</p>
//         </div>
//       );
//       // return moment(inputTime).format("MMM D, YYYY");
//     } catch (err) {
//       return "";
//     }
//   };
//
//   calculatePricePerSqft(calculation, saleP, sqft) {
//     if (calculation === "-") {
//       let res = Math.round(saleP / sqft);
//       return res.toLocaleString();
//     } else {
//       return Math.round(calculation).toLocaleString();
//     }
//   }
//   render() {
//     const {
//       getPropView,
//       formatSuffix,
//       formatBaths,
//       toAcre,
//       cutName,
//       propTypeAcronym,
//       shortenStatus,
//       shortenPropType,
//     } = propertyUtil;
//     const { shortenYear } = propertyUtil;
//     const { solds, mlsAreaID, older, isSingleTable } = this.props;
//
//     let isCountryClubGate = mlsAreaID === "132" ? true : false;
//     console.log("***********inside verification row", this.props);
//     const listMap =
//       solds &&
//       solds.map((list, i) => {
//         let hasOffMarket =
//           list.PotentialOffMarketSaleWithMultiPhotos === "1" ||
//           list.PotentialOffMarketSaleWithSinglePhoto === "1";
//         return (
//           <tr
//             key={`${list.Address} ${list.ListPrice} ${list.SaleDate} ${i}`}
//             className={`${list.Status && list.Status.toLowerCase()} ${
//               older ? "presolds-row" : "recent-row"
//             } ${isSingleTable ? "single-table-row" : ""}`}
//           >
//             <td>
//               <p>{i + 1}</p>
//             </td>
//
//             <td
//               // className={`status-text`}
//               className={`status-text ${
//                 list.Status && list.Status.toLowerCase()
//               }`}
//               align="left"
//             >
//               <p>
//                 {hasOffMarket && "*"}
//                 {shortenStatus(list.Status, "pendingOnly")}
//               </p>
//             </td>
//
//             {list.SaleDate === "-" ? (
//               <td></td>
//             ) : (
//               <td className="market-table-sale-date-row">
//                 {this.getMonthAndYearString(list.SaleDate)}
//               </td>
//             )}
//
//             <td className="prop-type-text">
//               {propTypeAcronym(list.PropertyType)}
//             </td>
//             <td>
//               <p>
//                 {list.StreetNumber === "-" || list.StreetNumber === "0"
//                   ? null
//                   : list.StreetNumber}{" "}
//                 {list.StreetName}{" "}
//                 {list.StreetSuffix &&
//                   formatSuffix(list.StreetSuffix, mlsAreaID)}
//                 {!isCountryClubGate &&
//                 list.PropertyType === "Townhouse" &&
//                 list.UnitNumber !== "-"
//                   ? `, #${list.UnitNumber}`
//                   : ""}
//               </p>
//             </td>
//
//             <td className="price-td">
//               <div>
//                 <p>$</p>
//                 <p>{Number(list.listPrice).toLocaleString()}</p>
//               </div>
//             </td>
//             <td className="price-td">
//               {list.SalePrice === "-" ? (
//                 <div className="off-centered-dash">
//                   <p>—</p>
//                 </div>
//               ) : (
//                 <div>
//                   <p>$</p>
//                   <p>{Number(list.SalePrice).toLocaleString()}</p>
//                 </div>
//               )}
//             </td>
//             <td>{list.DaysOnMarket}</td>
//             <td>{Math.round(list.PercentOfReceiving)}%</td>
//
//             <td>
//               {list.PricePerSquareFt === "-" ? (
//                 <div className="off-centered-dash">
//                   <p>—</p>
//                 </div>
//               ) : (
//                 <div>
//                   <p>$</p>
//                   <p>{Number(list.PricePerSquareFt).toLocaleString()}</p>
//                 </div>
//               )}
//             </td>
//             <td></td>
//           </tr>
//         );
//       });
//     return <tbody className="solds-tbody">{listMap}</tbody>;
//   }
// }
//
// export default VerificationRow;
