import axios from 'common/interceptors';
import querystring from "querystring";
import { CommonAxiosAPIHandler, GetAuthorization } from "common/helpers";
import { GetOAuthTokenByKey } from "common/storage";
import { isArray } from "util";

const GET_AGENT_WITHOUT_SCHEDULES = "/admin/getActiveAgentsWithoutPMASchedules";
const SET_SCHEDULE_FOR_AGENT = "/admin/setPMAScheduleIDForAgent";
const SET_SCHEDULE_FOR_COUNTY = "/admin/setPMAScheduleIDForCounty";
const ADD_UPDATE_SCHEDULES = "/admin/addNewPMASchedule";
//Schedule Flags

const UPDATE_APPROVAL_BUTTON_STATUS_FOR_AGENT = "/admin/updateApprovalButtonStatusForAgent";
const UPDATE_MAILPRO_ACCESS_STATUS_FOR_AGENT = "/admin/updateMailProAccessStatusForAgent";
const UPDATE_APPROVAL_BUTTON_STATUS = "/admin/updateApprovalButtonStatusForSchedule";
const UPDATE_MAILPRO_ACCESS_STATUS = "/admin/updateMailProAccessStatusForSchedule";
const UPDATE_APPROVAL_BUTTON_STATUS_FOR_COUNTY = "/admin/updateApprovalButtonStatusForCounty";
const UPDATE_MAILPRO_ACCESS_STATUS_FOR_COUNTY = "/admin/updateMailProAccessStatusForCounty";

const ADD_MARKETS_FOR_SCHEDULE = "/admin/addNewPMAMarket";
const GET_ALL_SCHEDULE_MARKET_MAP = "/admin/getAllPMAScheduleMarketMaps";
const ASSIGN_USERS_TO_MARKET = "/admin/assignListgenUserToMarket";

const GET_ALL_SCHEDULES_V1 = "/admin/getAllPMASchedules";

//admin/getAllPMASchedules

export const handleScheduleAPIs = (type, countyID, listgenUserID, pmaScheduleID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${type === "get_agents"
            ? GET_AGENT_WITHOUT_SCHEDULES
            : (type === 'county_schedule' ? SET_SCHEDULE_FOR_COUNTY : type === 'agent_schedule' ? SET_SCHEDULE_FOR_AGENT : '')}?listgenUserID=${listgenUserID}&countyID=${countyID}&pmaScheduleID=${pmaScheduleID}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*"
            },
            data: {}
          }
        )
        .then(res => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

// type === "invoice_agents" ? ADMIN_CHARGE_AND_INVOICE_AGENTS_API : type === "monthly_exclusivity" ? ADMIN_MONTHLY_EXCLUSIVITY_AGENTS_API : ""
//           }?listgenUserID=${listgenUserID}&lastPrintDate=${lastPrintDate}&isTestimonialUsed=${isTestimonialUsed}`

export const addScheduleV1 = (scheduleData) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(ADD_UPDATE_SCHEDULES, scheduleData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*"
            }
          }
        )
        .then(res => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const updateMailProAccessForSchedule = (mode, listgenUserID, pmaScheduleID, mailProAccessStatus, countyID) => {
  // console.log('mode, listgenUserID, pmaScheduleID, mailProAccessStatus, countyID==', mode, listgenUserID, pmaScheduleID, mailProAccessStatus, countyID)
  // console.log((mode === 'approval_button_county' ? UPDATE_APPROVAL_BUTTON_STATUS_FOR_COUNTY : mode === 'mailpro_access_county' ? UPDATE_MAILPRO_ACCESS_STATUS_FOR_COUNTY : mode === 'approval_button_agent' ? UPDATE_APPROVAL_BUTTON_STATUS_FOR_AGENT : mode === 'mailpro_access_agent' ?  UPDATE_MAILPRO_ACCESS_STATUS_FOR_AGENT : mode === 'approval_button_schedule' ? UPDATE_APPROVAL_BUTTON_STATUS : UPDATE_MAILPRO_ACCESS_STATUS) + `?listgenUserID=${listgenUserID}&pmaScheduleID=${pmaScheduleID}&mailProAccessStatus=${mailProAccessStatus}&approvalButtonStatus=${mailProAccessStatus}&countyID=${countyID}`)
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get((mode === 'approval_button_county' ? UPDATE_APPROVAL_BUTTON_STATUS_FOR_COUNTY : mode === 'mailpro_access_county' ? UPDATE_MAILPRO_ACCESS_STATUS_FOR_COUNTY : mode === 'approval_button_agent' ? UPDATE_APPROVAL_BUTTON_STATUS_FOR_AGENT : mode === 'mailpro_access_agent' ? UPDATE_MAILPRO_ACCESS_STATUS_FOR_AGENT : mode === 'approval_button_schedule' ? UPDATE_APPROVAL_BUTTON_STATUS : UPDATE_MAILPRO_ACCESS_STATUS) + `?listgenUserID=${listgenUserID}&pmaScheduleID=${pmaScheduleID}&mailProAccessStatus=${mailProAccessStatus}&approvalButtonStatus=${mailProAccessStatus}&countyID=${countyID}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*"
            },
            data: {}
          }
        )
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const addScheduleMarketAPI = (scheduleData) => {
  return CommonAxiosAPIHandler("POST", [
    `${ADD_MARKETS_FOR_SCHEDULE}`, scheduleData
  ]);
}

export const getAllMarketsData = () => {
  return CommonAxiosAPIHandler("GET", [
    `${GET_ALL_SCHEDULE_MARKET_MAP}`
  ]);
}

export const getAllSchedulesV1 = () => {
  return CommonAxiosAPIHandler("GET", [
    `${GET_ALL_SCHEDULES_V1}`
  ]);
}

export const assignUsersToMarket = (listgenUerID, pmaScheduleID, pmaMarketID) => {
  return CommonAxiosAPIHandler("GET", [
    `${ASSIGN_USERS_TO_MARKET}?listgenUserID=${listgenUerID}&pmaScheduleID=${pmaScheduleID}&pmaMarketID=${pmaMarketID}`
  ]);
}
