import { DialogActions, Icon } from "@material-ui/core";
import React, { Fragment } from "react";
import MailProArrowButton from "../../UI/MailProArrowButton";
import MailProButton from "../../UI/MailProButton";
import { CommonConfirmationDialogV2Component } from "components";
import AnniversaryAutomatorHomeownerSendAll from "./AnniversaryAutomatorHomeownerSendAll";

const AnniversaryAutomatorDialogFooter = ({
  classes,
  propertySequenceNumber,
  handleNextHOProperty,
  handlLastHOProperty,
  resetNoteandPS,
  isLoading,
  isLoadingSystemDefaultNoteData,
  anniversarySkippableMailProIdsCount,
  anniversaryHomeownersCount,
  skipBulkAnniversaryNotes,
  ...rest
}) => {
  return (
    <div>
      <div className={classes.footerContainer}>
        <div className={classes.footerContainerZero}>
          <div className={classes.backButton}>
            {propertySequenceNumber !== 1 &&
              <MailProArrowButton
                iKey={"anniversary_automator_back_button_note"}
                variant="text"
                label={
                  "Back"
                }
                customStyle={{
                  root: {
                    fontSize: "13px",
                    fontStyle: "italic",
                    fontWeight: 400,
                    color: 'blue'
                  }, icon2: { marginLeft: '-16px' }
                }}
                onClick={() => handlLastHOProperty(false)}
                disabled={isLoading || isLoadingSystemDefaultNoteData}
                reverse={true}
              />}
          </div>
        </div>
        <div className={classes.footerContainerFirst}>
          {/* We will enable this when we all three types of Send All Notes. One type is send a same custom note to all homeowners, second is send system default note to all homeowners and third is send system default note to all homeowners with custom note. */}
        {/* <div>
            <AnniversaryAutomatorHomeownerSendAll
              classes={classes}
              property={rest.property}
              //anniversaryHomeowners={rest.anniversaryHomeowners}
              anniversaryHomeownersCount={anniversaryHomeownersCount}
              cameFrom={rest.cameFrom}
              isBlocked={rest.isBlocked}
              agentData={rest.agentData}
              onSuccessUpdate={rest.sendNoteToAllHomeownersFn}
              isDisabled={isLoading || isLoadingSystemDefaultNoteData}
            />
          </div> */}
          <div style={{marginTop: '24px'}}>
            <MailProArrowButton
              iKey={"submit_anniversary_note"}
              //and P.S...
              label={isLoadingSystemDefaultNoteData ? 'Loading System Default Note...' : isLoading ? "Please wait..." : 'OK, SEND THIS ANNIVERSARY NOTE AS IT IS — NEXT'}//isNotePSOverridden ? 'OVERRIDE PERSONAL NOTE AND SEND THIS ANNIVERSARY NOTE — NEXT' :
              onClick={() => handleNextHOProperty(true)}
              customStyle={{
                padding: "2px 16px",
                root: {
                  fontSize: "15px",
                  fontWeight: "500",
                }
              }}
              disabled={isLoading || isLoadingSystemDefaultNoteData}
            />
          </div>
          <div style={{ marginTop: "24px", display: 'flex', gap: '16px', alignItems: 'center' }}>
            <MailProButton
              key={"anniversary_automator_not_add_note"}
              variant="outlined"
              label={
                "Skip Anniversary Note for this Homeowner"
              }
              customStyle={{
                fontSize: "12px",
                fontWeight: 400,
              }}
              onClick={resetNoteandPS}
              disabled={isLoading || isLoadingSystemDefaultNoteData}
            />
            {anniversarySkippableMailProIdsCount > 0 && <CommonConfirmationDialogV2Component
              title={`You are about to skip Anniversary Notes for ${anniversarySkippableMailProIdsCount} Homeowner${anniversarySkippableMailProIdsCount > 1 ? 's' : ''}`}
              subtitle={`Are you certain you want to skip Anniversary Notes for ${anniversarySkippableMailProIdsCount} Homeowner${anniversarySkippableMailProIdsCount > 1 ? 's' : ''}? This action is irreversible.`}
              methodToCallOnConfirmation={skipBulkAnniversaryNotes}
              key={'MailProAnniversaryAutomatorDialog-SKip'}
              cancelButtonText={'CANCEL'}
              confirmationButtonText={'YES, Skip'}
            >
              <MailProButton
                key={"anniversary_automator_skip_btn_hos"}
                variant="outlined"
                label={anniversarySkippableMailProIdsCount === anniversaryHomeownersCount ? `Skip Anniversary Notes for ${anniversarySkippableMailProIdsCount} Homeowners` : `Skip Anniversary Notes for remaining ${anniversarySkippableMailProIdsCount} Homeowner${anniversarySkippableMailProIdsCount > 1 ? 's' : ''}`}
                customStyle={{ fontSize: "12px", fontWeight: 400, }}
                onClick={() => { }}
                disabled={isLoading || isLoadingSystemDefaultNoteData}
              />
            </CommonConfirmationDialogV2Component>}
          </div>
        </div>
        <div className={classes.footerContainerSecond}>
          <div className={classes.anniversaryNoteCount}>
            <p style={{ fontWeight: 500 }}>
              {propertySequenceNumber} of {anniversaryHomeownersCount}
            </p>
            <p>Anniversary</p>
            <p>Notes</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnniversaryAutomatorDialogFooter;
