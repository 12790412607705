import React, { Component } from "react";
import { connect } from "react-redux";
import { pmaActions } from "_store/_actions";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";
import ActivePendingRow from "./ActivePendingRow";
import MarketTableBG from "./verification-table-bg.png";
import UpdatedMarketTableBG from "./updated-verification-table-bg.png";
import "./styles.scss";

class VerificationTable extends Component {
  sortByStatus = propList => {
    // let sliced = propList
    //   .slice(0, 19)
    //   .sort((a, b) => new Date(b.saleDate) - new Date(a.saleDate));
    // let sold = sliced.filter(x => x.status === "Sold");
    // let pend = sliced.filter(x => x.status === "Pending");
    // let cont = sliced.filter(x => x.status === "Contingent");
    // let active = sliced.filter(x => x.status === "Active");
    // let trueSort = [...sold, ...pend, ...cont, ...active];
    // return trueSort;
    return propList.slice(0, 19);
  };
  getTodayDate = () => {
    return moment(new Date()).format("MMM D, YYYY");
  };
  formatLastPrintDate = date => {
    return moment(date).format("MMM D, YYYY");
  };
  render() {
    const { currentArea, quarterlyAreaTable } = this.props;

    console.log("sales table props", this.props);
    const {
      lastMidQuarterlyPrintDate,
      totalActiveListings,
      totalPendingListings,
      daysToSellSinceLastMidQtr,
      marketHighLightsData
    } = quarterlyAreaTable;
    const {
      getPropView,
      formatSuffix,
      formatBaths,
      toAcre,
      cutName,
      propTypeAcronym,
      splitAreas,
      countAreaChars,
      slashSeparatedArea
    } = propertyUtil;
    const { mlsAreaID, mlsNeighborhood } = currentArea;
    //console.log(this.props.quarterlyAreaTable);
    const { getTitle } = this;

    const totalActives = this.props.quarterlyAreaTable.monthsSupplyTable
      .AreaNumberOfHousesForSale;
    const totalSolds = marketHighLightsData.current90DaysUnitsSold
      ? marketHighLightsData.current90DaysUnitsSold
      : 0;
    let last90DaysDOM = marketHighLightsData.current90DaysAvgDOM
      ? marketHighLightsData.current90DaysAvgDOM
      : 0;
    let last90DaysSalePrice = marketHighLightsData.current90DaysAvgSalePrice;
    let last90DaysPercRec =
      marketHighLightsData.current90DaysPercentReceivedOfAsking;
    let last90DaysPricePerSqFt = marketHighLightsData.current90DaysPricePerSqFt;
    let sorted =
      quarterlyAreaTable &&
      this.sortByStatus(quarterlyAreaTable.marketActivityTable);
    // console.log("sorted", sorted);
    // console.log(
    //   "quarterlyAreaTable.marketActivityTable",
    //   quarterlyAreaTable.marketActivityTable
    // );

    const soldNum =
      (quarterlyAreaTable.marketActivityTable &&
        quarterlyAreaTable.marketActivityTable.filter(
          list => list.status === "Sold"
        ).length) ||
      0;

    const activesNum =
      quarterlyAreaTable.marketActivityTable &&
      quarterlyAreaTable.marketActivityTable.filter(
        list => list.status === "Active"
      ).length;

    const contingentsNum =
      quarterlyAreaTable.marketActivityTable &&
      quarterlyAreaTable.marketActivityTable.filter(
        list => list.status === "Contingent"
      ).length;
    //  console.log("contingentsNum", contingentsNum);

    const currentSolds = (
      <ActivePendingRow solds={sorted.slice(0, 19)} mlsAreaID={mlsAreaID} />
    );

    const tableHeader = (
      <thead className="active-pending-thead">
        <tr>
          <th></th>
          <th></th>
          <th>Status</th>

          <th>
            <p>List</p>
            <p>Date</p>
          </th>
          <th>DOM</th>
          <th>
            <p>Property</p>
            <p>Type</p>
          </th>

          <th>Address</th>
          <th>
            <p>List Price</p>
          </th>
          <th>
            <p>Listing</p>
            <p>Agent</p>
          </th>

          <th>
            <p>% Rec'd</p>
            <p>of Ask</p>
          </th>

          <th>
            <p>Price per</p>
            <p>Square Foot</p>
          </th>
        </tr>
      </thead>
    );

    return (
      <div className="data-verification-sales-table">
        <div className="subtitle-row">
          <div className="left-subtitle-section">
            <div className="left-subtitle-text">
              <p className="left-subtitle-text-title">
                Active & Pending Listings
              </p>
            </div>
          </div>
          <div className="right-subtitle-section">
            <p className="small-left-subtitle">
              As of
              {this.getTodayDate()}
            </p>
          </div>
        </div>
        <div className="golden-divider" />
        <div className="market-table-containers">
          <div className="both-market-tables">
            <table className="regular-sales-table">
              {tableHeader}
              {currentSolds}
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    mlsAreas: state.pma.mlsAreas,
    localStorageData: state.localStorageData,
    version: state.pma.version,
    mailingVersion: state.pma.mailingVersion,
    currentStep: state.pma.currentStep,
    propertyData: state.pma.propertyData,

    pma: state.pma,
    mlsAreaIds: state.pma.mlsAreaIds,
    marketActivity: state.pma.marketActivity,
    MQCityDetails: state.pma.MQCityDetails,
    isLoading: state.pma.isLoading,
    quarterlyAreaTable: state.pma.quarterlyAreaTable,
    quarterlyCityTable: state.pma.quarterlyCityTable,
    localSellingAgents: state.pma.localSellingAgents,

    typeOfVersion: state.pma.typeOfVersion,
    typeOfMQVersion: state.pma.typeOfMQVersion,
    typeOfQVersion: state.pma.typeOfQVersion,

    viewingGeneratedCL: state.pma.viewingGeneratedCL,
    coverPageDetails: state.pma.coverPageDetails,
    testimonials: state.pma.testimonials,
    agentDesignations: state.pma.agentDesignations,
    agentAdvantages: state.pma.agentAdvantages,
    salePriceForCities: state.pma.salePriceForCities,
    yearEnd: state.pma.yearEnd,
    qArea: state.pma.qArea,
    qCity: state.pma.qCity,
    aprilData: state.pma.aprilData
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getMailerProDetails: (
      areaId,
      cityAndDate,
      agentData,
      forEditor,
      version,
      typeOfMQVersion,
      typeOfQVersion,
      listgenUserID
    ) => {
      dispatch(
        pmaActions.getMailerProDetails(
          areaId,
          cityAndDate,
          agentData,
          forEditor,
          version,
          typeOfMQVersion,
          typeOfQVersion,
          listgenUserID
        )
      );
    },
    switchArea: area => dispatch(pmaActions.switchArea(area)),
    switchStep: step => dispatch(pmaActions.switchStep(step)),
    updateLocalStorageDataForRedux: localStorageData =>
      dispatch(pmaActions.updateLocalStorageDataForRedux(localStorageData)),
    switchVersion: version => {
      dispatch(pmaActions.switchVersion(version));
    }
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(VerificationTable);
