import React, { Fragment, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import styles from "./styles";
import compose from "recompose/compose";
import { withSnackbar } from "notistack";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import classNames from "classnames";
import MailProButton from "../../UI/MailProButton";
import NoteAutomatorNoteEditor from "../Common/NoteAutomatorNoteEditor";
import { noteUtils } from "../Common/NoteUtils";
import NoteAutomatorNoteReviewDialog from "../Common/NoteAutomatorNoteReviewDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AnniversaryAutomatorHomeownerSendAll(props) {
  const [open, setOpen] = React.useState(false);
  const [showReviewDialog, setReviewDialog] = React.useState(false);
  const [showOverideSection, setOverrideSection] = React.useState(false);

  const {
    classes,
    property,
    //anniversaryHomeowners,
    anniversaryHomeownersCount,
    cameFrom,
    isBlocked,
    agentData,
    isDisabled
  } = props;

  const { signOffTextBottom } =
    noteUtils.getPersonalNoteGreetingTexts(property, agentData, 'and');
    const greetTextTop = "Hello [Homeowner Name]";

  const [notePS, setNotePS] = React.useState({
    personalNote: "",
    plainNote: "",
    personalPS: "",
    noteErrors: [],
    psErrors: [],
  });


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateNote = (htmlData, plainText) => {
    setNotePS({
      ...notePS,
      ...{
        personalNote: htmlData,
        plainNote: plainText,
        noteErrors: [],
      },
    });
  };

  const updatePlainNote = (plainData) => {
    setNotePS({
      ...notePS,
      ...{
        plainNote: plainData,
      },
    });
  };

  const updatePS = (psValue) => {
    setNotePS({ ...notePS, ...{ personalPS: psValue, psErrors: [] } });
  };

  const onReviewYesClick = () => {
    setReviewDialog(false);
    //{textPS, textPSDate, personalPS, personalPSDate, typePS}
    const noteData = noteUtils.buildNotePSDataBeforeSubmit(
      cameFrom,
      {},
      isBlocked,
      notePS.personalNote,
      null,//notePS.personalPS: Change this back to state.localPSCopy once we have PS back in anniversary. We have automated ps in anniversary and because of its getting submitted
      "both",
      false,
      null
    );
    props.onSuccessUpdate(noteData);
    handleClose();
  };


  const handleSubmitClick = () => {
    const noteErrors = noteUtils.checkPersonalNotePSForErrors(
      isBlocked,
      "personalNote",
      notePS.personalNote,
      notePS.plainNote,
      notePS.personalPS,
      cameFrom
    );
    if (noteErrors.length > 0) {
      setNotePS({ ...notePS, ...{ noteErrors } });
      return;
    }
    const psErrors = noteUtils.checkPersonalNotePSForErrors(
      isBlocked,
      "personalPS",
      notePS.personalNote,
      notePS.plainNote,
      notePS.personalPS,
      cameFrom
    );
    if (psErrors.length > 0) {
      setNotePS({ ...notePS, ...{ psErrors } });
      return;
    }
    setReviewDialog(true);
  };

  return (
    <div>
      <div onClick={handleClickOpen}>
        {props.children ? props.children :
        <MailProButton
          key={"anniversary_automator_note_send_all"}
          variant="text"
          label={
            <Fragment>
              <p>CLICK HERE TO SEND ANNIVERSARY NOTE TO ALL HOMEOWNERS</p>
            </Fragment>
          }
          customStyle={{
            fontSize: "15px",
            fontStyle: "italic",
            lineHeight: "17.5px",
            textAlign: "center",
            padding: "8px 16px"
          }}
          disabled={isDisabled}
        />} </div>
      {showReviewDialog && (
        <NoteAutomatorNoteReviewDialog
          greetTextTop={greetTextTop}
          signOffTextBottom={signOffTextBottom}
          personalNote={notePS.personalNote}
          personalPS={notePS.personalPS}
          onYesClick={onReviewYesClick}
          onNoClick={() => setReviewDialog(false)}
          headerText={`This is how your Personalized Anniversary Note will print for ${anniversaryHomeownersCount || 0} Anniversary Homeowners.`}
        />
      )}
      {open && (
        <Dialog
          onClose={handleClose}
          //maxWidth="sm"
          fullWidth={true}
          classes={{
            paper: classNames(
              classes.root,
              classes.rootPaper,
              classes.rootPaperDecorative,
              classes.rootPaperRadius,
              classes.editorDialog
            ),
          }}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          BackdropProps={{
            classes: {
              root: classes.backDropBackgroundLightBlue,
            },
          }}
          disableBackdropClick
        >
          <DialogTitle className={classes.editorDialogHeader}>
            <div>Enter an Anniversary Note for all {anniversaryHomeownersCount || 0} Homeowners with Anniversaries</div>
            <div className={classes.redNoteText}>Note:- This note will override any other written notes for Anniversary Homeowners only.</div>
          </DialogTitle>
          <DialogContent
            className={classes.editorDialogContent}

          >
              <div className={classes.editorDialogContainer}>
                <div>
                  <NoteAutomatorNoteEditor
                    preText={greetTextTop + ","}
                    postText={signOffTextBottom}
                    showPlaceholder={true}
                    minLength={100}
                    maxLength={200}
                    placeholder={"Write your anniversary message here that you would like to send to all homeowners celebrating their anniversary..."}
                    onChange={updateNote}
                    updatePlainPSFn={updatePlainNote}
                    preData={props.lastNoteTextHtml}
                    readOnly={false}
                    category={"note"}
                    noteMaxLength={noteUtils.getPersonalNoteSizes().personalNoteMaxLength}
                    noteMinLength={noteUtils.getPersonalNoteSizes().personalNoteMinLength}
                    errorMessages={notePS.noteErrors}
                  />
                </div>
                <div></div>
                <div>
                  {/* <NoteAutomatorPSEditor
                    property={property}
                    autoOpenPSSelector={autoOpenPSSelector}
                    errorMessages={notePS.psErrors}
                    onChange={updatePS}
                    currentPS={notePS.personalPS}
                    key={'aa-ps-editor-main'}
                    psMaxLength={noteUtils.getPersonalNoteSizes().personalPSMaxLength}
                    psMinLength={noteUtils.getPersonalNoteSizes().personalPSMinLength}
                    placeholder={"Enter an anniversary P.S. here..."}
                  /> */}
                </div>
              </div>
          </DialogContent>
          {!showOverideSection && (
            <DialogActions>
              <div className={classes.editorDialogFooter}>
                <div>
                  <MailProButton
                    key={"cancel_anniversary_note_editor_send_all"}
                    variant="outlined"
                    label={"CANCEL"}
                    customStyle={{
                      fontSize: "16px",
                      fontWeight: 600,
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                    onClick={handleClose}
                  />
                </div>
                <div>
                  <MailProButton
                    iKey={"submit_anniversary_note_editor_send_all"}
                    label={"SUBMIT"}
                    variant="filled"
                    onClick={handleSubmitClick}
                    customStyle={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "white",
                      paddingLeft: "24px",
                      paddingRight: "24px",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                  />
                </div>
                <div></div>
              </div>
            </DialogActions>
          )}
        </Dialog>
      )}
    </div>
  );
}

export default compose(
  withStyles(styles),
  withSnackbar
)(AnniversaryAutomatorHomeownerSendAll);
