import React, { Component } from "react";
import BeigeImg from "./091024_PMA_MissionStmt_imageBG_3.jpg";
import { Link } from "react-router-dom";

class MissionStatementBlock extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }
  }
  render() {
    const {
      printVersion,
      agentData,
      missionStatement,
      currentArea,
    } = this.props;
    const {
      missionStatementContent,
      missionStatementHeader,
    } = missionStatement;
    const { mlsAreaID } = currentArea;
    let { photoURL, isMemberOfTeam, listgenUserID } = agentData;

    let hasPhotoBorder = false;
    let missionStatementTextStyles;
    if (listgenUserID === "100656") {
      photoURL =
        "https://pma-coverphotos.s3.amazonaws.com/non-covers/091624_LisaMcCollum_missionStatmt.jpg";
    } else if (listgenUserID === "105790") {
      photoURL =
        "https://pma-coverphotos.s3.amazonaws.com/non-covers/091924_gary_missionStatmt_1.jpg";
    } else if (listgenUserID === "106463") {
      photoURL =
        "https://pma-coverphotos.s3.amazonaws.com/non-covers/092024_BethPowers-Deb_MissionStatemt.png";
    } else if (mlsAreaID === "SCL-205A") {
      photoURL =
        "https://pma-coverphotos.s3.amazonaws.com/non-covers/091424_CalebF-LynneM_combo-Headshot.png";
    } else if (listgenUserID === "102712") {
      photoURL =
        "https://pma-coverphotos.s3.amazonaws.com/non-covers/022524_LilaKazemi_Headshot.jpg";
    } else if (listgenUserID === "106450") {
      photoURL =
        "https://pma-coverphotos.s3.amazonaws.com/non-covers/GretaSieglock_MissionStatemt.png";
      missionStatementTextStyles = "mission-statement-14-5";
    }
    if (photoURL.includes(".jpg")) {
      hasPhotoBorder = true;
    }

    let useDefault = true;
    let pronoun = "My";
    if (isMemberOfTeam) {
      pronoun = "Our";
    }
    let missionTitleStyles = "";
    let isGalliTeam = listgenUserID === "102843";
    if (isGalliTeam) {
      missionTitleStyles = "mission-margin-top-30";
    }
    if (useDefault) {
      return (
        <div
          className={
            printVersion
              ? "print-mission-statement-block"
              : "editor-mission-statement-block"
          }
        >
          {!printVersion && (
            <Link
              to="/agent/missionstatements"
              className="edit-button-mission-statement"
            >
              <p>Click Here to Edit</p>
              <p>Mission Statement</p>
            </Link>
          )}

          <div className="mission-statement-block-bg-container">
            <img className="mission-statement-block-bg" src={BeigeImg} />
            <div className="mission-statement-content-container">
              <div className="mission-statement-column-container">
                <div className="mission-statement-image-container">
                  <img
                    src={photoURL}
                    className={`mission-statement-image ${
                      hasPhotoBorder && "mission-photo-border"
                    }`}
                  />
                </div>
              </div>

              <div className="mission-statement-inner-container">
                <div className="mission-statement-text-container">
                  {missionStatementHeader ? (
                    <div
                      className={`mission-statement-title ${missionTitleStyles}`}
                    >
                      {missionStatementHeader}
                    </div>
                  ) : (
                    <div
                      className={`mission-statement-title ${missionTitleStyles}`}
                    >
                      {pronoun} Commitment to You
                    </div>
                  )}

                  {isGalliTeam ? (
                    <div className="mission-statement-text">
                      <p className="mission-statement-margin-top-spacing">
                        We are dedicated to exceeding expectations and
                        anticipating your needs.
                      </p>
                      <p className="mission-statement-margin-top-spacing">
                        We are in a position of high trust and hold this
                        responsibility as sacred.
                      </p>
                      <p className="mission-statement-margin-top-spacing">
                        Our team is always available and committed to serving
                        YOU!
                      </p>
                      <p className="mission-statement-margin-top-spacing">
                        "We work hard to exceed your expectations!"
                      </p>
                    </div>
                  ) : (
                    <div
                      className={`mission-statement-text ${missionStatementTextStyles}`}
                    >
                      {missionStatementContent}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            printVersion
              ? "print-mission-statement-block-v2"
              : "editor-mission-statement-block-v2"
          }
        >
          {!printVersion && (
            <Link
              to="/agent/missionstatements"
              className="edit-button-mission-statement"
            >
              <p>Click Here to Edit</p>
              <p>Mission Statement</p>
            </Link>
          )}
          <div className="mission-statement-block-bg-container">
            <img className="mission-statement-block-bg" src={BeigeImg} />
            <div className="mission-statement-content-container">
              <div className="mission-statement-inner-container">
                <div className="mission-statement-image-container">
                  <img
                    src={photoURL}
                    className={`mission-statement-image ${
                      hasPhotoBorder && "mission-photo-border"
                    }`}
                  />
                </div>

                <div className="mission-statement-text-container">
                  {missionStatementHeader ? (
                    <div
                      className={`mission-statement-title ${missionTitleStyles}`}
                    >
                      {missionStatementHeader}
                    </div>
                  ) : (
                    <div
                      className={`mission-statement-title ${missionTitleStyles}`}
                    >
                      {pronoun} Commitment to You
                    </div>
                  )}
                  <div
                    className={`mission-statement-text ${missionStatementTextStyles}`}
                  >
                    {missionStatementContent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default MissionStatementBlock;
