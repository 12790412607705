import React, { Component } from "react";

class FooterButtons extends Component {
  render() {
    const { handlePMAIssuesDialog } = this.props;
    return (
      <div className="footer-buttons">
        <div className="approval-button">
          <p>All Data Above is Approved</p>
        </div>
        <div
          className="issue-button"
          onClick={event => handlePMAIssuesDialog(true)}
        >
          <p>Report a Data Issue</p>
        </div>
      </div>
    );
  }
}

export default FooterButtons;
