import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import styles from "./styles";
import compose from "recompose/compose";
import { withSnackbar } from "notistack";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import classNames from "classnames";
import MailProButton from "../../UI/MailProButton";
import NoteAutomatorNoteEditor from "../Common/NoteAutomatorNoteEditor";
import NoteAutomatorPSEditor from "../Common/NoteAutomatorPSEditor";
import { noteUtils } from "../Common/NoteUtils";
import NoteAutomatorNoteReviewDialog from "../Common/NoteAutomatorNoteReviewDialog";
import PersonalNoteOverride from "../Common/PersonalNoteOverride";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AnniversaryAutomatorNoteandPSEditorContainer(props) {
  const [open, setOpen] = React.useState(false);
  const [showReviewDialog, setReviewDialog] = React.useState(false);
  const [showOverideSection, setOverrideSection] = React.useState(false);

  const {
    classes,
    property,
    cameFrom,
    autoOpenPSSelector,
    isBlocked,
    agentData,
  } = props;
  const {
    // textPS, //personal note
    // textPSDate,
    // personalPS, //personal ps
    // personalPSDate,
    typePS,
  } = property.changeDetails ? property.changeDetails : {};

  const { greetTextTop, signOffTextBottom } =
    noteUtils.getPersonalNoteGreetingTexts(property, agentData, 'and');

  const autoOpenPSSelectorScrollRef = useRef();

  const [notePS, setNotePS] = React.useState({
    personalNote: "",
    plainNote: "",
    personalPS: "",
    noteErrors: [],
    psErrors: [],
  });

  useEffect(() => {
    //in start we need to auto update notePS in case parent data changes
    setNotePS({
      personalNote: props.lastNoteTextHtml,
      plainNote: noteUtils.getPlainTextFromRichEditorState(
        props.lastNoteTextHtml
      ),
      personalPS: props.lastNotePS,
    });
    const isOverrideNotePS = noteUtils.shouldOveridePersonalNote(
      property,
      cameFrom
    );
    setOverrideSection(isOverrideNotePS);
  }, [props.lastNoteTextHtml, props.lastNotePS]);

  const handleDialogEntered = () => {
    if (autoOpenPSSelector && autoOpenPSSelectorScrollRef && autoOpenPSSelectorScrollRef.current) {
      setTimeout(() => {
        autoOpenPSSelectorScrollRef.current.scrollBy({
          top: 150,
          behavior: 'smooth'
      });
      }, 200);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateNote = (htmlData, plainText) => {
    setNotePS({
      ...notePS,
      ...{
        personalNote: htmlData,
        plainNote: plainText,
        noteErrors: [],
      },
    });
  };

  const updatePlainNote = (plainData) => {
    setNotePS({
      ...notePS,
      ...{
        plainNote: plainData,
      },
    });
  };

  const updatePS = (psValue) => {
    setNotePS({ ...notePS, ...{ personalPS: psValue, psErrors: [] } });
  };

  const onReviewYesClick = () => {
    setReviewDialog(false);
    props.onSuccessUpdate(notePS.personalNote, notePS.personalPS);
    handleClose();
  };

  const onCancelOverride = () => {
    handleClose();
  };

  const handleSubmitClick = () => {
    const noteErrors = noteUtils.checkPersonalNotePSForErrors(
      isBlocked,
      "personalNote",
      notePS.personalNote,
      notePS.plainNote,
      notePS.personalPS,
      cameFrom
    );
    if (noteErrors.length > 0) {
      setNotePS({ ...notePS, ...{ noteErrors } });
      return;
    }
    const psErrors = noteUtils.checkPersonalNotePSForErrors(
      isBlocked,
      "personalPS",
      notePS.personalNote,
      notePS.plainNote,
      notePS.personalPS,
      cameFrom
    );
    if (psErrors.length > 0) {
      setNotePS({ ...notePS, ...{ psErrors } });
      return;
    }
    setReviewDialog(true);
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{props.children}</div>
      {showReviewDialog && (
        <NoteAutomatorNoteReviewDialog
          greetTextTop={greetTextTop}
          signOffTextBottom={signOffTextBottom}
          personalNote={notePS.personalNote}
          personalPS={notePS.personalPS}
          onYesClick={onReviewYesClick}
          onNoClick={() => setReviewDialog(false)}
          headerText={"This is how your Personalized Anniversary Note will print."}
        />
      )}
      {open && (
        <Dialog
          onClose={handleClose}
          //maxWidth="sm"
          fullWidth={true}
          classes={{
            paper: classNames(
              classes.root,
              classes.rootPaper,
              classes.rootPaperDecorative,
              classes.rootPaperRadius,
              classes.editorDialog
            ),
          }}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          BackdropProps={{
            classes: {
              root: classes.backDropBackgroundLightBlue,
            },
          }}
          onEntered={handleDialogEntered}
          //innerRef={autoOpenPSSelectorScrollRef}
          PaperProps={{ref: autoOpenPSSelectorScrollRef}}
          disableBackdropClick
        >
          <DialogTitle className={classes.editorDialogHeader}>
            <div>You are editing this Homeowner’s Anniversary Note</div>
            <div>
              {noteUtils.getPersonalNoteGreetingTextsWithFullName(property, 'and')}
            </div>
          </DialogTitle>
          <DialogContent
            className={classes.editorDialogContent}
            
          >
            {showOverideSection ? (
              <PersonalNoteOverride
                typePS={typePS}
                onOverride={() => setOverrideSection(false)}
                onCancel={onCancelOverride}
              />
            ) : (
              <div className={classes.editorDialogContainer}>
                <div>
                  <NoteAutomatorNoteEditor
                    preText={greetTextTop + ","}
                    postText={signOffTextBottom}
                    showPlaceholder={true}
                    minLength={100}
                    maxLength={200}
                    placeholder={"Type your Anniversary Note here..."}
                    onChange={updateNote}
                    updatePlainPSFn={updatePlainNote}
                    preData={props.lastNoteTextHtml}
                    readOnly={false}
                    category={"note"}
                    noteMaxLength={noteUtils.getPersonalNoteSizes().personalNoteMaxLength}
                    noteMinLength={noteUtils.getPersonalNoteSizes().personalNoteMinLength}
                    errorMessages={notePS.noteErrors}
                  />
                </div>
                <div></div>
                <div>
                  {/* <NoteAutomatorPSEditor
                    property={property}
                    autoOpenPSSelector={autoOpenPSSelector}
                    errorMessages={notePS.psErrors}
                    onChange={updatePS}
                    currentPS={notePS.personalPS}
                    key={'aa-ps-editor-main'}
                    psMaxLength={noteUtils.getPersonalNoteSizes().personalPSMaxLength}
                    psMinLength={noteUtils.getPersonalNoteSizes().personalPSMinLength}
                    placeholder={"Enter an anniversary P.S. here..."}
                  /> */}
                </div>
              </div>
            )}
          </DialogContent>
          {!showOverideSection && (
            <DialogActions>
              <div className={classes.editorDialogFooter}>
                <div>
                  <MailProButton
                    key={"cancel_anniversary_note_editor"}
                    variant="outlined"
                    label={"CANCEL"}
                    customStyle={{
                      fontSize: "16px",
                      fontWeight: 600,
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                    onClick={handleClose}
                  />
                </div>
                <div>
                  <MailProButton
                    iKey={"submit_anniversary_note_editor"}
                    label={"SUBMIT"}
                    variant="filled"
                    onClick={handleSubmitClick}
                    customStyle={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "white",
                      paddingLeft: "24px",
                      paddingRight: "24px",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                  />
                </div>
                <div></div>
              </div>
            </DialogActions>
          )}
        </Dialog>
      )}
    </div>
  );
}

export default compose(
  withStyles(styles),
  withSnackbar
)(AnniversaryAutomatorNoteandPSEditorContainer);
