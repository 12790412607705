import React, { Component } from "react";
import QVFourSpeedometer from "./QVFourSpeedometer";
import QVFourSpeedometerV2 from "./QVFourSpeedometerV2";
import QVFourSellerGraphContainer from "./QVFourSellerGraphContainer";
import QVFourDotGraph from "./QVFourDotGraph";
import QVFourEmailAndWebsiteFooter from "./QVFourEmailAndWebsiteFooter";
import { propertyUtil } from "views/Pma/PropertyUtil";

class QVFourSecondLeft extends Component {
  render() {
    const {
      quarterlyCityTable,
      agentData,
      currentArea,
      quarterlyAreaTable,
      hoData,
      printVersion,
    } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID } = currentArea;
    const { calculateQSellersAdvantages, useAreaOrCityYEGraph } = propertyUtil;
    const {
      marketTrends: marketTrendsArea,
      marketTrendsPercentage: marketTrendsPercArea,
      graphAverages: graphAveragesArea,
    } = quarterlyAreaTable;

    const {
      marketTrends: marketTrendsCity,
      marketTrendsPercentage: marketTrendsPercCity,
      graphAverages: graphAveragesCity,
    } = quarterlyCityTable;
    //  console.log("graphaveragesArea", graphAveragesArea);
    console.log("marketTrendsPercArea", marketTrendsPercArea);
    console.log("marketTrendArea", marketTrendsArea);
    //console.log(quarterlyAreaTable);
    let useMedian = false;
    if (listgenUserID === "x102548") {
      useMedian = true;
    }

    let currentYearObjArea = marketTrendsArea.filter(
      (obj) => obj.TrendName === "6-Months Trending"
    );
    //  console.log("currentYearObjArea", currentYearObjArea);
    let currentYearAreaPerc = marketTrendsPercArea.filter(
      (obj) => obj.TrendPercentageName === "Same 6 Months Trends Percentage"
    );
    console.log("currentYearAreaPerc", currentYearAreaPerc);
    //console.log("currentYearAreaPerc", currentYearAreaPerc);
    const pastYearObjArea = marketTrendsArea.filter(
      (obj) => obj.TrendName === "Same 6-Months Last Year Trending"
    );
    //  console.log("pastYearObjArea", pastYearObjArea);
    let currentYearObjCity = marketTrendsCity.filter(
      (obj) => obj.TrendName === "6-Months Trending"
    );
    //console.log("currentYearObjCity", currentYearObjCity);

    let currentYearCityPerc = marketTrendsPercCity.filter(
      (obj) => obj.TrendPercentageName === "6 Months Trends Percentage"
    );
    //console.log("currentYearCityPerc", currentYearCityPerc);
    const pastYearObjCity = marketTrendsCity.filter(
      (obj) => obj.TrendName === "Past 6-Months Trending"
    );
    //  console.log("pastYearObjCity", pastYearObjCity);
    //-------
    // let currentYearObjArea = marketTrendsArea.filter(
    //   (obj) => obj.TrendName === "1-Year Trending"
    // );
    //
    // let currentYearAreaPerc = marketTrendsPercArea.filter(
    //   (obj) => obj.TrendPercentageName === "1 Year Trends Percentage"
    // );
    // const pastYearObjArea = marketTrendsArea.filter(
    //   (obj) => obj.TrendName === "Past 1-Year Trending"
    // );
    // let currentYearObjCity = marketTrendsCity.filter(
    //   (obj) => obj.TrendName === "1-Year Trending"
    // );
    // let currentYearCityPerc = marketTrendsPercCity.filter(
    //   (obj) => obj.TrendPercentageName === "1 Year Trends Percentage"
    // );
    // const pastYearObjCity = marketTrendsCity.filter(
    //   (obj) => obj.TrendName === "Past 1-Year Trending"
    // );
    //--------
    // let currentYearObjArea = marketTrendsArea.filter(
    //   (obj) => obj.TrendName === "Current Year First Half Trending"
    // );
    // let currentYearAreaPerc = marketTrendsPercArea.filter(
    //   (obj) =>
    //     obj.TrendPercentageName ===
    //     "Current Year First Half - Last Year Second Half Trends Percentage"
    // );
    // const pastYearObjArea = marketTrendsArea.filter(
    //   (obj) => obj.TrendName === "Past Year Second Half Trending"
    // );
    //
    // let currentYearObjCity = marketTrendsCity.filter(
    //   (obj) => obj.TrendName === "Current Year First Half Trending"
    // );
    //
    // let currentYearCityPerc = marketTrendsPercCity.filter(
    //   (obj) =>
    //     obj.TrendPercentageName ===
    //     "Current Year First Half - Last Year Second Half Trends Percentage"
    // );
    // const pastYearObjCity = marketTrendsCity.filter(
    //   (obj) => obj.TrendName === "Past Year Second Half Trending"
    // );

    const calculateSellersAdvantages = calculateQSellersAdvantages(
      ...currentYearAreaPerc,
      ...currentYearObjArea,
      ...currentYearCityPerc,
      ...currentYearObjCity
    );
    const {
      areaSellersAdvantages,
      citySellersAdvantages,
    } = calculateSellersAdvantages;

    // let determineCityOrArea = useAreaOrCityYEGraph(
    //   calculateSellersAdvantages,
    //   mlsAreaID
    // );
    let determineCityOrArea = "area";
    //console.log(this.props);
    // console.log("currentYearAreaPerc", currentYearAreaPerc);
    return (
      <div className="qv4-second-left">
        <QVFourSpeedometerV2
          currentArea={currentArea}
          quarterlyCityTable={quarterlyCityTable}
          quarterlyAreaTable={quarterlyAreaTable}
          determineCityOrArea={determineCityOrArea}
          areaSellersAdvantages={areaSellersAdvantages}
          citySellersAdvantages={citySellersAdvantages}
          calculateSellersAdvantages={calculateSellersAdvantages}
          hoData={hoData}
          currentYearObjArea={currentYearObjArea}
          pastYearObjArea={pastYearObjArea}
          currentYearObjCity={currentYearObjCity}
          pastYearObjCity={pastYearObjCity}
          agentData={agentData}
        />
        <QVFourSellerGraphContainer
          agentData={agentData}
          quarterlyCityTable={quarterlyCityTable}
          quarterlyAreaTable={quarterlyAreaTable}
          marketTrendsArea={marketTrendsArea}
          marketTrendsPercArea={marketTrendsPercArea}
          marketTrendsCity={marketTrendsCity}
          marketTrendsPercCity={marketTrendsPercCity}
          calculateSellersAdvantages={calculateSellersAdvantages}
          graphAveragesArea={graphAveragesArea}
          graphAveragesCity={graphAveragesCity}
          determineCityOrArea={determineCityOrArea}
          currentYearObjArea={currentYearObjArea}
          currentYearObjCity={currentYearObjCity}
          currentYearAreaPerc={currentYearAreaPerc}
          currentYearCityPerc={currentYearCityPerc}
          pastYearObjArea={pastYearObjArea}
          pastYearObjCity={pastYearObjCity}
          printVersion={printVersion}
          currentArea={currentArea}
          useMedian={useMedian}
        />
        <QVFourEmailAndWebsiteFooter
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
      </div>
      //adding comment
    );
  }
}

export default QVFourSecondLeft;
