import { el } from "date-fns/locale";

export const HomeValuationList = [
    { key: "-1", value: "I'll Rate this Homeowner later" },
    { key: "0", value: "No Chance of listing their property" },
    { key: "1", value: "Very unlikely to list" },
    { key: "2", value: "Unlikely to list" },
    { key: "3", value: "May possibly list later" },
    { key: "4", value: "50 / 50 chance of listing" },
    { key: "5", value: "Highly likely to list!" },
];

export const BestHomeValuationTag = "Hot Seller Lead";

export const isAVeryGoodListing = (isHomeValuationGivenByAgent, likelyhoodOfListing) => {
    return (isHomeValuationGivenByAgent || true) && likelyhoodOfListing >= 4 ? true : false;
}

export const checkForHomeValuationErrors = (property) => {
    const { likelyhoodOfListing, isHomeValuationGivenByAgent, approxDateOfListing, homeValuationGivenDate } = property;
    let isError = false;
    const homeValuationErrors = [];
    const likelihoodErrors = [];

    if (!isHomeValuationGivenByAgent && !likelyhoodOfListing) {
        isError = true
        homeValuationErrors.push('Please select at least one option. If you have provided a valuation, you can choose that option; otherwise, you may select the I\'ll rate later option.');
    } else {


        if (isHomeValuationGivenByAgent) {
            if (!homeValuationGivenDate) {
                isError = true
                homeValuationErrors.push('Please select the date you provided a Home Valuation to this Homeowner.')
            } else if (!likelyhoodOfListing) {
                isError = true
                likelihoodErrors.push('Please choose an option from the likelihood of Listing selections.');
            }
        }

        // if (!isHomeValuationGivenByAgent && likelyhoodOfListing && likelyhoodOfListing != -1) {
        //     isError = true
        //     homeValuationErrors.push('Looking like you have selected likelihood of listing option. Now please provide home valuation.');
        // }
    }
    return { homeValuation: homeValuationErrors, likelihood: likelihoodErrors, isError };
}

export const getEmptyHomeValuationData = (mailerProID) => {
    return {
        mailerProID,
        isHomeValuationGivenByAgent: false,
        likelyhoodOfListing: null,
        approxDateOfListing: null,
        homeValuationGivenDate: null,
        agentCommentsOnHO: null,
        isRequestedForValuation: false,
        valuationRequestedDateTime: null,
    }
}