import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";

class ActivePendingRow extends Component {
  getMonthAndYearString = inputTime => {
    // console.log("inputTime", inputTime);
    try {
      let month = moment(inputTime).format("M");
      let day = moment(inputTime).format("D");
      let year = moment(inputTime).format("YY");
      // console.log("month", month);
      // console.log("day", day);
      // console.log("year", year);
      return (
        <p>
          {month}&#8729;
          {/*<div className="market-table-sale-date-separator"></div>*/}
          {day}&#8729;
          {/*<div className="market-table-sale-date-separator"></div>*/}
          {year}
        </p>
      );
      // return moment(inputTime).format("MMM D, YYYY");
    } catch (err) {
      return "";
    }
  };

  calculatePricePerSqft(calculation, saleP, sqft) {
    if (calculation === "-") {
      let res = Math.round(saleP / sqft);
      return res.toLocaleString();
    } else {
      return Math.round(calculation).toLocaleString();
    }
  }
  render() {
    const {
      getPropView,
      formatSuffix,
      formatBaths,
      toAcre,
      cutName,
      propTypeAcronym,
      shortenStatus,
      shortenPropType
    } = propertyUtil;
    const { shortenYear } = propertyUtil;
    const { solds, mlsAreaID, older, isSingleTable } = this.props;
    //console.log("row", this.props);
    let isCountryClubGate = mlsAreaID === "132" ? true : false;
    console.log("solds", solds);
    const listMap =
      solds &&
      solds.map((list, i) => (
        <tr
          key={`${list.address} ${list.listPrice} ${list.saleDate} ${i}`}
          className={`${list.status.toLowerCase()} ${
            older ? "presolds-row" : "recent-row"
          } ${isSingleTable ? "single-table-row" : ""}`}
        >
          <td>{i + 1}</td>
          <td className="details-td">Details</td>
          <td
            // className={`status-text`}
            className={`status-text ${list.status.toLowerCase()}`}
            align="left"
          >
            <p>{shortenStatus(list.status, "pendingOnly")}</p>
          </td>

          {!list.listDate || list.listDate === "-" ? (
            <td>XX</td>
          ) : (
            <td className="market-table-sale-date-row">
              {this.getMonthAndYearString(list.listDate)}
            </td>
          )}
          <td>{list.daysOnMarket}</td>
          <td className="prop-type-text">
            {propTypeAcronym(list.propertyType)}
          </td>
          <td>
            <p>
              {list.streetNumber === "-" || list.streetNumber === "0"
                ? null
                : list.streetNumber}{" "}
              {list.streetName}{" "}
              {list.streetSuffix && formatSuffix(list.streetSuffix, mlsAreaID)}
              {!isCountryClubGate &&
              list.propertyType === "Townhouse" &&
              list.unitNumber !== "-"
                ? `, #${list.unitNumber}`
                : ""}
            </p>
          </td>

          <td className="price-td">
            <div>
              <p>$</p>
              <p>{Number(list.listPrice).toLocaleString()}</p>
            </div>
          </td>
          <td>XXXX</td>
          <td>XXX</td>
          <td>{list.pricePerSquareFt}</td>
        </tr>
      ));
    return <tbody className="active-pending-tbody">{listMap}</tbody>;
  }
}

export default ActivePendingRow;
