import React, { Component } from "react";

class QVThreeEconomists extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }
  }
  render() {
    const { printVersion } = this.props;
    return (
      <div
        className={
          printVersion ? "print-qv3-economists" : "editor-qv3-economists"
        }
      >
        <div className="qv3-economists-title-container">
          <div className="qv3-economists-title-text">2022 Predictions</div>
          <div className="qv3-economists-title-divider"></div>
          <div className="qv3-economists-title-subtitle">
            Top Economists Weigh in
          </div>
        </div>
        <div className="qv3-economists-body">
          <div className="qv3-economists-section">
            <div className="qv3-economists-section-title">Rising Prices</div>
            <div className="qv3-economists-section-content">
              <p>California housing market to remain</p>
              <p>solid if pandemic is kept under control.</p>
              <p>The median home price is forecast to rise</p>
              <p>5.2% in 2022, following a projected 20.3%</p>
              <p>increase in 2021.</p>
            </div>
            <div className="qv3-economists-section-author">
              <p>2022 Housing Market Forecast</p>
              <p>California Association of Realtors</p>
            </div>
          </div>

          <div className="qv3-economists-section">
            <div className="qv3-economists-section-title">STRONG MARKET</div>
            <div className="qv3-economists-section-content">
              <p>All markets are seeing strong conditions</p>
              <p>and home sales are the best they have been</p>
              <p>in 15 years. The housing sector's success</p>
              <p>will continue, but I don't expect next year's</p>
              <p>performance to exceed this year's.</p>
            </div>
            <div className="qv3-economists-section-author">
              <p>Lawrence Yun, Chief Economist</p>
              <p>National Association of Realtors</p>
            </div>
          </div>

          <div className="qv3-economists-section">
            <div className="qv3-economists-section-title">HOUSING SHORTAGE</div>
            <div className="qv3-economists-section-content">
              <p>Of all the shortages afflicting the US economy,</p>
              <p>the housing shortage might last the longest.</p>
              <p>The supply-demand imbalance that has been</p>
              <p>the basis for our call for a multi-year boom in</p>
              <p>home prices remains intact.</p>
            </div>
            <div className="qv3-economists-section-author">
              <p>Goldman Sachs</p>
            </div>
          </div>

          <div className="qv3-economists-section">
            <div className="qv3-economists-section-title">MORTGAGE RATES</div>
            <div className="qv3-economists-section-content">
              <p>Right now, we forecast mortgage rates</p>
              <p>to average 3.3 percent in 2022, which,</p>
              <p>though slightly higher than 2020 and</p>
              <p>2021, by historical standards remains</p>
              <p>extremely low...</p>
            </div>
            <div className="qv3-economists-section-author">
              <p>Doug Duncan, Sr VP</p>
              <p>Chief Economist - Fannie Mae</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QVThreeEconomists;
