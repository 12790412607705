import React, { Component } from "react";
import QVFourPhotoSection from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotoSection";
import QVFourPresentedByFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPresentedByFooter";
import QVFourAgentLogoHeader from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourAgentLogoHeader";
import QVFourPS from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPS";

import YETwoShortenedMarketTable from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoSecondPage/YETwoShortenedMarketTable";
import MQThreeAvailableHomes from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeAvailableHomes";
import GNQRCode from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/GNQRCode";

class YETwoSecondRight extends Component {
  sortByStatus = (propList, getExtendedTable, alwaysExtendedTable) => {
    const { mlsAreaID } = this.props.currentArea;
    const { listgenUserID } = this.props.agentData;

    const {
      activePhotoDetailsList,
      contingentPhotoDetailsList,
      pendingPhotoDetailsList,
      preSoldPhotoDetailsList,
      recentSoldPhotoDetailsList,
      agentFilteredPhotoDetailsList,
    } = this.props.quarterlyAreaTable;

    let isLoriB = listgenUserID === "102548";
    let sold = recentSoldPhotoDetailsList ? recentSoldPhotoDetailsList : [];
    let pend = pendingPhotoDetailsList ? pendingPhotoDetailsList : [];
    let cont = contingentPhotoDetailsList ? contingentPhotoDetailsList : [];
    let active = isLoriB
      ? activePhotoDetailsList.slice(0, 7)
      : activePhotoDetailsList
      ? activePhotoDetailsList
      : [];
    let laterSold = preSoldPhotoDetailsList ? preSoldPhotoDetailsList : [];
    let agentsProps = agentFilteredPhotoDetailsList
      ? agentFilteredPhotoDetailsList
      : [];

    // console.log("active", active);
    // console.log("pend", pend);
    // if (listgenUserID === "102288") {
    //   let avenidaPrincipalProp = agentsProps.filter(
    //     (property) =>
    //       property.streetName === "Avenida Principal" &&
    //       property.streetNumber === "24725"
    //   );
    //
    //   laterSold.unshift(avenidaPrincipalProp[0]);
    //   agentsProps = agentsProps.filter(
    //     (property) =>
    //       property.streetName !== "Avenida Principal" &&
    //       property.streetNumber !== "24725"
    //   );
    // }

    // console.log("sold", sold);
    // console.log("laterSold", laterSold);
    let last45DaysSum =
      (agentFilteredPhotoDetailsList ? agentFilteredPhotoDetailsList : 0) +
      (activePhotoDetailsList ? activePhotoDetailsList.length : 0) +
      (contingentPhotoDetailsList ? contingentPhotoDetailsList.length : 0) +
      (pendingPhotoDetailsList ? pendingPhotoDetailsList.length : 0) +
      (recentSoldPhotoDetailsList ? recentSoldPhotoDetailsList.length : 0);

    let remainderPreSold = 12 - last45DaysSum;

    const sortByStatusThenDate = (data) => {
      let sortedSolds = data
        .filter((x) => x.status === "Sold")
        .sort((a, b) => new Date(b.saleDate) - new Date(a.saleDate));

      let newConstructed = [
        ...data.filter((x) => x.status === "Active"),
        ...data.filter(
          (x) => x.status === "Pending" || x.status === "Contingent"
        ),
        ...sortedSolds,
      ];
      let uniqueProps = newConstructed.filter(
        (obj1, i, arr) =>
          arr.findIndex(
            (obj2) =>
              obj2.streetNumber === obj1.streetNumber &&
              obj2.streetName === obj1.streetName &&
              obj2.status === obj1.status
          ) === i
      );

      return uniqueProps;
    };

    let trueSort1 = [
      ...agentsProps,
      ...active,
      ...pend,
      ...cont,
      ...sold,
      ...laterSold.slice(
        0,
        mlsAreaID === "SCL-1V" ? remainderPreSold + 2 : remainderPreSold
      ),
    ];

    let trueSort = sortByStatusThenDate(trueSort1);

    if (
      (last45DaysSum < 12 && listgenUserID !== "101268") ||
      alwaysExtendedTable
    ) {
      if (getExtendedTable) {
        return false;
      } else {
        return trueSort;
      }
    } else {
      let today = new Date();
      let date45DaysAgo = new Date(
        today.setDate(today.getDate() - 45)
      ).toISOString();

      let last45DayDates = trueSort.filter(
        (x) =>
          (x.saleDate !== "-" && x.saleDate > date45DaysAgo) ||
          x.status == "Active" ||
          x.status === "Contingent" ||
          x.status === "Pending"
      );

      let remainderExtended = 18 - last45DayDates.length;

      let extendedTrueSort1 = [
        ...agentsProps,
        ...active,
        ...pend,
        ...cont,
        ...sold,
        ...laterSold.slice(0, remainderExtended),
      ];
      let extendedTrueSort = sortByStatusThenDate(extendedTrueSort1);

      if (getExtendedTable) {
        return true;
      } else {
        return extendedTrueSort.slice(0, 12);
      }
    }
    // return propList.slice(0, 19);
  };
  render() {
    const {
      currentArea,
      agentData,
      printVersion,
      version,
      hoData,
      mlsAreas,

      demoAccountHOName,

      photoTableData,
      quarterlyAreaTable,
      quarterlyCityTable,
      MQCityDetails,
      isDemo,
    } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID } = currentArea;
    const { currentSoldListings } = quarterlyAreaTable;
    let useExtendedTable = false;
    if (listgenUserID === "105886") {
      useExtendedTable = true;
    }
    let sorted =
      currentSoldListings && currentSoldListings
        ? this.sortByStatus(currentSoldListings, false, useExtendedTable)
        : [];

    // let useExtendedTable =
    //   currentSoldListings && currentSoldListings
    //     ? this.sortByStatus(currentSoldListings, true)
    //     : [];
    // let isBellaterra = mlsAreaID === "SCL-16M";
    // if (isBellaterra) {
    //   useExtendedTable = false;
    // }

    let useCity = false;
    if (mlsAreaID === "SBA-40F" || mlsAreaID === "SBA-40G") {
      useCity = true;
    }

    if (mlsAreaID.includes("xSBA")) {
      return (
        <div
          className={`${
            version === "year-end-2022" ? "ye2" : "qv4"
          }-second-right`}
        >
          <QVFourAgentLogoHeader
            agentData={agentData}
            currentArea={currentArea}
          />
          {hoData &&
            hoData.changeDetails &&
            Object.keys(hoData.changeDetails).length > 1 && (
              <QVFourPS
                agentData={agentData}
                hoData={hoData}
                currentArea={currentArea}
                mlsAreas={mlsAreas}
                version={version}
                printVersion={printVersion}
                mailingNum="qv4"
                demoAccountHOName={demoAccountHOName}
                isDemo={isDemo}
              />
            )}
          <GNQRCode hoData={hoData} agentData={agentData} smallerQR={true} />
          <QVFourPhotoSection
            currentArea={currentArea}
            agentData={agentData}
            printVersion={printVersion}
            version={version}
            hoData={hoData}
            currentSoldListings={currentSoldListings}
          />
          <QVFourPresentedByFooter
            agentData={agentData}
            currentArea={currentArea}
            currentSoldListings={currentSoldListings}
            printVersion={printVersion}
          />
        </div>
      );
    } else {
      return (
        <div
          className={`${
            version === "year-end-2022" ? "ye2" : "qv4"
          }-second-right`}
        >
          <QVFourAgentLogoHeader
            agentData={agentData}
            currentArea={currentArea}
          />
          {hoData &&
            hoData.changeDetails &&
            Object.keys(hoData.changeDetails).length > 1 && (
              <QVFourPS
                agentData={agentData}
                hoData={hoData}
                currentArea={currentArea}
                mlsAreas={mlsAreas}
                version={version}
                printVersion={printVersion}
                mailingNum="qv4"
                demoAccountHOName={demoAccountHOName}
                isDemo={isDemo}
              />
            )}
          <GNQRCode hoData={hoData} agentData={agentData} />
          <YETwoShortenedMarketTable
            quarterlyCityTable={quarterlyCityTable}
            currentArea={currentArea}
            quarterlyAreaTable={
              useCity ? quarterlyCityTable : quarterlyAreaTable
            }
            useCity="false"
            hoData={hoData}
            agentData={agentData}
            MQCityDetails={MQCityDetails}
            lastMidQuarterlyPrintDate={
              quarterlyAreaTable.lastMidQuarterlyPrintDate
            }
            //printDate="Nov 1, 2021"
            printVersion={printVersion}
            currentSoldListings={currentSoldListings}
            sorted={sorted}
            useExtendedTable={useExtendedTable}
          />
          {!useExtendedTable && (
            <MQThreeAvailableHomes
              currentArea={currentArea}
              quarterlyAreaTable={quarterlyAreaTable}
              quarterlyCityTable={quarterlyCityTable}
              MQCityDetails={MQCityDetails}
              hoData={hoData}
              agentData={agentData}
              useCity={useCity}
              altVersion="may-2024"
              printVersion={printVersion}
            />
          )}

          <QVFourPresentedByFooter
            agentData={agentData}
            currentArea={currentArea}
            currentSoldListings={currentSoldListings}
            printVersion={printVersion}
          />
        </div>
      );
    }
  }
}

export default YETwoSecondRight;
