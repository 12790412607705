import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import DownArrow from "./verification-arrow-down.png";
import UpArrow from "./verification-arrow-up.png";

class MQThreeIndicatorSection extends Component {
  formatPrevData = data => {
    const { roundSalePrice } = propertyUtil;
    const { category, compareDays } = this.props;
    let formattedData;
    if (category === "Sale Prices") {
      formattedData = `${roundSalePrice(data)}`;
    } else if (category === "Days to Sell") {
      formattedData = `${data} Days`;
    } else if (category === "Square Ft") {
      formattedData = `$${Number(data).toLocaleString()}`;
    } else if (category === "% Received of Asking") {
      formattedData = `${data}%`;
    }
    return `Previous ${compareDays}: ${formattedData}`;
  };
  formatCategory = category => {
    if (category === "Sale Prices") {
      return (
        <div className="indicator-category">
          <p>Avg Sale Price</p>
        </div>
      );
    } else if (category === "Days to Sell") {
      return (
        <div className="indicator-category">
          <p>Avg Days on Market</p>
        </div>
      );
    } else if (category === "Square Ft") {
      return (
        <div className="indicator-category">
          <p>Avg Price Per Sq Ft</p>
        </div>
      );
    } else if (category === "Homes for Sale") {
      return (
        <div className="indicator-category">
          <p>For Sale</p>
        </div>
      );
    } else if (category === "% Received of Asking") {
      return (
        <div className="indicator-category">
          <p>% Received of Asking</p>
        </div>
      );
    }
  };
  formatValue = value => {
    const { category } = this.props;
    const { roundSalePrice } = propertyUtil;
    if (category === "Sale Prices") {
      //console.log("sale prices", value);
      return `${roundSalePrice(value).toLowerCase()}`;
    } else if (category === "Days to Sell") {
      return `${value} Days`;
    } else if (category === "Square Ft") {
      return `$${Number(value).toLocaleString()}`;
    } else if (category === "Homes for Sale") {
      return `${value} ${value == 1 ? "Home" : "Homes"}`;
    } else if (category === "% Received of Asking") {
      return `${Math.round(value)}%`;
    }
  };
  formatArrow = perc => {
    perc = +perc;
    if (perc > 0) {
      // return <div className="indicator-triangle-up" />;
      return (
        <img src={UpArrow} alt="Up Arrow" className="mq3-indicator-arrow-up" />
      );
    } else if (perc < 0) {
      return (
        <img
          src={DownArrow}
          alt="Down Arrow"
          className="mq3-indicator-arrow-down"
        />
      );
      // return <div className="indicator-triangle-down" />;
    } else if (perc === 0) {
      return <div className="indicator-balanced">No Change</div>;
    }
  };
  render() {
    const { category, value, perc, prev } = this.props;
    // console.log("perc", perc);
    // console.log("indicator props", this.props);
    const { formatCategory, formatValue, formatArrow, formatPrevData } = this;
    const { posOrNeg, infinityCheck } = propertyUtil;
    return (
      <div className="indicator-section-col">
        <div className="indicator-section">
          <div className="indicator-title-container">
            {formatCategory(category)}
            <p className="indicator-value">{formatValue(value)}</p>
            <div className="indicator-title-bottom-border" />
          </div>
          <div className="indicator-col-1">
            {perc == "0" ? (
              ""
            ) : (
              <p className="indicator-perc">{posOrNeg(perc, null, true)}%</p>
            )}

            {formatArrow(perc)}
          </div>
        </div>
        <div className="prev-data-text">{formatPrevData(prev)}</div>
      </div>
    );
  }
}

export default MQThreeIndicatorSection;
