import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";

class QVFourSpeedometerV2 extends Component {
  determineSpeedometerImg = (len, list) => {
    const { mlsAreaID } = this.props.currentArea;

    // Nat: if sale price is down and everything else is up, it should be 2pm not 3pm
    // If ¾
    // 12pm - if avg sale price is down more than 1% & 2 out of the other 3 are to the seller’s advantages. Goes to noon. Disadvantage [avg sale price, dom]:
    // If 2 are disadvantage including avg sale price & 2 are advantage, noon.
    // 2pm: avg sale price down & 1 of the other 3 is also a negative
    // 3pm pinned: avg sale price up & ⅔ are seller’s advantage

    if (len >= 3) {
      return "https://d2onbxnrte2nim.cloudfront.net/speedometer-4pm.png";
    } else if (len === 2) {
      return "https://d2onbxnrte2nim.cloudfront.net/speedometer-2pm.png";
    } else if (len === 1) {
      return "https://d2onbxnrte2nim.cloudfront.net/speedometer-10am.png";
    } else if (len === 0) {
      return "https://d2onbxnrte2nim.cloudfront.net/speedometer-8am.png";
    }
  };
  addCircleDividers = (areas) => {
    const { mlsAreaID } = this.props.currentArea;
    const { splitAreas } = propertyUtil;
    const { subdivisionName } = this.props.hoData;
    let areaSplit = splitAreas(areas);
    if (areas === "Ennisbrook Birnam Wood") {
      areaSplit = ["Ennisbrook", "Birnam Wood"];
    }
    let len = areaSplit.length;
    let charCount = areas.length;
    let speedometerSize;
    let singleLineSpeedometerSize;
    if (charCount > 48) {
      singleLineSpeedometerSize = "xxs-single-line-speedometer-area-text";
      speedometerSize = "xxs-speedometer-area-text";
    } else if (charCount >= 42) {
      singleLineSpeedometerSize = "xs-single-line-speedometer-area-text";
      speedometerSize = "xs-speedometer-area-text";
    } else if (charCount > 30) {
      singleLineSpeedometerSize = "small-single-line-speedometer-area-text";
      speedometerSize = "small-speedometer-area-text";
    }

    if (subdivisionName && subdivisionName.length > 1) {
      return (
        <div className="speedometer-subtitle">
          <div>{subdivisionName}</div>
        </div>
      );
    }
    if (charCount > 30 && areas.includes("/")) {
      return (
        <div className="speedometer-column-subtitle">
          <div>{areaSplit[0]}</div>
          {areaSplit[1] && <div>{areaSplit[1]}</div>}
          {areaSplit[2] && <div>{areaSplit[2]}</div>}
        </div>
      );
    } else if (mlsAreaID === "SCL-16J") {
      return <div className="speedometer-subtitle"></div>;
    } else if (mlsAreaID === "SCL-1SB") {
      return (
        <div className="speedometer-subtitle">
          Borello Ranch Estates & Alicante
        </div>
      );
    } else if (mlsAreaID === "SBA-20FInv") {
      return (
        <div className="speedometer-subtitle speedometer-subtitle-col">
          <div>SANTA BARBARA DOWNTOWN</div>
          <div>INVESTMENT PROPERTY</div>
        </div>
      );
    } else if (len === 1) {
      return (
        <div className={`speedometer-subtitle ${singleLineSpeedometerSize}`}>
          <div>{areaSplit[0]}</div>
        </div>
      );
    } else if (len === 2) {
      return (
        <div className="speedometer-subtitle">
          <div>{areaSplit[0]}</div>
          <div className="speedometer-circle-divider" />
          <div>{areaSplit[1]}</div>
        </div>
      );
    } else {
      return (
        <div className={`speedometer-subtitle ${speedometerSize}`}>
          <div>{areaSplit[0]}</div>{" "}
          <div className="speedometer-circle-divider" />{" "}
          <div>{areaSplit[1]}</div>
          <div className="speedometer-circle-divider" />{" "}
          <div>{areaSplit[2]}</div>
        </div>
      );
    }
  };

  calculateSubtitle = (advantages) => {
    if (advantages === 0) {
      return `Based on 2021 Statistical Analysis`;
    } else if (advantages === 1) {
      return "1 out of 3 Market Indicators Show Trending in Favor of Seller's";
    } else if (advantages === 2) {
      return `2 out of 3 Key Market Indicators are in Favor of Seller's`;
    } else if (advantages >= 3) {
      return `3 out of 3 Key Market Indicators are in Favor of Seller's`;
    }
  };
  render() {
    const {
      currentArea,
      determineCityOrArea,
      areaSellersAdvantages,
      citySellersAdvantages,
      calculateSellersAdvantages,
      currentYearObjArea,
      pastYearObjArea,
      currentYearObjCity,
      pastYearObjCity,
      agentData,
    } = this.props;
    const { listgenUserID } = agentData;
    const { mlsNeighborhood, mlsCityName, mlsAreaID } = currentArea;

    const {
      addCircleDividers,
      determineSpeedometerImg,
      calculateSubtitle,
    } = this;
    const { splitAreas, calculateDiff } = propertyUtil;
    let advantagesLength;
    let advantagesList;
    if (determineCityOrArea === "area") {
      if (
        !areaSellersAdvantages.includes("AVG SALE PRICE") &&
        areaSellersAdvantages.length >= 3
      ) {
        advantagesLength = areaSellersAdvantages.length - 1 || 0;
      } else {
        advantagesLength = areaSellersAdvantages.length;
      }
      advantagesList = areaSellersAdvantages;
    } else {
      if (
        !citySellersAdvantages.includes("AVG SALE PRICE") &&
        citySellersAdvantages.length >= 3
      ) {
        advantagesLength = citySellersAdvantages.length - 1 || 0;
      } else {
        advantagesLength = citySellersAdvantages.length;
      }

      advantagesList = citySellersAdvantages;
    }
    let marketText;
    //     Can you adjust the logic to change the header to 'It's a Shifting Market' when the Avg Sale Price goes down 2% or more and one other hot box is a negative to sellers?
    // See attached. I think in this shifting market it's a mistake to show it's Still a Seller's Market when the Avg Sale Price goes down even as little as 2%.
    // || advantages.length === 2 && avgSalePricePerc <= -2

    let salePriceTrendingPercPoint;
    if (determineCityOrArea === "area") {
      salePriceTrendingPercPoint = calculateDiff(
        currentYearObjArea[0].AvgSalePrice,
        pastYearObjArea[0].AvgSalePrice
      );
    } else {
      salePriceTrendingPercPoint = calculateDiff(
        currentYearObjCity[0].AvgSalePrice,
        pastYearObjCity[0].AvgSalePrice
      );
    }
    //  console.log("salePriceTrendingPercPoint", salePriceTrendingPercPoint);

    if (currentArea.mlsAreaID === "46C") {
      marketText = (
        <div>
          <p>A Year in Review</p>
        </div>
      );
    } else if (advantagesLength === 2 || salePriceTrendingPercPoint >= 1) {
      marketText = (
        <div>
          <p>It's Still a Seller's market</p>
        </div>
      );
    } else if (
      advantagesLength === 0 ||
      advantagesLength === 1 ||
      (advantagesLength === 2 && salePriceTrendingPercPoint <= -2) ||
      salePriceTrendingPercPoint <= -2
    ) {
      marketText = (
        <div>
          <p>It's a Shifting market</p>
        </div>
      );
    } else {
      marketText = (
        <div>
          <p>It's Still a Seller's market</p>
        </div>
      );
    }
    const { subdivisionName } = this.props.hoData;
    let areasLength = splitAreas(mlsNeighborhood).length;
    let charCount = mlsNeighborhood.length;
    let raiseTitle =
      mlsAreaID === "131133" ||
      mlsAreaID === "160" ||
      (charCount > 30 && mlsNeighborhood.includes("/"));

    return (
      <div className="qv4-speedometer-v2">
        {/*<div className="speedometer-bg-gradient"></div>*/}
        <img
          className="speedometer-background-img"
          src="https://pma-coverphotos.s3.amazonaws.com/non-covers/Dashboard_101024_BG_gold-ish_1.jpg"
          alt="Cloud"
        />
        <div className="speedometer-market-container-v2">
          <div className="speedometer-market-title-v2">Keeping an Eye on</div>
          <div className="speedometer-statistics-v2">
            {listgenUserID === "102843" ? (
              <p>Local Market Trends</p>
            ) : (
              <p>Market Trends that Matter</p>
            )}
          </div>
        </div>
        {determineCityOrArea === "area" ? (
          <div
            className={`speedometer-title-container-v2 ${
              raiseTitle && "raise-title"
            }`}
          >
            <div
              className={`${
                mlsAreaID === "SCL-16J"
                  ? "speedometer-subtitle"
                  : "speedometer-title"
              }`}
            >
              <p>Your Neighborhood Trends</p>
            </div>
            <div>{addCircleDividers(mlsNeighborhood)}</div>
          </div>
        ) : (
          <div className="speedometer-title-container-v2">
            <div className="speedometer-title">
              <p>Your City Trends</p>
            </div>
            <div>{addCircleDividers(mlsCityName)}</div>{" "}
          </div>
        )}
      </div>
    );
  }
}

export default QVFourSpeedometerV2;
