import React, { Component, Fragment } from "react";
import SOSLogo from "views/Pma/PmaEditor/CustomAgentPages/CustomImages/sb-sos-logo.png";
import { Link } from "react-router-dom";
import QuadrantEditorModal from "views/Pma/PmaEditor/TableQuadrants/TableQuadrantsPageOne/QuadrantEditorModal";
import { pmaActions } from "_store/_actions";
import { connect } from "react-redux";
import AddAreasModal from "views/Pma/PmaEditor/TableQuadrants/TableQuadrantsPageOne/AddAreasModal";

class QVFourQuadrantContainer extends Component {
  state = {
    editQuadrantFormData: null,
    oldQuadrantData: [],
    selectedQuadrantNumber: null,
    titleEdit: false,
    maxMainTitleLength: 35,
    addAreasModalOpen: false,
    filteredAreas: {},
    maxMainSubtitleLength: 57,
    savingMessageShowing: false,
  };

  //   [
  //     {
  //         "listgenUserID": "100047",
  //         "quadAreaID": "165",
  //         "quadAreaTitle": "List your home with my team",
  //         "quadAreaSubTitle": "We're passionate about estates of Miramonte",
  //         "quad1Title": "We add value",
  //         "quad1Text": "Our team is equiped and read to showcase your proeperty's full potentional by providing proven professional staging options, exquisite photography and videos - and so much more",
  //         "quad2Title": "We have experience",
  //         "quad2Text": "our commitment is to provide you with an unparalleled selling experience, delivering a customized plan of action to achieve your goals - from start to close",
  //         "quad3Title": "We're passionate",
  //         "quad3Text": "We are passionate about serving the Golden Rectangle enighborhood and know all the unique qualities of living here that buyers are looking for.",
  //         "quad4Title": "We deliver results",
  //         "quad4Text": "Our deep market knowledge and savvy negotiating skills will help you make informed decisions to attract the right buyers and achieve the highes sale price, in the shortest length of time."
  //     },
  //
  // ]

  handleFormClose = () => {
    this.props.updateQuadrantModalOpen(false);

    this.setState({
      titleEdit: false,
      addAreasModalOpen: false,
      savingMessageShowing: false,
    });
  };
  onEditFormSubmit = () => {
    const { editQuadrantFormData, oldQuadrantData } = this.state;

    let foundIndex = oldQuadrantData.findIndex(
      (x) => x.quadAreaID == editQuadrantFormData.quadAreaID
    );

    oldQuadrantData[foundIndex] = editQuadrantFormData;

    this.props.setQuadrantDetails(oldQuadrantData);
    this.setState({
      savingMessageShowing: true,
    });

    setInterval(() => {
      this.setState({ savingMessageShowing: false });
    }, 5000);

    // console.log("oldQuadrantData", oldQuadrantData);
  };
  openSelectedQuadrant = (quadrantNumber, titleEdit) => {
    this.props.updateQuadrantModalOpen(true);
    this.setState({
      selectedQuadrantNumber: quadrantNumber,
      titleEdit: titleEdit,
      savingMessageShowing: false,
    });
  };
  componentDidMount = () => {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }

    const { quadrantDetails, currentArea } = this.props;

    this.props.updateQuadrantModalOpen(false);

    let newObj = [...quadrantDetails]
      .filter((quadrantObj) => quadrantObj.quadAreaID === currentArea.mlsAreaID)
      .map((i) => ({ ...i }))[0];

    if (!newObj && currentArea.mlsAreaID.includes("DEMO")) {
      newObj = quadrantDetails[0];
    }
    let includesDemo = currentArea.mlsAreaID.includes("DEMO");
    // console.log("includesDemo", includesDemo);
    // console.log("newObj", newObj);
    let thisObj = [...quadrantDetails]
      .filter((quadrantObj) => quadrantObj.quadAreaID === currentArea.mlsAreaID)
      .map((i) => ({ ...i }))[0];
    // console.log("thisObj", thisObj);
    this.setState({
      oldQuadrantData: [...quadrantDetails].map((i) => ({ ...i })),
      editQuadrantFormData: [...quadrantDetails]
        .filter(
          (quadrantObj) => quadrantObj.quadAreaID === currentArea.mlsAreaID
        )
        .map((i) => ({ ...i }))[0],
    });
    // if (!this.state.editQuadrantFormData) {
    //   console.log("no edit");
    //   this.setState({
    //     editQuadrantFormData: quadrantDetails[0],
    //   });
    // }
    //  console.log("after mounting", this.state);
    // this.setState({
    //   oldQuadrantData: quadrantDetails,
    //   editQuadrantFormData: quadrantDetails,
    // });
  };
  handleRestoreDefault = () => {
    const {
      selectedQuadrantNumber,
      editQuadrantFormData,
      oldQuadrantData,
      titleEdit,
    } = this.state;
    let past = oldQuadrantData[selectedQuadrantNumber];
    if (titleEdit) {
      this.setState({
        // mainTitle: oldTitle,
        // subtitle: oldSubtitle,
      });
    } else if (selectedQuadrantNumber >= 0) {
      let newObj = [...editQuadrantFormData].map((i) => ({ ...i }));
      // console.log("regular obj", newObj);
      let oldSelection = [...oldQuadrantData].map((i) => ({
        ...i,
      }))[selectedQuadrantNumber];
      // console.log("oldSelection", oldSelection);
      newObj[selectedQuadrantNumber].text = oldSelection.text;
      // console.log("after obj", newObj);
      this.setState({
        editQuadrantFormData: newObj,
      });
    }
  };
  handleInputChange = (e) => {
    const { editQuadrantFormData, oldQuadrantData, titleEdit } = this.state;
    const target = e.target;
    const value = target.value;
    const name = target.name;
    // console.log("target", target);
    // console.log("value", value);
    // console.log("name", name);
    // console.log("editQuadrantFormData", editQuadrantFormData);

    let clonedEditQuadrantFormData = Object.assign(editQuadrantFormData, {
      [name]: value,
    });

    this.setState({
      editQuadrantFormData: clonedEditQuadrantFormData,
    });
  };
  applyToAllAreas = () => {
    console.log("applyToAllAreas");
  };

  render() {
    // console.log(this.props.currentArea);
    const { mlsAreaID } = this.props.currentArea;
    const {
      mlsAreas,
      quadrantModalOpen,
      currentArea,
      printVersion,
      hoData,
    } = this.props;
    //console.log("quadrantModalOpen", quadrantModalOpen);

    const {
      openSelectedQuadrant,
      handleInputChange,
      handleRestoreDefault,
      applyToAllAreas,
    } = this;
    const {
      editQuadrantFormData,
      oldQuadrantData,
      selectedQuadrantNumber,

      titleEdit,

      addAreasModalOpen,
      maxMainTitleLength,
      maxMainSubtitleLength,
      savingMessageShowing,
    } = this.state;
    let subtitleStyles;
    if (
      mlsAreaID === "SCL-207A" ||
      mlsAreaID === "SCL-207B" ||
      mlsAreaID === "SCL-209A" ||
      mlsAreaID == "SCL-209B"
    ) {
      subtitleStyles = "no-capitalized-subtitle";
    }
    let quadrantStyles;
    if (mlsAreaID === "SMA-466A" || mlsAreaID === "SMA-492") {
      quadrantStyles = "smaller-lower-quadrants";
    } else if (mlsAreaID === "SMA-472") {
      quadrantStyles = "larger-font-quadrants";
    } else if (mlsAreaID === "SMA-437") {
      quadrantStyles = "less-title-more-text";
    }
    // else if (
    //   editQuadrantFormData &&
    //   editQuadrantFormData.quad4Text &&
    //   editQuadrantFormData.quad4Text.length > 250
    // ) {
    //   console.log("quad4Text");
    // }
    // console.log("quadrant state", this.state);

    let showButtons = true;
    // let showButtons = currentArea.mailingStatus !== "Approved";

    if (editQuadrantFormData && Object.keys(editQuadrantFormData).length >= 1) {
      const {
        quadAreaID,
        quadAreaTitle,
        quadAreaSubTitle,
        quad1Title,
        quad1Text,
        quad2Title,
        quad2Text,
        quad3Title,
        quad3Text,
        quad4Title,
        quad4Text,
      } = editQuadrantFormData;
      return (
        <Fragment>
          {addAreasModalOpen && (
            <AddAreasModal
              addAreasModalOpen={addAreasModalOpen}
              mlsAreas={mlsAreas}
              currentArea={currentArea}
              onCloseFn={this.handleFormClose}
              onEditFormSubmit={this.onEditFormSubmit}
              editQuadrantFormData={editQuadrantFormData}
            />
          )}
          {editQuadrantFormData &&
            Object.keys(editQuadrantFormData).length >= 1 &&
            quadrantModalOpen && (
              <QuadrantEditorModal
                oldQuadrantData={oldQuadrantData}
                onCloseFn={this.handleFormClose}
                handleInputChange={handleInputChange}
                editQuadrantFormData={editQuadrantFormData}
                onEditFormSubmit={this.onEditFormSubmit}
                selectedQuadrantNumber={selectedQuadrantNumber}
                handleRestoreDefault={handleRestoreDefault}
                titleEdit={titleEdit}
                mlsAreas={mlsAreas}
                maxMainTitleLength={maxMainTitleLength}
                maxMainSubtitleLength={maxMainSubtitleLength}
                savingMessageShowing={savingMessageShowing}
              />
            )}

          <div
            className={`${
              printVersion
                ? "print-april-2023-quadrant-container"
                : "editor-april-2023-quadrant-container"
            } ${quadrantStyles && quadrantStyles} `}
          >
            <div className="quadrant">
              <div className="quadrant-title-container">
                <div
                  className="quadrant-title-text"
                  dangerouslySetInnerHTML={{ __html: quadAreaTitle }}
                ></div>
                <div
                  className={`quadrant-subtitle-text ${subtitleStyles}`}
                  dangerouslySetInnerHTML={{ __html: quadAreaSubTitle }}
                ></div>
              </div>

              <div className="quadrant-body">
                {!printVersion && showButtons && (
                  <>
                    <div
                      className="quadrant-btn quadrant-title-btn"
                      onClick={() => openSelectedQuadrant(null, true)}
                    >
                      <div>Edit this section</div>
                    </div>

                    <div
                      className="quadrant-btn quadrant-btn-1"
                      onClick={() => openSelectedQuadrant(1)}
                    >
                      <div>Edit this section</div>
                    </div>

                    <div
                      className="quadrant-btn quadrant-btn-2"
                      onClick={() => openSelectedQuadrant(2)}
                    >
                      <div>Edit this section</div>
                    </div>

                    <div
                      className="quadrant-btn quadrant-btn-3"
                      onClick={() => openSelectedQuadrant(3)}
                    >
                      <div>Edit this section</div>
                    </div>

                    <div
                      className="quadrant-btn quadrant-btn-4"
                      onClick={() => openSelectedQuadrant(4)}
                    >
                      <div>Edit this section</div>
                    </div>
                  </>
                )}

                <div className="section-container">
                  <div className="section-row">
                    <div
                      className={`${
                        1 == selectedQuadrantNumber &&
                        "selected-quadrant-section"
                      } quadrant-section section-1`}
                      // onClick={() => openSelectedQuadrant(1)}
                      key={1}
                    >
                      <div className="quadrant-section-title">
                        <p dangerouslySetInnerHTML={{ __html: quad1Title }}></p>
                        <div className="quadrant-section-title-divider" />
                      </div>

                      <div
                        className="quadrant-section-text"
                        dangerouslySetInnerHTML={{ __html: quad1Text }}
                      ></div>
                    </div>
                    <div
                      className={`${
                        2 == selectedQuadrantNumber &&
                        "selected-quadrant-section"
                      } quadrant-section section-2`}
                      // onClick={() => openSelectedQuadrant(2)}
                      key={2}
                    >
                      <div className="quadrant-section-title">
                        <p dangerouslySetInnerHTML={{ __html: quad2Title }}></p>
                        <div className="quadrant-section-title-divider" />
                      </div>

                      <div
                        className="quadrant-section-text"
                        dangerouslySetInnerHTML={{ __html: quad2Text }}
                      ></div>
                    </div>
                    <div
                      className={`${
                        3 == selectedQuadrantNumber &&
                        "selected-quadrant-section"
                      } quadrant-section section-3`}
                      // onClick={() => openSelectedQuadrant(3)}
                      key={3}
                    >
                      <div className="quadrant-section-title">
                        <p dangerouslySetInnerHTML={{ __html: quad3Title }}></p>
                        <div className="quadrant-section-title-divider" />
                      </div>

                      <div
                        className="quadrant-section-text"
                        dangerouslySetInnerHTML={{ __html: quad3Text }}
                      ></div>
                    </div>
                    <div
                      className={`${
                        4 == selectedQuadrantNumber &&
                        "selected-quadrant-section"
                      } quadrant-section section-4`}
                      // onClick={() => openSelectedQuadrant(4)}
                      key={4}
                    >
                      <div className="quadrant-section-title">
                        <p dangerouslySetInnerHTML={{ __html: quad4Title }}></p>
                        <div className="quadrant-section-title-divider" />
                      </div>

                      <div
                        className="quadrant-section-text"
                        dangerouslySetInnerHTML={{ __html: quad4Text }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="quadrant-logo-container">
              <img src={SOSLogo} alt="SOS Logo" />
            </div>*/}
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <div>Loading Quadrants...</div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    quadrantModalOpen: state.pma.quadrantModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateQuadrantModalOpen: (isModalOpen) => {
      dispatch(pmaActions.updateQuadrantModalOpen(isModalOpen));
    },
    setQuadrantDetails: (quadrantDetails) => {
      dispatch(pmaActions.setQuadrantDetails(quadrantDetails));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QVFourQuadrantContainer);
