import React, { Component } from "react";
import { connect } from "react-redux";
import { pmaActions } from "_store/_actions";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";
import VerificationRow from "./VerificationRow";
import MarketTableBG from "./verification-table-bg.png";
import UpdatedMarketTableBG from "./updated-verification-table-bg.png";
import "./styles.scss";

class VerificationTable extends Component {
  sortByStatus = (propList) => {
    //console.log("propList", propList);
    let sliced = propList
      .slice(0, 19)
      .sort((a, b) => new Date(a.SaleDate) - new Date(b.SaleDate));
    //console.log("sliced", sliced);
    // let sold = sliced.filter(x => x.status === "Sold");
    // let pend = sliced.filter(x => x.status === "Pending");
    // let cont = sliced.filter(x => x.status === "Contingent");
    // let active = sliced.filter(x => x.status === "Active");
    // let trueSort = [...sold, ...pend, ...cont, ...active];
    // return trueSort;
    return sliced;
    // return propList.slice(0, 19);
  };
  getTodayDate = () => {
    return moment(new Date()).format("MMM D, YYYY");
  };
  formatLastPrintDate = (date) => {
    return moment(date).format("MMM D, YYYY");
  };
  render() {
    const {
      currentArea,
      dataVerification,
      showTitle,
      titleText,
      dateRange,
      pastStyles,
      propDetails,
      coverPageAverages,
      agentData,
    } = this.props;

    // console.log("sales table props", dataVerification);
    const { lastMidQuarterlyPrintDate } = dataVerification;
    const {
      AvgDaysOnMarket,
      AvgPerOfReceiving,
      AvgPricePerSqFt,
      AvgSalePrice,
      SoldCount,
    } = coverPageAverages;
    const {
      getPropView,
      formatSuffix,
      formatBaths,
      toAcre,
      cutName,
      propTypeAcronym,
      splitAreas,
      countAreaChars,
      slashSeparatedArea,
    } = propertyUtil;
    const { mlsAreaID, mlsNeighborhood } = currentArea;
    console.log(this.props.dataVerification);

    //
    // const totalActives = this.props.dataVerification.monthsSupplyTable
    //   .AreaNumberOfHousesForSale;
    // const totalSolds = marketHighLightsData.current90DaysUnitsSold
    //   ? marketHighLightsData.current90DaysUnitsSold
    //   : 0;
    // let last90DaysDOM = marketHighLightsData.current90DaysAvgDOM
    //   ? marketHighLightsData.current90DaysAvgDOM
    //   : 0;
    // let last90DaysSalePrice = marketHighLightsData.current90DaysAvgSalePrice;
    // let last90DaysPercRec =
    //   marketHighLightsData.current90DaysPercentReceivedOfAsking;
    // let last90DaysPricePerSqFt = marketHighLightsData.current90DaysPricePerSqFt;
    let sorted = dataVerification && this.sortByStatus(propDetails);
    // console.log("sorted", sorted);
    // console.log(
    //   "propDetails",
    //   propDetails
    // );

    const soldNum =
      (propDetails &&
        propDetails.filter((list) => list.status === "Sold").length) ||
      0;

    const activesNum =
      propDetails &&
      propDetails.filter((list) => list.status === "Active").length;

    const contingentsNum =
      propDetails &&
      propDetails.filter((list) => list.status === "Contingent").length;
    //  console.log("contingentsNum", contingentsNum);

    const currentSolds = (
      <VerificationRow
        solds={sorted.slice(0, 19)}
        mlsAreaID={mlsAreaID}
        agentData={agentData}
      />
    );

    let hasOffMarket = sorted.some(
      (prop) =>
        prop.PotentialOffMarketSaleWithMultiPhotos === "1" ||
        prop.PotentialOffMarketSaleWithSinglePhoto === "1"
    );

    const tableHeader = (
      <thead className="solds-thead">
        <tr>
          <th></th>
          <th></th>

          <th>Status</th>

          <th>
            <p>Sale </p>
            <p>Date</p>
          </th>

          <th>
            <p>Property</p>
            <p>Type</p>
          </th>

          <th>Address</th>
          <th>
            <p>List Price</p>
          </th>
          <th>Sale Price</th>
          <th>DOM</th>
          <th>
            <p>% Rec'd</p>
            <p>of Ask</p>
          </th>

          <th>
            <p>Price per</p>
            <p>Square Foot</p>
          </th>
          <th></th>
        </tr>
      </thead>
    );

    return (
      <div className="data-verification-sales-table">
        <div className="subtitle-row">
          <div className="left-subtitle-section">
            <div className="left-subtitle-text">
              <p className="left-subtitle-text-title">
                {pastStyles ? "" : "Recent"} Homes Sold
              </p>
              <div className="left-subtitle-divider"></div>
              <p className="left-subtitle-text-title">{titleText}</p>
            </div>
          </div>

          <div className="right-subtitle-section">
            <p className="small-left-subtitle">
              {dateRange}
              {/*{this.formatLastPrintDate(lastMidQuarterlyPrintDate)} —{" "}
              {this.getTodayDate()}*/}
            </p>
          </div>
        </div>

        <div className="market-table-containers">
          {/*<img
            src={UpdatedMarketTableBG}
            alt="Market Table Cloud"
            className="market-table-bg-image"
          />*/}
          <div className="both-market-tables">
            <table className="regular-sales-table">
              {tableHeader}
              {currentSolds}
            </table>

            {hasOffMarket && (
              <div className="off-market-disclaimer">
                *This property was sold off-market. It was not listed on the
                MLS, but the County recorded it as a sale.
              </div>
            )}

            <table
              className={`market-table-footer ${pastStyles && "past-footer"}`}
            >
              {/*<div>
              <p>Sold: {SoldCount}</p>
            </div>*/}

              {/*<thead className="solds-thead">
                <tr>
                  <th></th>
                  <th> </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th> </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th> </th>
                  <th></th>
                </tr>
              </thead>*/}

              <tbody className="solds-tbody">
                <tr className="recent-row">
                  <td></td>
                  <td>
                    <div>
                      <p>Average:</p>
                      <div>
                        <p>$</p>
                        <p>{Number(AvgSalePrice).toLocaleString()}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p>Average:</p>
                    <p>{AvgDaysOnMarket} Days</p>
                  </td>
                  <td>
                    <div>
                      <p>Average:</p>
                      <p>{Math.round(Number(AvgPerOfReceiving))}%</p>
                    </div>
                  </td>
                  <td>
                    <p>Average:</p>
                    <div>
                      <p>$</p>
                      <p>{Number(AvgPricePerSqFt).toLocaleString()}</p>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mlsAreas: state.pma.mlsAreas,
    localStorageData: state.localStorageData,
    version: state.pma.version,
    mailingVersion: state.pma.mailingVersion,
    currentStep: state.pma.currentStep,
    propertyData: state.pma.propertyData,

    isLoading: state.pma.isLoading,
    dataVerification: state.pma.dataVerification,
    quarterlyCityTable: state.pma.quarterlyCityTable,
    localSellingAgents: state.pma.localSellingAgents,

    typeOfVersion: state.pma.typeOfVersion,
    typeOfMQVersion: state.pma.typeOfMQVersion,
    typeOfQVersion: state.pma.typeOfQVersion,

    viewingGeneratedCL: state.pma.viewingGeneratedCL,
    coverPageDetails: state.pma.coverPageDetails,
    testimonials: state.pma.testimonials,
    agentDesignations: state.pma.agentDesignations,
    agentAdvantages: state.pma.agentAdvantages,
    salePriceForCities: state.pma.salePriceForCities,
    yearEnd: state.pma.yearEnd,
    qArea: state.pma.qArea,
    qCity: state.pma.qCity,
    aprilData: state.pma.aprilData,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getMailerProDetails: (
      areaId,
      cityAndDate,
      agentData,
      forEditor,
      version,
      typeOfMQVersion,
      typeOfQVersion,
      listgenUserID
    ) => {
      dispatch(
        pmaActions.getMailerProDetails(
          areaId,
          cityAndDate,
          agentData,
          forEditor,
          version,
          typeOfMQVersion,
          typeOfQVersion,
          listgenUserID
        )
      );
    },
    switchArea: (area) => dispatch(pmaActions.switchArea(area)),
    switchStep: (step) => dispatch(pmaActions.switchStep(step)),
    updateLocalStorageDataForRedux: (localStorageData) =>
      dispatch(pmaActions.updateLocalStorageDataForRedux(localStorageData)),
    switchVersion: (version) => {
      dispatch(pmaActions.switchVersion(version));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(VerificationTable);

// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { pmaActions } from "_store/_actions";
// import { propertyUtil } from "views/Pma/PropertyUtil";
// import moment from "moment";
// import VerificationRow from "./VerificationRow";
// import MarketTableBG from "./verification-table-bg.png";
// import UpdatedMarketTableBG from "./updated-verification-table-bg.png";
// import "./styles.scss";
//
// class VerificationTable extends Component {
//   sortByStatus = (propList) => {
//     //console.log("propList", propList);
//     let sliced = propList
//       .slice(0, 19)
//       .sort((a, b) => new Date(a.SaleDate) - new Date(b.SaleDate));
//     //console.log("sliced", sliced);
//     // let sold = sliced.filter(x => x.status === "Sold");
//     // let pend = sliced.filter(x => x.status === "Pending");
//     // let cont = sliced.filter(x => x.status === "Contingent");
//     // let active = sliced.filter(x => x.status === "Active");
//     // let trueSort = [...sold, ...pend, ...cont, ...active];
//     // return trueSort;
//     return sliced;
//     // return propList.slice(0, 19);
//   };
//   getTodayDate = () => {
//     return moment(new Date()).format("MMM D, YYYY");
//   };
//   formatLastPrintDate = (date) => {
//     return moment(date).format("MMM D, YYYY");
//   };
//   render() {
//     const {
//       currentArea,
//       dataVerification,
//       showTitle,
//       titleText,
//       dateRange,
//       pastStyles,
//       propDetails,
//       coverPageAverages,
//     } = this.props;
//
//     console.log("sales table props", dataVerification);
//     const { lastMidQuarterlyPrintDate } = dataVerification;
//     const {
//       AvgDaysOnMarket,
//       AvgPerOfReceiving,
//       AvgPricePerSqFt,
//       AvgSalePrice,
//       SoldCount,
//     } = coverPageAverages;
//     const {
//       getPropView,
//       formatSuffix,
//       formatBaths,
//       toAcre,
//       cutName,
//       propTypeAcronym,
//       splitAreas,
//       countAreaChars,
//       slashSeparatedArea,
//     } = propertyUtil;
//     const { mlsAreaID, mlsNeighborhood } = currentArea;
//     //console.log(this.props.dataVerification);
//
//     //
//     // const totalActives = this.props.dataVerification.monthsSupplyTable
//     //   .AreaNumberOfHousesForSale;
//     // const totalSolds = marketHighLightsData.current90DaysUnitsSold
//     //   ? marketHighLightsData.current90DaysUnitsSold
//     //   : 0;
//     // let last90DaysDOM = marketHighLightsData.current90DaysAvgDOM
//     //   ? marketHighLightsData.current90DaysAvgDOM
//     //   : 0;
//     // let last90DaysSalePrice = marketHighLightsData.current90DaysAvgSalePrice;
//     // let last90DaysPercRec =
//     //   marketHighLightsData.current90DaysPercentReceivedOfAsking;
//     // let last90DaysPricePerSqFt = marketHighLightsData.current90DaysPricePerSqFt;
//     let sorted = dataVerification && this.sortByStatus(propDetails);
//     // console.log("sorted", sorted);
//     // console.log(
//     //   "propDetails",
//     //   propDetails
//     // );
//
//     const soldNum =
//       (propDetails &&
//         propDetails.filter((list) => list.status === "Sold").length) ||
//       0;
//
//     const activesNum =
//       propDetails &&
//       propDetails.filter((list) => list.status === "Active").length;
//
//     const contingentsNum =
//       propDetails &&
//       propDetails.filter((list) => list.status === "Contingent").length;
//     //  console.log("contingentsNum", contingentsNum);
//
//     const currentSolds = (
//       <VerificationRow solds={sorted.slice(0, 19)} mlsAreaID={mlsAreaID} />
//     );
//
//     let hasOffMarket = sorted.some(
//       (prop) =>
//         prop.PotentialOffMarketSaleWithMultiPhotos === "1" ||
//         prop.PotentialOffMarketSaleWithSinglePhoto === "1"
//     );
//
//     const tableHeader = (
//       <thead className="solds-thead">
//         <tr>
//           <th></th>
//
//           <th>Status</th>
//
//           <th>
//             <p>Sale </p>
//             <p>Date</p>
//           </th>
//
//           <th>
//             <p>Property</p>
//             <p>Type</p>
//           </th>
//
//           <th>Address</th>
//           <th>
//             <p>List Price</p>
//           </th>
//           <th>Sale Price</th>
//           <th>DOM</th>
//           <th>
//             <p>% Rec'd</p>
//             <p>of Ask</p>
//           </th>
//
//           <th>
//             <p>Price per</p>
//             <p>Square Foot</p>
//           </th>
//           <th></th>
//         </tr>
//       </thead>
//     );
//
//     return (
//       <div className="data-verification-sales-table">
//         {hasTitle && (
//           <div className="market-sales-title-container">
//             <div className="market-sales-title-row">
//               <div className="market-sales-subtitle-text">
//                 Data Verification
//               </div>
//               <div className="market-sales-review-text">
//                 Please review the following data points to ensure your PMA's
//                 accuracy
//               </div>
//             </div>
//             // {getTitle(mlsNeighborhood)}
//           </div>
//         )}
//
//         <div className="subtitle-row">
//           <div className="left-subtitle-section">
//             <div className="left-subtitle-text">
//               <p className="left-subtitle-text-title">
//                 {current && "Recent"} Homes Sold
//               </p>
//               <div className="left-subtitle-divider"></div>
//               <p className="left-subtitle-text-title">
//                 {current ? "Last" : "Previous"} 90 Days
//               </p>
//             </div>
//           </div>
//
//           <div className="right-subtitle-section">
//             <p className="small-left-subtitle">
//               {dateRange}
//               {/*{this.formatLastPrintDate(lastMidQuarterlyPrintDate)} —{" "}
//               {this.getTodayDate()}*/}
//             </p>
//           </div>
//         </div>
//
//         <div className="market-table-containers">
//           {/*<img
//             src={UpdatedMarketTableBG}
//             alt="Market Table Cloud"
//             className="market-table-bg-image"
//           />*/}
//           <div className="both-market-tables">
//             <table className="regular-sales-table">
//               {tableHeader}
//               {currentSolds}
//             </table>
//
//             {hasOffMarket && (
//               <div className="off-market-disclaimer">
//                 *This property was sold off-market. It was not listed on the
//                 MLS, but the County recorded it as a sale.
//               </div>
//             )}
//
//             <table
//               className={`market-table-footer ${pastStyles && "past-footer"}`}
//             >
//               {/*<div>
//               <p>Sold: {SoldCount}</p>
//             </div>*/}
//
//               {/*<thead className="solds-thead">
//                 <tr>
//                   <th></th>
//
//                   <th> </th>
//
//                   <th></th>
//
//                   <th></th>
//
//                   <th></th>
//
//                   <th> </th>
//
//                   <th></th>
//
//                   <th></th>
//                   <th></th>
//
//                   <th> </th>
//
//                   <th></th>
//                 </tr>
//               </thead>*/}
//
//               <tbody className="solds-tbody">
//                 <tr className="recent-row">
//                   <td></td>
//                   <td>
//                     <div>
//                       <p>Average:</p>
//                       <div>
//                         <p>$</p>
//                         <p>{Number(AvgSalePrice).toLocaleString()}</p>
//                       </div>
//                     </div>
//                   </td>
//                   <td>
//                     <p>Average:</p>
//                     <p>{AvgDaysOnMarket} Days</p>
//                   </td>
//                   <td>
//                     <div>
//                       <p>Average:</p>
//                       <p>{Math.round(Number(AvgPerOfReceiving))}%</p>
//                     </div>
//                   </td>
//                   <td>
//                     <p>Average:</p>
//                     <div>
//                       <p>$</p>
//                       <p>{Number(AvgPricePerSqFt).toLocaleString()}</p>
//                     </div>
//                   </td>
//                   <td></td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
//
// const mapStateToProps = (state) => {
//   return {
//     mlsAreas: state.pma.mlsAreas,
//     localStorageData: state.localStorageData,
//     version: state.pma.version,
//     mailingVersion: state.pma.mailingVersion,
//     currentStep: state.pma.currentStep,
//     propertyData: state.pma.propertyData,
//
//     isLoading: state.pma.isLoading,
//     dataVerification: state.pma.dataVerification,
//     quarterlyCityTable: state.pma.quarterlyCityTable,
//     localSellingAgents: state.pma.localSellingAgents,
//
//     typeOfVersion: state.pma.typeOfVersion,
//     typeOfMQVersion: state.pma.typeOfMQVersion,
//     typeOfQVersion: state.pma.typeOfQVersion,
//
//     viewingGeneratedCL: state.pma.viewingGeneratedCL,
//     coverPageDetails: state.pma.coverPageDetails,
//     testimonials: state.pma.testimonials,
//     agentDesignations: state.pma.agentDesignations,
//     agentAdvantages: state.pma.agentAdvantages,
//     salePriceForCities: state.pma.salePriceForCities,
//     yearEnd: state.pma.yearEnd,
//     qArea: state.pma.qArea,
//     qCity: state.pma.qCity,
//     aprilData: state.pma.aprilData,
//   };
// };
// function mapDispatchToProps(dispatch) {
//   return {
//     getMailerProDetails: (
//       areaId,
//       cityAndDate,
//       agentData,
//       forEditor,
//       version,
//       typeOfMQVersion,
//       typeOfQVersion,
//       listgenUserID
//     ) => {
//       dispatch(
//         pmaActions.getMailerProDetails(
//           areaId,
//           cityAndDate,
//           agentData,
//           forEditor,
//           version,
//           typeOfMQVersion,
//           typeOfQVersion,
//           listgenUserID
//         )
//       );
//     },
//     switchArea: (area) => dispatch(pmaActions.switchArea(area)),
//     switchStep: (step) => dispatch(pmaActions.switchStep(step)),
//     updateLocalStorageDataForRedux: (localStorageData) =>
//       dispatch(pmaActions.updateLocalStorageDataForRedux(localStorageData)),
//     switchVersion: (version) => {
//       dispatch(pmaActions.switchVersion(version));
//     },
//   };
// }
// export default connect(mapStateToProps, mapDispatchToProps)(VerificationTable);
