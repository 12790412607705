export const psSuggestions = [
    "Enjoying all the detailed market information we’re providing for your neighborhood? Call us – we’d be happy to provide you with a no cost valuation for your property too!",
    "With just a quick call, we can give you a free, no-obligation market valuation of your property. %AREA_NAME% neighborhood is our passion and specialty!",
    "Enjoying all the detailed market information we’re providing for your neighborhood? Allow us to provide you with a free, no-obligation home valuation based on today's market prices.",
    "Considering cashing in on your incredible investment while the market is still strong? Detailed market knowledge is our speciality. Call us – we’re always ready to share our market expertise.",
    "If you’re considering putting your house on the market soon, please feel free to reach out to us for a detailed property valuation. We’re just a phone call away!",
    "In many neighborhoods, the market is still showing signs of strength for sellers. If you’re considering selling before the market shifts more towards favoring buyers, call us…We’re here to help!",
    "Even though the market has shifted, there are still lots of all-cash buyers out there ready and eager to buy. Call us for a current property valuation to capture the highest possible price.",
    "Capturing the highest possible price for your property can be challenging in this shifting market.  Call us today — we have the expertise and experience to help you get the highest possible price.",
    "After a flurry of medorotic price increases and low inventory, the market has settled down. Thinking of selling? Call us, there are plenty of buyers looking for a wonderful home at a fair price."
];


export const notePaperMaxWidth = (width = 1200) => width;

export const NotesCameFrom = {
    PersonalPS: "PersonalPS",
    AbsentHOPS: "AbsentHOPS",
    Anniversary: "Anniversary",
    Congrats: "Congrats",
    Cancelled: "Cancelled",
    Expired: "Expired",
    Coincidence: "Coincidence",
}

export const MaxAllowedLinesInNote = 7;

export const perLineComfortableNoteCharCount = 90;


export const noteSuggestions = [
    {
        min:1,
        max:0,
        note: ''
    },
    {
        min:10,
        max:20,
        note: ''
    },
    {
        min:21,
        max:'-',
        note: ''
    }
];