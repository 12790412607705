import React, { Component } from "react";
import FirstLeftHeader from "views/Pma/PmaEditor/CommonEditor/FirstLeftHeader";
import HOAddress from "views/Pma/PmaEditor/CommonEditor/HOAddress";
import QVFourLogoAndAvatar from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourLogoAndAvatar";
import QVThreeTopCategories from "./QVThreeTopCategories";
import QVThreeDonutBar from "./QVThreeDonutBar";
import QVThreeAvgPriceBarChart from "./QVThreeAvgPriceBarChart";
import QVThreeEconomists from "views/Pma/PmaEditor/Quarterly/QVThree/QVThreeFirstPage/QVThreeEconomists";
import QVThreeTestimonial from "./QVThreeTestimonial";
import QV3BackPageFlower from "./qv3-backpage-flower1-1.jpg";
import BackpageBoxes from "./BackpageBoxes";
// import YETwoPropertyHighlights from "views/Pma/PmaEditor/YearEnd/YETwo/YETwoFirstPage/YETwoPropertyHighlights";

class QVThreeFirstLeft extends Component {
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      quarterlyAreaTable,
      quarterlyCityTable,
      navbarData,
      coverPageDetails,
      yearEnd,
      salePriceForCities,
      aprilData,
      demoAccountHOName,
      demoAccountAreaName,
      testimonials,
      handlePropertyHighlightDialog,
      bubbleBoxes,
    } = this.props;
    console.log("first left", this.props);
    // const { currentSoldListings } = aprilData.pmaAprilRunAreaDTO;
    return (
      <div className="qv3-first-left">
        <div className="qv3-corner-highlight"></div>
        <FirstLeftHeader
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />
        <QVFourLogoAndAvatar
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
        {hoData &&
          hoData.changeDetails &&
          Object.keys(hoData.changeDetails).length > 1 && (
            <HOAddress
              hoData={hoData}
              version={version}
              agentData={agentData}
              currentArea={currentArea}
              printVersion={printVersion}
              mailInfoClass="qv3-mail-info-container-print"
              versionType="qv3"
              typeOfVersion="qv3"
              demoAccountHOName={demoAccountHOName}
              coverPageDetails={coverPageDetails}
            />
          )}

        {/*<div className="qv3-backpage-bg">
          <img src={QV3BackPageFlower} alt="Flower" />
        </div>*/}
        <BackpageBoxes
          currentArea={currentArea}
          agentData={agentData}
          bubbleBoxes={bubbleBoxes}
          mlsAreas={currentArea.mlsAreas}
          printVersion={printVersion}
        />
        {/*<QVThreeDonutBar />*/}
        {/*<QVThreeEconomists />
        {testimonials && Object.keys(testimonials).length > 1 && (
          <QVThreeTestimonial
            testimonials={testimonials}
            agentData={agentData}
          />
        )}*/}
      </div>
    );
  }
}

export default QVThreeFirstLeft;
