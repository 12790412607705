import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";

class DataVerificationFooter extends Component {
  render() {
    const { mlsCityName, mlsCityID } = this.props.currentArea;
    const { getCounty } = propertyUtil;
    let county = getCounty(mlsCityName);

    let isSB = false;
    if (mlsCityID === "5060" || mlsCityID === "5063" || mlsCityID === "5056") {
      isSB = true;
    }

    return (
      <div className="data-verification-footer">
        {isSB ? (
          <p>
            All data is sourced from FlexMLS. Off-market sales are sourced from
            Santa Barbara County records.
          </p>
        ) : (
          <p>
            All data is sourced from MLSListings Inc. Off-market sales are
            sourced from {county} County records.
          </p>
        )}
        <p>© ListGen Inc 2022</p>
      </div>
    );
  }
}

export default DataVerificationFooter;
