import React, { Component } from "react";
import { connect } from "react-redux";
import { pmaActions } from "../../../_store/_actions";
import { GetStateStorage, IsValidNavbarData } from "common/storage";
import { WebsiteLayout } from "layouts";
import { CircularProgress, Typography, Button, Grid } from "@material-ui/core";
// import PMAEditorInformationDialog from "./components/PMAEditorInformationDialog";
import MailProInit from "./MailProInit";
import { isEmpty } from "lodash";
import AddGroup from "./components/Groups/AddGroup";
import AddHomeownerToGroup from "./components/Groups/AddHomeownerToGroup";
import ListOfHomeownersInGroup from "./components/Groups/ListOfHomeownersInGroup";
import RemoveHomeownerFromGroup from "./components/Groups/RemoveHomeownerFromGroup";
import ManageAGroup from "./components/Groups/ManageAGroup";

class MailProMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbarData: {},
      resetLocalState: false,
      openDrawer: false,
      enableMailProApprovalProcess: false, //no need to touch this as it will have no effect. use component Did Mount. It iwll controlled by backend now
      openPMAIssuesDialog: false,
      enablePMAInformationDialog: false,
      // enablePMAInformationDialog: true,// true means system will display the PMA info dialog. When we push Year End PMA then make it false
      openPMAInformationDialog: false,
    };
    /**bind methods */
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
  }

  componentWillMount = () => {
    this.props.switchStep(1);
    /** check for default step */
    try {
      this.props.switchStep(1);
    } catch (err) {}
  };

  componentDidMount() {
    const stateData = GetStateStorage();
    const agentData = stateData.userData;
    this.props.getNextPMAPrintDate(agentData);
    //check if state data not founf
    const { mlsAreas, switchArea, history } = this.props;
    /**Get session storage */
    let paramAreaID = this.props.match.params.areaID;
    let areaIDList = agentData.navbarInfo.map((area) => area.mlsAreaID);
    let idMatches = areaIDList.indexOf(paramAreaID) >= 0; //if session switch, if the agent does not have the navbar area id that matches the url, then we need to take the first navbar area for the agent because that means that they are being switched from one agent to another on admin side

    let navbarInfo = {};

    if (agentData && Array.isArray(agentData.navbarInfo)) {
      if (!idMatches) {
        navbarInfo = agentData.navbarInfo[0];
        history.push(`/mailpro/${agentData.navbarInfo[0].mlsAreaID}`);
      } else {
        navbarInfo = agentData.navbarInfo.filter(
          (area) => area.mlsAreaID === paramAreaID
        )[0];
      }
    } else {
      navbarInfo = {};
    }

    switchArea(navbarInfo);
    const isValid = IsValidNavbarData();
    !this.props.mlsAreas.length &&
      this.props.getNavbarInfo(stateData.userData.listgenUserID, navbarInfo);
    /**get next pma print date */

    let showApproval = []; // ["101003", "101006", "100474"];
    if (showApproval.includes(agentData.listgenUserID)) {
      this.setState({
        enableMailProApprovalProcess: true, //it's also linked with mailpro approval button is also enable
      });
    } else {
      this.setState({
        enableMailProApprovalProcess: agentData.enableApprovalButton || false, //check the agent data for this process
      });
    }
    if (isValid) {
    } else {
      console.log("No MLS areas found", mlsAreas);
    }

    /**now scroll to top also */
    window.scrollTo(0, 0);
    //show the pma information dialog
    if (this.state.enablePMAInformationDialog) {
      this.setState({
        openPMAInformationDialog: true,
      });
    }
  }

  handleDrawerOpen = () => {
    this.setState({
      openDrawer: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      openDrawer: false,
    });
  };

  render() {
    const { mlsAreas, currentArea, currentStep, propertyData } = this.props;
    const stateData = GetStateStorage() || {};
    const agentData = stateData.userData;

    let paramAreaID = this.props.match.params.areaID;
    const { mlsCityName } = currentArea;
    let isUnavailable = false;
    // if (
    //   mlsCityName === "WOODSIDE" ||
    //   mlsCityName === "ATHERTON" ||
    //   mlsCityName === "MENLO PARK" ||
    //   mlsCityName === "PORTOLA VALLEY"
    // ) {
    //   isUnavailable = true;
    // }

    return (
      <WebsiteLayout
        sidebarMode={true}
        openSidebarModeSidebar={this.state.openDrawer}
        sidebarModeHandleClose={this.handleDrawerClose}
        hideFooter={true}
      >
        {mlsAreas && mlsAreas.length >= 1 && !isEmpty(currentArea) ? (
          <MailProInit
            agentData={agentData}
            mlsAreas={mlsAreas}
            navbarData={currentArea}
            handleDrawerOpen={this.handleDrawerOpen}
            enableMailProApprovalProcess={
              this.state.enableMailProApprovalProcess
            }
            paramAreaID={paramAreaID}
            propertyData={propertyData}
            isUnavailable={isUnavailable}
          />
        ) : (
          <div
            style={{
              height: "80vh",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              background: "inherit",
            }}
          >
            <div>
              <CircularProgress />
            </div>
            <div>
              <Typography variant="subtitle2">
                &nbsp;&nbsp;Loading...
              </Typography>
            </div>
          </div>
        )}
      </WebsiteLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentArea: state.pma.currentArea,
    mlsAreas: state.pma.mlsAreas,
    localStorageData: state.localStorageData,
    version: state.pma.version,
    currentStep: state.pma.currentStep,
    propertyData: state.pma.propertyData,
    nextPMAPrintDate: state.pma.nextPMAPrintDate,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getNavbarInfo: (listgenUserID) =>
      dispatch(pmaActions.getNavbarInfo(listgenUserID)),
    switchArea: (area, isLoading) =>
      dispatch(pmaActions.switchArea(area, isLoading)),
    switchStep: (step) => dispatch(pmaActions.switchStep(step)),
    getNextPMAPrintDate: (agentData) => dispatch(pmaActions.getNextPMAPrintDate(agentData)),
    updateLocalStorageDataForRedux: (localStorageData) =>
      dispatch(pmaActions.updateLocalStorageDataForRedux(localStorageData)),
    switchVersion: (version) => {
      dispatch(pmaActions.switchVersion(version));
    },
    changeLoading: (status) => {
      dispatch(pmaActions.changeLoading(status));
    },
    explicitEnableNewMailProInitFn: (data) =>
      dispatch(pmaActions.explicitEnableNewMailProInitFn(data)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MailProMain);
