import React, { forwardRef, Fragment, lazy, Suspense } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
import MailingTableHeader from "./MailingTableHeader";
import PropertyRecord from "./PropertyRecord";
import AgentRecord from "./AgentRecord";
import "./index.scss";
import { pmaActions } from "../../../../_store/_actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MediaViewComponent } from "components";
import { propertyUtil } from "../../PropertyUtil";
import { Paper, Fab, Typography, Button, Grow } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ScrollToTopComponent } from "components";
import { tableSortUtil } from "../../TableSortUtil";
import { renderLogicUtil } from "../../RenderLogicUtil";
import { MailProConstants } from "./MailProConstants";
import { NotesCameFrom } from "../components/Notes/Common/NotesConfig";
const NewMailingModuleInit = lazy(() =>
  import("./NewMailProInitDialog"));
const NewMailingComponentTable = lazy(() =>
  import("./NewMailingComponentTable")
);
const AnniversaryAutomatorDialog = lazy(() => import('../components/Notes/AnniversaryAutomator/AnniversaryAutomatorDialog'));


const MailProLoader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        background: "#f8fafc"
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div>
          <CircularProgress />
        </div>
        <div>
          <Typography variant="subtitle2">
            &nbsp;&nbsp;Loading MailPro...
          </Typography>
        </div>
      </div>
    </div>
  );
};

class PmaMailingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMediaView: false,
      mediaURL: null,
      triggerNow: false,
      propertyRecords: [],
      dataToDisplay: [],
      pageNumber: 1,
      agentRecords: [],
      allDataDisplyed: false,
      noRecordMatched: false
      //isLoading: true,
      //enablePagination: false
    };
    this.pageLimit = 100;
    /**bind methods */
    this.openMediaViewDialog = this.openMediaViewDialog.bind(this);
    this.closeMediaViewDialog = this.closeMediaViewDialog.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.updateTable();
    }, 1000);
    //register scroll evemt
    this.addEventScroller();
    //change the step. In PMA by default it will zero so no update required. Here we need to update after the mailpro navbar load
    //We already calling in mailpro index file but we need to call here also
    setTimeout(() => { this.props.switchStep(1) }, 50);
  }




  componentDidUpdate(prevProps) {
    if (
      this.props.pma.currentArea.mlsAreaID !==
      prevProps.pma.currentArea.mlsAreaID
    ) {
      //on chnage of area first reset current data. on load of new area data mailpro id will change then table rendering will happen
      this.scrollToTop(0, 0);
      this.resetMailProLocalState();
    } else if (
      this.props.pma.currentAreaFirstMailProID !==
      prevProps.pma.currentAreaFirstMailProID
    ) {
      setTimeout(() => {
        this.scrollToTop(0, 0);
        this.updateTable(true);
      }, 1000);
    } else if (
      this.props.pma.hoSearch !== prevProps.pma.hoSearch ||
      this.props.pma.hoFilter !== prevProps.pma.hoFilter ||
      this.props.pma.hoQuickFilter !== prevProps.pma.hoQuickFilter ||
      this.props.pma.hoAdvanceFilter !== prevProps.pma.hoAdvanceFilter ||
      this.props.pma.advanceFilterOptionData !== prevProps.pma.advanceFilterOptionData
    ) {
      setTimeout(() => {
        this.scrollToTop(0, 0);
        //we have to reset thepagination because in this case the data to diaply count will change
        this.updateTable(true);
      }, 1000);
    } else if (
      (this.props.pma.mailProHeader !== prevProps.pma.mailProHeader &&
        this.props.pma.mailProHeader !== "")
         ||
      (this.props.pma.mailProSortDirection !==
        prevProps.pma.mailProSortDirection &&
        this.props.pma.mailProHeader !== "")
    ) {
      //do not render die to header sort if header sort is empty
      setTimeout(() => {
        this.updateTable();
      }, 1000);
    } else if(this.props.recentlyUpdatedMailProID){
      //we need this when we call update homeowner prop API to update one home owner record details. for e.g. name,address,personal note etc
      this.updateOneDisplayedRecords(this.props.recentlyUpdatedMailProID);
      this.props.updateRecentUpdatedMailProId(null);
    }
  }
  
  resetMailProLocalState = () => {
    this.setState({
      propertyRecords: [],
      dataToDisplay: []
    });
  }

  addEventScroller() {
    window.addEventListener("scroll", this.handleScrolling);
  }

  removeEventScroller() {
    window.removeEventListener("scroll", this.handleScrolling);
  }

  updateOneDisplayedRecords = (mailProID) => {
    if(!mailProID)
    return;
    const {propertyData} = this.props;
    let updatedHoRecord = propertyData.find(property => property.mailerProID === mailProID);
    this.setState(prevState => {
      let updatedDataToDisplay = [...prevState.dataToDisplay];
      let index = updatedDataToDisplay.findIndex(property => property.mailerProID === mailProID);
      if(index > -1){
        updatedDataToDisplay[index] = updatedHoRecord;
      }
      return {
        dataToDisplay: updatedDataToDisplay
      }
    });
  }

  updateTable = (resetPagination = false) => {
    //check for sorting. If it's homeValuation then perform this only
    let { propertyData, agentData } = this.props;

    const {
      currentArea,
      hoSearch,
      hoFilter,
      hoQuickFilter,
      hoAdvanceFilter,
      mailProHeader,
      mailProSortDirection,
      isLoading,
      advanceFilterOptionData
    } = this.props.pma;
    let agentXData = [];
    let propertyXData = [];
    /**method start from here */
    propertyData.map((property, key) => {
      //filter agent id
      //let isCorrect = true;
      const {
        OwnerFirst,
        OwnerLast,
        SiteStreet,
        Price,
        MailAddress,
        MailCity,
        MailState,
        SiteCity,
        SiteState,
        SiteNumber
      } = property.mailingDetails;
      const { listPrice } = property.propertyDetails
        ? property.propertyDetails
        : {};
      //apply search by edited names
      let xSiteMailingAddressString =
        SiteNumber +
        " " +
        SiteStreet +
        " " +
        SiteCity +
        " " +
        MailAddress +
        " " +
        MailCity;

      //Step 1: check agent id
      if (parseInt(property.agentID) !== 0 || parseInt(property.agentID) > 0) {
        //isCorrect = false;
        //its an agent
        agentXData.push(property);
        return "";
      }
      if (hoFilter) {
        if (hoFilter === "blocked" && !property.HOQuickFilterData.isBlocked) {
          return "";
        }
        if (hoFilter === "delete" && !property.HOQuickFilterData.isDeleted) {
          return "";
        }
        if (hoFilter === "active" && !property.HOQuickFilterData.isActive) {
          return "";
        }
        if (hoFilter === "homeValuation" && !property.isRequestedForValuation) {
          return "";
        }
        if (hoFilter === "duplicateMailingAddress" && !property.HOQuickFilterData.duplicateMailingAddress) {
          return "";
        }
      }

      if (hoQuickFilter) {
        if (hoQuickFilter === "congrats" && !property.HOQuickFilterData.isCongrats) {
          return "";
        }
        if (hoQuickFilter === "anniversary" && !property.HOQuickFilterData.isAnniversary) {
          return "";
        }
        if (hoQuickFilter === "expired" && !property.HOQuickFilterData.isExpired) {
          return "";
        }
        if (hoQuickFilter === "absentee" && !property.HOQuickFilterData.isAbsentee) {
          return "";
        }
        if (hoQuickFilter === "cancelled" && !property.HOQuickFilterData.isCancelled) {
          return "";
        }
      }

      if (
        hoSearch &&
        !this.doFilter(xSiteMailingAddressString) &&
        !this.doFilterByNameV3(property, hoSearch) &&
        !this.abovePrice(Price) &&
        !this.abovePrice(listPrice)
      ) {
        return "";
      }

      //apply for advance filters
      if (hoAdvanceFilter &&
        !renderLogicUtil.advanceHOFilter(property, agentData, hoAdvanceFilter, advanceFilterOptionData)) {
        return "";
      }
      propertyXData.push(property);
    });
    //get the property count
    //check if ho quick filter is set then sort by last sale in desc order
    if (hoQuickFilter) {
      const lastPMAPrintDate = propertyUtil.getLastPrintDate();
      propertyXData = tableSortUtil.lastSaleSorting(
        propertyXData,
        lastPMAPrintDate,
        this.props.nextPMAPrintDate,
        mailProSortDirection === "desc" ? false : true
      );

    }
    //In pagination reset start with page number 1
    this.setState(
      prevState => {
        let dataToDisplay =
          prevState.allDataDisplyed && !resetPagination
            ? propertyXData
            : [
              ...propertyXData.slice(
                0,
                this.pageLimit * (resetPagination ? 1 : prevState.pageNumber)
              )
            ]; //if all data displayed then no need to slice. this will cover status check from unblocked/blocked to all and where number if blocked properties is greater then page limit
        return {
          propertyRecords: [...propertyXData],
          dataToDisplay: dataToDisplay,
          pageNumber: resetPagination ? 1 : prevState.pageNumber,
          agentRecords: agentXData,
          allDataDisplyed: resetPagination ? false : prevState.allDataDisplyed, //iys different from all property diaplyed data
          noRecordMatched: propertyData.length > 0 && propertyXData.length === 0 //data is there but no record matched to given filter/search text
        };
      },
      () => {
        //scroll to top now
        setTimeout(() => {
          //Need to reset the recent updated mailpro id
          this.props.updateRecentUpdatedMailProId(null)
          if (isLoading) {
            this.props.changeLoading(false);
          }
          //check for reset pagination also
          if (resetPagination) {
            //area changed add event scroller again by removing first if exist.
            //this case come when we do any operation after the all property loading
            this.removeEventScroller();
            this.addEventScroller();
          }
        }, 1000);
      }
    );
  };

  doFilterByNameV3 = (property, hoSearch) => {
    const { lgOwnerFirstName, lgOwnerLastName, lgSecondaryOwnerFirstName, lgSecondaryOwnerLastName } = property.changeDetails ? property.changeDetails : {};
    //fetch original ho names
    let { OwnerName, SecondaryOwnerName, OwnerLast, OwnerFirst, SecondaryOwnerFirst, SecondaryOwnerLast, SuggestedOwnerName,
      SuggestedSecondaryOwnerName } = property.mailingDetails ? property.mailingDetails : {};
    const isValid = value =>
      (value ? value.toLowerCase().includes(hoSearch.toLowerCase()) : false);
    return (
      isValid(OwnerName) ||
      isValid(SecondaryOwnerName) ||
      isValid(OwnerFirst) ||
      isValid(OwnerLast) ||
      isValid(`${OwnerFirst} ${OwnerLast}`) ||
      isValid(`${SecondaryOwnerFirst} ${SecondaryOwnerLast}`) ||
      isValid(SecondaryOwnerFirst) ||
      isValid(SecondaryOwnerLast) ||
      isValid(lgOwnerFirstName) ||
      isValid(lgOwnerLastName) ||
      isValid(`${lgOwnerFirstName} ${lgOwnerLastName}`) ||
      isValid(`${lgSecondaryOwnerFirstName} ${lgSecondaryOwnerLastName}`) ||
      isValid(lgSecondaryOwnerFirstName) ||
      isValid(lgSecondaryOwnerLastName) ||
      isValid(SuggestedOwnerName) ||
      isValid(SuggestedSecondaryOwnerName)
    );
  };

  doFilter = property => {
    const { hoSearch } = this.props.pma;
    return property.toLowerCase().includes(hoSearch.toLowerCase());
  };

  doNumFilter = property => {
    const { hoSearch } = this.props.pma;
    return property.includes(hoSearch);
  };

  abovePrice = property => {
    const { hoSearch } = this.props.pma;
    /**check if text is number only. otherwise sedn false */
    if (property && parseFloat(hoSearch) > 0) {
      return (
        parseFloat(property) == parseFloat(hoSearch.replace(/[^0-9-.]/g, ""))
      );
      //return parseFloat(property) >= parseFloat(hoSearch.replace(/[^0-9-.]/g, ''));
    } else {
      /**no number */
      return false;
    }
  };

  listingFilter = property => {
    const { hoSearch } = this.props.pma;
    const formatListing = prop => {
      return `${prop.slice(0, 2).toLowerCase()}${prop.slice(2)}`;
    };
    let loweredProp = formatListing(property);
    let loweredSearch = formatListing(hoSearch);
    return loweredProp.includes(loweredSearch);
  };

  parcelFilter = property => {
    const { hoSearch } = this.props.pma;

    const propReplace = prop => {
      let regex = /-/gi;
      return prop.replace(regex, "");
    };
    return propReplace(property).includes(propReplace(hoSearch));
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  openMediaViewDialog = mediaURL => {
    this.setState(
      {
        mediaURL
      },
      this.setState({
        openMediaView: true
      })
    );
  };

  closeMediaViewDialog = () => {
    this.setState({
      mediaURL: null,
      mediaTitle: null,
      openMediaView: false
    });
  };

  handleScrolling = event => {
    const {
      scrollTop,
      scrollHeight,
      clientHeight
    } = event.target.scrollingElement; // document.getElementById('auto-scroll-mailing-list-table-body');
    if (scrollTop + clientHeight >= scrollHeight - 20) {
      //load next set after a certain time
      //setTimeout(() => {
      this.setState(
        prevState => {
          let newData = [
            ...prevState.dataToDisplay,
            ...prevState.propertyRecords.slice(
              prevState.pageNumber * this.pageLimit,
              (prevState.pageNumber + 1) * this.pageLimit
            )
          ];
          return {
            //propertyRecords: [],
            dataToDisplay: newData,
            pageNumber: prevState.pageNumber + 1,
            allDataDisplyed: newData.length === prevState.propertyRecords.length
          };
        },
        () => {
          const { allDataDisplyed } = this.state;
          //all property displayed deregister the scroll event
          if (allDataDisplyed) {
            window.removeEventListener("scroll", this.handleScrolling);
          }
        }
      );
      //}, 500);
    }
  };

  render() {
    const {
      propertyData,
      mlsAreas,
      isLoading,
      agentData,
      nextPMAPrintDate,
      isMailProLocked
    } = this.props;
    const lastPMAPrintDate = propertyUtil.getLastPrintDate();
    const { currentArea } = this.props.pma;

    const {
      propertyRecords,
      agentRecords,
      pageNumber,
      dataToDisplay,
      noRecordMatched
    } = this.state;

    //check all data displayed or not
    let allPropertyDisplyed = propertyRecords.length === dataToDisplay.length;
    // /if we will launch at startup then in case of new mailing we are updating newMailing or isNewProperty Key.. but in case of anniversary need to create a function htat will check is edited in current mialing or not.
    // /new mailing or new property -> then anniversary dialog we need to show
    return !this.props.explicitEnableNewMailProInit ? (
      <Fragment>
        <NewMailingModuleInit
          currentArea={currentArea}
          propertyData={propertyData}
          isMailProLocked={isMailProLocked}
          agentData={agentData} />
        <div className="pma-mailing-list pma-main">
          <div className="container">
            <div className="mailing-table">
              {this.state.openMediaView ? (
                <MediaViewComponent
                  mediaURL={this.state.mediaURL}
                  mediaTitle={"Property Photo"}
                  onOpen={this.openMediaViewDialog}
                  onClose={this.closeMediaViewDialog}
                />
              ) : (
                ""
              )}
              <table>
                <MailingTableHeader
                  propertyData={propertyData}
                  nextPMAPrintDate={nextPMAPrintDate}
                  lastPMAPrintDate={lastPMAPrintDate}
                  agentData={agentData}
                />
                <tbody
                  className="mailing-list-scroll-table-body"
                  id="auto-scroll-mailing-list-table-body"
                >
                  {noRecordMatched ? (
                    <tr style={{ backgroundColor: "#ecfbfb" }}>
                      <td colSpan={11}>
                        <div>
                          <Typography
                            variant="subtitle2"
                            style={{ padding: "64px" }}
                          >
                            No Matched record found. Please change your query.
                          </Typography>
                        </div>
                      </td>
                    </tr>
                  ) : dataToDisplay.length > 0 && !isLoading ? (
                    <Fragment>
                      <tr id="back-to-top-anchor-mailing-table">
                        <td colSpan={11}></td>
                      </tr>
                      {dataToDisplay.map((property, key) => {
                        return (
                          <PropertyRecord
                            key={property.mailerProID || key}
                            property={property}
                            propertyData={propertyData}
                            mailerProID={property.mailerProID}
                            propertyDetails={property.propertyDetails}
                            mailingDetails={property.mailingDetails}
                            changeDetails={property.changeDetails}
                            areaID={property.areaID}
                            agentID={property.agentID}
                            mailingStatus={property.mailingStatus}
                            isPropertyMapped={property.isPropertyMapped}
                            agentData={agentData}
                            isMailProLocked={isMailProLocked}
                            openMediaViewDialog={this.openMediaViewDialog}
                            nextPMAPrintDate={nextPMAPrintDate}
                            lastPMAPrintDate={lastPMAPrintDate}
                            currentArea={currentArea}
                            propertySequenceNumber={key + 1}
                            hoaSubdivisionName={property.subdivisionName}
                          />
                        );
                      })}
                      {allPropertyDisplyed &&
                        agentRecords.map((agent, key) => (
                          <AgentRecord
                            key={agent.mailerProID}
                            propertyData={propertyData}
                            mailerProID={agent.mailerProID}
                            mailingDetails={agent.mailingDetails}
                            areaID={agent.areaID}
                            agentID={agent.agentID}
                            mailingStatus={agent.mailingStatus}
                            isPropertyMapped={agent.isPropertyMapped}
                          />
                        ))}
                      <tr>
                        <td colSpan={11}>
                          <div className="lastRowGreeting">
                            {!allPropertyDisplyed
                              ? `Please Wait... Loading ${this.pageLimit * pageNumber >
                                propertyRecords.length
                                ? propertyRecords.length
                                : this.pageLimit * pageNumber
                              } - ${this.pageLimit * (pageNumber + 1) >
                                propertyRecords.length
                                ? propertyRecords.length
                                : this.pageLimit * (pageNumber + 1)
                              } of total ${propertyRecords.length
                              } Properties...`
                              : "That's All !!!"}
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  ) : (
                    <MailProLoader />
                  )}
                </tbody>
              </table>
              {dataToDisplay.length > 0 && !isLoading && (
                <Fragment>
                  <ScrollToTopComponent {...this.props} key={'scrolltoleft_left'} normalMode={true} alignment={'left'}>
                    <Fab
                      color="primary"
                      size="medium"
                      aria-label="Scroll back to top"
                      title="Scroll back to top"
                    >
                      <KeyboardArrowUpIcon />
                    </Fab>
                  </ScrollToTopComponent>
                  <ScrollToTopComponent {...this.props} key={'scrolltoleft_right'} normalMode={true}>
                    <Fab
                      color="primary"
                      size="medium"
                      aria-label="Scroll back to top"
                      title="Scroll back to top"
                    >
                      <KeyboardArrowUpIcon />
                    </Fab>
                  </ScrollToTopComponent>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    ) : (
      <Suspense
        fallback={
          <Typography variant="h6" align="center" style={{ marginTop: '32px' }}>
            Loading... Please wait...
          </Typography>
        }
      >
        {propertyData.length === 0 || isLoading ? (
          <MailProLoader />
        ) : (
          this.props.newMailProInitDialogMode === MailProConstants.newAnniversary ?
            <AnniversaryAutomatorDialog
              agentData={agentData}
              defaultOpen={true}
              mlsAreas={mlsAreas}
              currentArea={currentArea}
              cameFrom={NotesCameFrom.Anniversary}
              key={"anniversary_automator_from_mailing_list"}
              isMailProLocked={isMailProLocked}
            />
            : ([MailProConstants.isNewProperty, MailProConstants.newMailing].includes(this.props.newMailProInitDialogMode)) ?
              <NewMailingComponentTable
                propertyData={propertyData}
                isMailProLocked={isMailProLocked}
                mlsAreas={mlsAreas}
                currentArea={currentArea}
                forceUpdate={() => {
                  this.props.newMailingForceUpdate();
                  this.scrollToTop(0, 0);
                }}
                agentData={agentData}
              />
              : null
        )}
      </Suspense>
    );
  }
}

const PmaMailingListWithRef = forwardRef((props, ref) => (
  <PmaMailingList {...props} ref={ref} />
));

const mapDispatchToProps = dispatch => {
  return {
    updateNavbar: mlsAreas => dispatch(pmaActions.updateNavbar(mlsAreas)),
    mailProHeaderSort: (sorted, mailProHeader) =>
      dispatch(pmaActions.mailProHeaderSort(sorted, mailProHeader)),
    changeLoading: status => {
      dispatch(pmaActions.changeLoading(status));
    },
    resetMailproCurrentHeaderSort: () => {
      dispatch(pmaActions.resetMailproCurrentHeaderSort());
    },
    switchStep: (step) => dispatch(pmaActions.switchStep(step)),
    updateRecentUpdatedMailProId: (mailerProID) => {
      dispatch(pmaActions.updateRecentUpdatedMailProId(mailerProID));
    }
  };
};

function mapStateToProps(state) {
  return {
    properties: state.pma.properties,
    pma: state.pma,
    propertyData: state.pma.propertyData,
    currentArea: state.pma.currentArea,
    currentStep: state.pma.currentStep,
    mlsAreas: state.pma.mlsAreas,
    isLoading: state.pma.isLoading,
    nextPMAPrintDate: state.pma.nextPMAPrintDate,
    explicitEnableNewMailProInit: state.pma.explicitEnableNewMailProInit,
    newMailProInitDialogMode: state.pma.newMailProInitDialogMode,
    recentlyUpdatedMailProID: state.pma.recentlyUpdatedMailProID,
  };
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PmaMailingListWithRef);

// const connectedMailingTable = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(PmaMailingList);
// export default connectedMailingTable;
