import { CommonAxiosAPIHandler } from "common/helpers";
import { GetOAuthTokenByKey } from "common/storage";
import qs from 'qs';

const GET_ANNIVERSARY_NOTES = "/agent/getSystemAnniversaryNotes";
const SKIP_ANNIVERSARY_NOTES = "/agent/skipAnniversaryNotesForMailProIDs";
const SEND_NOTE_TO_ALL_HOMEOWNERS = "/agent/setAnniversaryNotesForAllHomeOwners";

/**
 * @name getAnniversaryNoteForHomeowner
 * @param {*} data 
 * @param {*} data: { areaID, areaName, siteAddress, yearPurchased, isAbsenteeHO, anniversaryCount }
 * @returns 
 */
export const getAnniversaryNoteForHomeowner = (data) => {
    return CommonAxiosAPIHandler("GET", [
        `${GET_ANNIVERSARY_NOTES}?${qs.stringify(data)}`
    ]);
};

/**
 * @name skipAnniversaryNotesForMailProIDs
 * @param {*} data
 * @param {*} data: { mailProIDs }
 * @returns
 * @description Skip anniversary notes for the given mailProIDs
 * @returns
 */
export const skipAnniversaryNotesForMailProIDs = (mailProIDs = []) => {
    return CommonAxiosAPIHandler("POST", [
        SKIP_ANNIVERSARY_NOTES,
        {
            mailProIDs: mailProIDs
        }]);
}

/**
 * @name sendNoteToAllHomeowners
 * @param {*} data
 * @param {*} data: { textPS, textPSDate, personalPS, personalPSDate, typePS, mailerProIDs }
 * @returns
 * @description Send note to all homeowners
 */
export const sendNoteToAllHomeowners = (data) => {
    return CommonAxiosAPIHandler("POST", [
        SEND_NOTE_TO_ALL_HOMEOWNERS,
        data
    ]);
}