import React, { Component } from "react";
import QVFourProducedByFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPresentedByFooter";
import PS from "views/Pma/PmaEditor/CommonEditor/PS";
import QVFourPhotoSection from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotoSection";
import QVFourAgentLogoHeader from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourAgentLogoHeader";
import QVFourPS from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPS";
import GNQRCode from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/GNQRCode";

class BubbleCompareSecondRight extends Component {
  render() {
    const {
      agentData,
      hoData,
      qArea,
      currentArea,
      printVersion,
      version,
      mlsAreas,
      MQCityDetails,
      quarterlyAreaTable,
      quarterlyCityTable,
      isDemo,
    } = this.props;
    const { listgenUserID } = agentData;
    const { currentSoldListings } = quarterlyAreaTable;
    let isBuellton = currentArea.mlsAreaID === "SBA-40F";

    return (
      <div className="bubble-compare-second-right">
        <QVFourProducedByFooter
          agentData={agentData}
          currentArea={currentArea}
          currentSoldListings={currentSoldListings}
          printVersion={printVersion}
        />
        <QVFourAgentLogoHeader
          agentData={agentData}
          currentArea={currentArea}
        />
        {hoData &&
          hoData.changeDetails &&
          Object.keys(hoData.changeDetails).length > 1 && (
            <QVFourPS
              agentData={agentData}
              hoData={hoData}
              currentArea={currentArea}
              mlsAreas={mlsAreas}
              version={version}
              printVersion={printVersion}
              mailingNum="qv4"
              isDemo={isDemo}
            />
          )}
        {version === "bubble-compare" && listgenUserID !== "100462" && (
          <GNQRCode hoData={hoData} agentData={agentData} />
        )}
        <QVFourPhotoSection
          currentArea={currentArea}
          agentData={agentData}
          printVersion={printVersion}
          version={version}
          hoData={hoData}
          quarterlyCityTable={quarterlyCityTable}
          currentSoldListings={
            isBuellton
              ? quarterlyCityTable.currentSoldListings
              : quarterlyAreaTable.currentSoldListings
          }
        />
      </div>
    );
  }
}

export default BubbleCompareSecondRight;
