import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { Link } from "react-router-dom";
import testLogo from "./octavio-marquez-june-2024-logo.png";
import Constants from "common/constants";
import NateRandallAgentBlock from "_img/agentLogos/082924_Nate-TopProducer_1.jpg";

class QVFourLogoAndAvatar extends Component {
  componentDidMount = () => {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }
  };
  formatAgentDesignation = (designation) => {
    const { listgenUserID } = this.props.agentData;
    let isWendyMarioni = listgenUserID === "102935";

    if (isWendyMarioni) {
      return "Realtor® | CIPS™ | ABR™ | Relocation Specialist";
    } else if (
      designation === "Realtor" ||
      designation === "Broker Associate | Realtor"
    ) {
      return `${designation}®`;
    } else {
      return `${designation}`;
    }
  };
  formatTagline = (tagline, containerClasses, textClasses) => {
    const { listgenUserID } = this.props.agentData;
    const { mlsAreaID } = this.props.currentArea;
    let split = tagline.split(Constants.LG_DEFAULT_TEXT_SEPARATOR);
    let isSophieTsang = listgenUserID === "102567";
    let isErikaDemma = listgenUserID === "106107";
    let largerSignatureMargin = listgenUserID === "104412";
    let extraClasses = "";

    if (
      tagline.includes(Constants.LG_DEFAULT_TEXT_SEPARATOR) &&
      ((split[0] && split[0].length > 51 && split[0] && split[0].length < 65) ||
        (split[1] &&
          split[1].length > 51 &&
          split[1] &&
          split[1] &&
          split[1].length < 65))
    ) {
      extraClasses = "small-tagline-text-12";
    } else if (
      tagline.includes(Constants.LG_DEFAULT_TEXT_SEPARATOR) &&
      ((split[0] && split[0].length > 51) || (split[1] && split[1].length > 51))
    ) {
      extraClasses = "small-tagline-text-10";
    }
    let secondTaglineClass;
    if (isErikaDemma) {
      return (
        <div className={`address-city-mt larger-address-city-365`}>
          <div
            className={`address-city ${textClasses} ${extraClasses}`}
            dangerouslySetInnerHTML={{
              __html: split[0],
            }}
          ></div>
          {split[1] && (
            <div
              className={`address-city ${textClasses} ${extraClasses} ${secondTaglineClass}`}
              dangerouslySetInnerHTML={{
                __html: split[1],
              }}
            ></div>
          )}
          <div
            className={`address-city ${textClasses} ${extraClasses} ${secondTaglineClass}`}
          >
            Follow me on Instagram @luxrealestateservices
          </div>
        </div>
      );
    } else if (isSophieTsang) {
      return (
        <div className={`address-city-mt larger-address-city-365`}>
          <div
            className={`address-city ${textClasses} ${extraClasses}`}
            dangerouslySetInnerHTML={{
              __html: split[0],
            }}
          ></div>
          {split[1] && (
            <div
              className={`address-city ${textClasses} ${extraClasses} ${secondTaglineClass}`}
              dangerouslySetInnerHTML={{
                __html: split[1],
              }}
            ></div>
          )}
          <div
            className={`address-city ${textClasses} ${extraClasses} ${secondTaglineClass}`}
          >
            WSJ RealTrends America's Best 2024
          </div>
        </div>
      );
    } else if (mlsAreaID === "SMA-448") {
      return (
        <div className={`address-city-mt ${containerClasses}`}>
          <div className={`address-city ${textClasses} ${extraClasses}`}>
            #1 Compass Team in Hillsborough*
          </div>
          <div className={`address-city ${textClasses} ${extraClasses}`}>
            <span className="smaller-signature-text-12 italicized-tagline-text">
              *2023 MLS Volume{" "}
            </span>{" "}
          </div>
        </div>
      );
    } else if (mlsAreaID === "SMA-436" || mlsAreaID === "SMA-438") {
      return (
        <div className={`address-city-mt ${containerClasses}`}>
          <div className={`address-city ${textClasses} ${extraClasses}`}>
            #1 Team in San Mateo, Hillsborough
          </div>
          <div className={`address-city ${textClasses} ${extraClasses}`}>
            & Burlingame Combined*
          </div>
          <div className={`address-city ${textClasses} ${extraClasses}`}>
            <span className="smaller-signature-text-12 italicized-tagline-text">
              *2023 MLS Volume{" "}
            </span>{" "}
          </div>
        </div>
      );
    } else {
      return (
        <div className={`address-city-mt ${containerClasses}`}>
          <div
            className={`address-city ${textClasses} ${extraClasses}`}
            dangerouslySetInnerHTML={{
              __html: split[0],
            }}
          ></div>
          {split[1] && (
            <div
              className={`address-city ${textClasses} ${extraClasses} ${secondTaglineClass}`}
              dangerouslySetInnerHTML={{
                __html: split[1],
              }}
            ></div>
          )}
        </div>
      );
    }
  };
  render() {
    const { formatTagline, formatAgentDesignation } = this;
    const { printVersion, agentData, currentArea, isDemo } = this.props;
    let {
      logoURL,
      brokerageName,
      mobilePhoneNo,
      photoURL,
      isMemberOfTeam,
      listgenUserFirstName,
      listgenUserLastName,
      teamMembers,
      emailAddress,
      websiteURL,
      listgenUserID,
      teamName,
      displayEmailAddress,
      specialSignatureText,
      agentLicenseNumber,
    } = agentData;
    if (listgenUserID === "x104412") {
      logoURL = testLogo;
    }
    if (listgenUserID === "102843") {
      isMemberOfTeam = false;
      teamMembers = [];
    }
    const { mlsAreaID } = currentArea;
    if (mlsAreaID === "SCL-209A") {
      displayEmailAddress = "CuestaPark.com";
      emailAddress = "cuestapark.com";
    } else if (mlsAreaID === "SCL-207A") {
      displayEmailAddress = "OldMountainView.com";
      emailAddress = "OldMountainView.com";
    } else if (mlsAreaID === "SCL-207B") {
      displayEmailAddress = "DowntownMountainView.com";
      emailAddress = "DowntownMountainView.com";
    } else if (mlsAreaID === "SCL-209C") {
      displayEmailAddress = "SaintFrancisAcres.com";
      emailAddress = "SaintFrancisAcres.com";
    }
    let isMarkWong = listgenUserID === "102450";

    let { tagLine } = currentArea;

    if (tagLine && tagLine.length > 2) {
      specialSignatureText = tagLine;
    }

    let isWendyLeung = listgenUserID === "105794";
    let isColleenForaker = listgenUserID === "106020";
    let isLeeShantePappas = listgenUserID === "104480";
    let isShantiM = listgenUserID === "106999";
    let isLynneMercer = listgenUserID === "102512";
    let smallerSignature = false;
    if (isLeeShantePappas) {
      listgenUserFirstName = "Lee + Shanté";
    }
    if (
      specialSignatureText &&
      specialSignatureText.length > 78 &&
      !specialSignatureText.includes(Constants.LG_DEFAULT_TEXT_SEPARATOR)
    ) {
      smallerSignature = "smaller-signature-text";
    }
    if (isMarkWong) {
      smallerSignature = "negative-signature-margin";
    }

    let profileId = btoa(listgenUserID);
    let isPerryGroup = listgenUserID === "101649";
    let isKazemiTeam = listgenUserID === "102712";
    let isSophieTsang = listgenUserID === "102567";
    let isCindyAndrade = listgenUserID === "102378";
    let isKyle3Team = listgenUserID === "103406";
    let isKimmAndJennifer = listgenUserID === "106028";
    const {
      formatPhoneNoWithDashes,
      formatPhoneNoWithPeriods,
      filterBrokerage,
      formatDemoEmail,
    } = propertyUtil;
    let filteredBrokerage = "";
    const isScar = teamName === "Team Scarborough";
    const isSingleBerkshire =
      filteredBrokerage ===
      "Berkshire Hathaway HomeServices California Properties";
    const singleAgent2LineBerkshire = isSingleBerkshire && true;
    let isAnnetteVait = listgenUserID === "101001";
    if (isAnnetteVait) {
      logoURL =
        "https://pma-coverphotos.s3.amazonaws.com/non-covers/122623_AnnetteVait_LOGO-sans-ICON_1.png";
    }
    const isMaryAyers = listgenUserID === "100059";
    const isJenMurry = listgenUserID === "106031";
    const isDaphneChou = listgenUserID === "102413";
    let isJohnsons = listgenUserID === "100988";
    let backpageAgentInfoStyles;
    if (isJenMurry) {
      backpageAgentInfoStyles = "vano-maurry-agent-info";
    }

    let emailStyles;
    if (listgenUserID === "102288") {
      emailStyles = "email-reduced-margin-top";
    } else if (listgenUserID === "104504") {
      emailStyles = "team-email-no-margin";
    }

    let logoMargin = "";
    let logoHeight = "";
    if (
      listgenUserID === "100129" ||
      listgenUserID === "100374" ||
      listgenUserID === "102699" ||
      listgenUserID === "105790" ||
      listgenUserID === "105527" ||
      listgenUserID === "102627" ||
      listgenUserID === "104709" ||
      listgenUserID === "100746" ||
      listgenUserID === "107010"
    ) {
      logoMargin = "small-mt-bottom-margin";
      logoHeight = "taller-upper-col-agent-logo";
    } else if (listgenUserID === "100528" || listgenUserID === "107001") {
      logoHeight = "double-x-upper-col-agent-logo";
    } else if (
      listgenUserID === "100138" ||
      listgenUserID === "100411" ||
      listgenUserID === "105056" ||
      listgenUserID === "106421" ||
      listgenUserID === "104113" ||
      listgenUserID === "102808" ||
      listgenUserID === "104454"
    ) {
      logoHeight = "xxl-upper-col-agent-logo";
    } else if (
      listgenUserID === "107001" ||
      listgenUserID === "105100" ||
      listgenUserID === "105959"
    ) {
      logoHeight = "xxxxl-upper-col-agent-logo";
    } else if (listgenUserID === "102286") {
      logoHeight = "smaller-xl-upper-col-agent-logo";
    } else if (listgenUserID === "101003") {
      logoMargin = "small-mt-bottom-margin";
    } else if (
      listgenUserID === "100568" ||
      listgenUserID === "100220" ||
      listgenUserID === "100401" ||
      listgenUserID === "100134" ||
      listgenUserID === "100210" ||
      listgenUserID === "100329" ||
      listgenUserID === "100227" ||
      listgenUserID === "100656" ||
      listgenUserID === "100187" ||
      listgenUserID === "101006" ||
      listgenUserID === "100462" ||
      listgenUserID === "100026" ||
      listgenUserID === "100474" ||
      listgenUserID === "100040" ||
      listgenUserID === "104626" ||
      listgenUserID === "102743" ||
      listgenUserID === "102674" ||
      listgenUserID === "102551" ||
      listgenUserID === "105801" ||
      listgenUserID === "102512" ||
      listgenUserID === "105810" ||
      listgenUserID === "105047" ||
      listgenUserID === "102941"
    ) {
      logoMargin = "taller-logo-small-mt";
      logoHeight = "taller-upper-col-agent-logo";
    } else if (
      listgenUserID === "105794" ||
      listgenUserID === "104607" ||
      listgenUserID === "106655"
    ) {
      logoHeight = "xs-45-agent-logo";
    } else if (
      listgenUserID === "100988" ||
      listgenUserID === "100059" ||
      listgenUserID === "102481"
    ) {
      logoMargin = "taller-logo-small-mt";
      logoHeight = "smaller-width-agent-logo";
    } else if (isJenMurry) {
      logoHeight = "jen-murry-logo-height";
    } else if (
      listgenUserID === "100588" ||
      listgenUserID === "100999" ||
      listgenUserID === "101677" ||
      listgenUserID === "102340" ||
      listgenUserID === "106998" ||
      listgenUserID === "105068" ||
      listgenUserID === "106997" ||
      listgenUserID === "106999" ||
      listgenUserID === "102734" ||
      listgenUserID === "106145" ||
      listgenUserID === "106433"
    ) {
      logoHeight = "wider-upper-col-agent-logo";
      logoMargin = "taller-logo-small-mt";
    } else if (isAnnetteVait || listgenUserID === "105867") {
      logoHeight = "small-height-v1-agent-logo";
    } else if (
      listgenUserID === "100028" ||
      listgenUserID === "102288" ||
      listgenUserID === "102712" ||
      listgenUserID === "102843" ||
      listgenUserID === "105222" ||
      listgenUserID === "101313" ||
      listgenUserID === "105896" ||
      listgenUserID === "106028" ||
      listgenUserID === "105882" ||
      listgenUserID === "104849" ||
      listgenUserID === "106399" ||
      listgenUserID === "105799" ||
      listgenUserID === "105042" ||
      listgenUserID === "106463"
    ) {
      logoHeight = "wider-upper-col-agent-logo";
    } else if (isDaphneChou) {
      logoHeight = "margin-top-1-agent-logo";
    } else if (listgenUserID === "100152") {
      logoHeight = "taller-upper-col-agent-logo";
    } else if (
      listgenUserID === "103404" ||
      listgenUserID === "104412" ||
      listgenUserID === "105678" ||
      listgenUserID === "102660" ||
      listgenUserID === "101479" ||
      listgenUserID === "104819" ||
      listgenUserID === "106647" ||
      listgenUserID === "106578" ||
      listgenUserID === "102694" ||
      listgenUserID === "102809" ||
      listgenUserID === "102507" ||
      listgenUserID === "102995" ||
      listgenUserID === "106024" ||
      listgenUserID === "106543" ||
      listgenUserID === "106472" ||
      listgenUserID === "106141" ||
      listgenUserID === "106661" ||
      listgenUserID === "105189" ||
      listgenUserID === "105797" ||
      listgenUserID === "106144" ||
      listgenUserID === "107012"
    ) {
      logoHeight = "xl-height-agent-logo";
    } else if (
      listgenUserID === "103405" ||
      listgenUserID === "105789" ||
      listgenUserID === "104683" ||
      listgenUserID === "102935" ||
      listgenUserID === "105016" ||
      listgenUserID === "102802" ||
      listgenUserID === "102501" ||
      listgenUserID === "102548" ||
      listgenUserID === "105886" ||
      listgenUserID === "105103" ||
      listgenUserID === "107009" ||
      listgenUserID === "105805" ||
      isKyle3Team ||
      listgenUserID === "106591" ||
      listgenUserID === "101332"
    ) {
      logoHeight = "med-height-agent-logo";
    } else if (
      listgenUserID === "100348" ||
      listgenUserID === "100778" ||
      listgenUserID === "100314" ||
      listgenUserID === "100528" ||
      listgenUserID === "104597" ||
      listgenUserID === "104510" ||
      listgenUserID === "105787" ||
      listgenUserID === "102687" ||
      listgenUserID === "104593" ||
      listgenUserID === "105473" ||
      listgenUserID == "102975" ||
      listgenUserID === "104504" ||
      listgenUserID === "105785" ||
      listgenUserID === "104480" ||
      listgenUserID === "105830" ||
      listgenUserID === "104804"
    ) {
      logoMargin = "taller-logo-small-mt";
      logoHeight = "large-taller-upper-col-agent-logo";
    } else if (listgenUserID === "100585") {
      logoHeight = "logo-style-lg";
    } else if (listgenUserID === "106531") {
      logoHeight = "xl-height-agent-logo";
    }
    let agentPhotoStyle;
    let agentPhotoContainerStyle;
    if (
      listgenUserID === "100374" ||
      listgenUserID === "100902" ||
      listgenUserID === "103404" ||
      listgenUserID === "103455"
    ) {
      agentPhotoStyle = "large-agent-photo-height";
    } else if (listgenUserID === "104835") {
      agentPhotoStyle = "agent-photo-210-height-290-width";
    } else if (isJenMurry) {
      agentPhotoStyle = "jen-murry-agent-photo-height";
    } else if (
      listgenUserID === "100411" ||
      listgenUserID === "102340" ||
      listgenUserID === "100329" ||
      listgenUserID === "107012" ||
      listgenUserID === "106433" ||
      listgenUserID === "106399"
    ) {
      agentPhotoStyle = "mccaw-agent-photo-height";
    } else if (listgenUserID === "x102753") {
      agentPhotoStyle = "agent-photo-styles-1";
    } else if (listgenUserID === "104504") {
      agentPhotoStyle = "agent-photo-styles-reduced-height-1";
    } else if (isKyle3Team) {
      agentPhotoStyle = "agent-photo-less-margin-large-photo";
    } else if (
      listgenUserID === "105795" ||
      listgenUserID === "106397" ||
      listgenUserID === "106020" ||
      listgenUserID === "105642"
    ) {
      agentPhotoStyle = "agent-photo-height-250-width-300";
      agentPhotoContainerStyle = "agent-photo-container-height-250-width-300";
    }
    let useFullName = false;
    if (
      listgenUserID === "100401" ||
      listgenUserID === "105047" ||
      listgenUserID === "106463" ||
      isJenMurry
    ) {
      useFullName = true;
    }
    if (listgenUserID === "105047") {
      displayEmailAddress = emailAddress = "";
    }
    let smallFontBrokerage =
      filteredBrokerage.length > 40 && !singleAgent2LineBerkshire
        ? true
        : false;
    let demoEmailAddress = formatDemoEmail(brokerageName);
    let switchOrder = false;
    // let switchOrder = listgenUserID === "106028";
    // let switchOrder = listgenUserID === "106028";
    let editorOrPrint = printVersion ? "print-" : "editor-";

    if (isKimmAndJennifer) {
      return (
        <div
          className={`${editorOrPrint}qv4-logo-and-avatar-container ${logoMargin} kimm-jennifer-logo-avatar-container`}
        >
          {logoURL && (
            <div className={`left-upper-col-agent-logo  ${logoHeight}`}>
              <img src={logoURL} alt="Logo" />
            </div>
          )}

          <div className={`agent-photo-container ${agentPhotoContainerStyle}`}>
            <img
              src={photoURL}
              className={`agent-photo ${agentPhotoStyle}`}
              alt="Agent"
            />
          </div>

          <div
            className={`logo-and-avatar-team-contact ${backpageAgentInfoStyles}`}
          >
            <div className="contact-section-team">
              <div className="contact-me-team-container">
                <div className="contact-me-team-inner">
                  <div className="contact-me row">
                    <p>
                      {listgenUserFirstName} {listgenUserLastName}
                    </p>
                    <div className="contact-me-divider" />{" "}
                    <p>{formatPhoneNoWithPeriods(mobilePhoneNo)}</p>
                  </div>

                  <div className="contact-me row">
                    <p>
                      {teamMembers[1].firstName} {teamMembers[1].lastName}
                    </p>
                    <div className="contact-me-divider" />{" "}
                    <p>{formatPhoneNoWithPeriods(teamMembers[1].phone)}</p>
                  </div>
                </div>
              </div>
              <div className="stacked-address">
                {specialSignatureText &&
                  formatTagline(
                    specialSignatureText,
                    "address-city-mt",
                    `address-city special-mt special-berkshire ${smallerSignature}`
                  )}
                <div
                  className={`address-col team-email-address ${emailStyles}`}
                >
                  {displayEmailAddress ? displayEmailAddress : emailAddress}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (isLynneMercer) {
      if (mlsAreaID === "SCL-205A") {
        return (
          <div
            className={`${editorOrPrint}qv4-logo-and-avatar-container-0-margin`}
          >
            <div className="custom-agent-logo-avatar">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/non-covers/091824_CalebF-LynneM_AgentBlock_1.jpg"
                alt="Logo"
              />
            </div>
          </div>
        );
      } else {
        return (
          <div
            className={`${editorOrPrint}qv4-logo-and-avatar-container-0-margin`}
          >
            <div className="custom-agent-logo-avatar">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/non-covers/091524_LyneM-CalebF_AgentBlock_1.jpg"
                alt="Logo"
              />
            </div>
          </div>
        );
      }
    } else if (isAnnetteVait === "early2024") {
      return (
        <div
          className={`${editorOrPrint}qv4-logo-and-avatar-container-0-margin`}
        >
          <div className="custom-agent-logo-avatar">
            <img
              src="https://pma-coverphotos.s3.amazonaws.com/non-covers/021324_AnnetteVait_AgentBlock_300ppi_1.jpg"
              alt="Logo"
            />
          </div>
        </div>
      );
    } else if (listgenUserID === "106463") {
      return (
        <div
          className={`${editorOrPrint}qv4-logo-and-avatar-container-0-margin`}
        >
          <div className="custom-agent-logo-avatar left-custom-photo-margin">
            <img
              src="https://pma-coverphotos.s3.amazonaws.com/non-covers/071924_BethPowers-Deb_AgentBlock_3.jpg"
              alt="Logo"
            />
          </div>
        </div>
      );
    } else if (listgenUserID === "100030") {
      return (
        <div
          className={`${editorOrPrint}qv4-logo-and-avatar-container-0-margin`}
        >
          <div className="custom-agent-logo-avatar">
            <img src={NateRandallAgentBlock} alt="Logo" />
          </div>
        </div>
      );
    } else if (listgenUserID === "100040") {
      return (
        <div
          className={`${editorOrPrint}qv4-logo-and-avatar-container-0-margin`}
        >
          <div className="custom-agent-logo-avatar">
            <img
              src="https://pma-coverphotos.s3.amazonaws.com/non-covers/040424_AmberRussell_AgentBlock_sm.jpg"
              alt="Logo"
            />
          </div>
        </div>
      );
    } else if (listgenUserID === "101566") {
      return (
        <div
          className={`${editorOrPrint}qv4-logo-and-avatar-container-0-margin`}
        >
          <div className="custom-gummow-agent-logo-avatar">
            <img
              src="https://pma-coverphotos.s3.amazonaws.com/non-covers/071924_GummowBROs_AgentBlock.jpg"
              alt="Logo"
            />
          </div>
        </div>
      );
    } else if (isCindyAndrade) {
      return (
        <div
          className={`${editorOrPrint}qv4-logo-and-avatar-container ${logoMargin} cindy-andrade-logo-avatar`}
        >
          <div className="cindy-logo-avatar-col-1">
            <div className="contact-me contact-me-single-inner double-name">
              <div className="contact-me col">
                <p className="large-bolded-contact">
                  {listgenUserFirstName} {listgenUserLastName}
                </p>
              </div>
            </div>

            <div className="agent-photo-container align-bottom-photo-container">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/non-covers/cindy-andrade-headshot.png"
                className={`agent-photo ${agentPhotoStyle} ${
                  listgenUserID === "100526" || listgenUserID === "100138"
                    ? "larger-agent-photo"
                    : ""
                }`}
                alt="Agent"
              />
            </div>
            <div className="contact-section-single">
              <div className="contact-me contact-me-single-inner">
                {mobilePhoneNo.slice(0, 3)}{" "}
                <div className="contact-me-circle" />{" "}
                {mobilePhoneNo.slice(3, 6)}{" "}
                <div className="contact-me-circle" /> {mobilePhoneNo.slice(6)}
              </div>
              <div className="stacked-address single-agent">
                <div className="address-col">
                  <div className="office-address-container">
                    <p className="office-address">
                      {displayEmailAddress ? displayEmailAddress : emailAddress}
                    </p>
                  </div>
                </div>
                {specialSignatureText &&
                  formatTagline(
                    specialSignatureText,
                    "address-city-mt",
                    "address-city special-berkshire special-mt"
                  )}
              </div>
            </div>
          </div>
          <div className="cindy-logo-avatar-col-2">
            <div className={`special-no-margin-agent-logo`}>
              <img src={logoURL} alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (listgenUserID === "100528") {
      return (
        <div
          className={`${editorOrPrint}qv4-logo-and-avatar-container ${logoMargin} small-height-logo-and-avatar`}
        >
          <div className={`left-upper-col-agent-logo ${logoHeight}`}>
            <img src={logoURL} alt="Logo" />
          </div>
          <div className="agent-photo-container">
            <img
              src={photoURL}
              className={`agent-photo med-agent-photo`}
              alt="Agent"
            />
          </div>{" "}
          <div className="contact-section-single double-name-container">
            <div className="contact-me contact-me-single-inner double-name">
              <div className="contact-me col">
                <p className="capitalized-contact">
                  {listgenUserFirstName} {listgenUserLastName}
                </p>
              </div>
            </div>
            <div className="contact-me contact-me-single-inner">
              {mobilePhoneNo.slice(0, 3)} <div className="contact-me-circle" />{" "}
              {mobilePhoneNo.slice(3, 6)} <div className="contact-me-circle" />{" "}
              {mobilePhoneNo.slice(6)}
            </div>
            <div className="stacked-address single-agent">
              {specialSignatureText &&
                formatTagline(
                  specialSignatureText,
                  "address-city-mt",
                  "address-city  special-berkshire special-mt"
                )}
            </div>
          </div>
        </div>
      );
      // return (
      //   <div className={`${editorOrPrint}qv4-logo-and-avatar-container`}>
      //     <>
      //       <img
      //         src="https://pma-coverphotos.s3.amazonaws.com/final-500px-douglas-logo.png"
      //         alt="Douglas logo and avatar"
      //         className="douglas-logo-and-avatar"
      //       />
      //     </>
      //   </div>
      // );
    } else {
      return (
        <div
          className={`${editorOrPrint}qv4-logo-and-avatar-container ${logoMargin}`}
        >
          {logoURL &&
            listgenUserID !== "105795" &&
            !isWendyLeung &&
            listgenUserID !== "106020" &&
            listgenUserID !== "104835" &&
            listgenUserID !== "106397" &&
            !isDaphneChou && (
              <div className={`left-upper-col-agent-logo  ${logoHeight}`}>
                <img src={logoURL} alt="Logo" />
              </div>
            )}

          <div className={`agent-photo-container ${agentPhotoContainerStyle}`}>
            <img
              src={photoURL}
              className={`agent-photo ${agentPhotoStyle} ${
                listgenUserID === "100526" ||
                listgenUserID === "100138" ||
                isColleenForaker
                  ? "larger-agent-photo"
                  : ""
              }`}
              alt="Agent"
            />
          </div>

          {isWendyLeung && (
            <div className={`left-upper-col-agent-logo ${logoHeight}`}>
              <img src={logoURL} alt="Logo" />
            </div>
          )}
          {isKazemiTeam ? (
            <div className="logo-and-avatar-team-contact">
              <div className="contact-section-team">
                <div className="contact-me-team-container">
                  <div className="contact-me-team-inner">
                    <div className="contact-me row">
                      <p>
                        <p>{formatPhoneNoWithDashes(mobilePhoneNo)}</p>
                      </p>
                      <div className="contact-me-divider" />{" "}
                      <p>{listgenUserFirstName}</p>
                    </div>
                  </div>
                </div>
                <div className="stacked-address">
                  <div
                    className={`address-col team-email-address ${emailStyles}`}
                  >
                    {displayEmailAddress ? displayEmailAddress : emailAddress}
                  </div>

                  {specialSignatureText &&
                    formatTagline(
                      specialSignatureText,
                      "address-city-mt",
                      `address-city special-mt special-berkshire ${smallerSignature}`
                    )}
                </div>
              </div>
            </div>
          ) : isPerryGroup ? (
            <div className="logo-and-avatar-team-contact">
              <div className="contact-section-team">
                <div className="contact-me-team-container">
                  <div className="contact-me-team-inner">
                    <div className="contact-me row">
                      <p className="uppercased-contact">
                        {teamMembers[1].firstName} {teamMembers[1].lastName}
                      </p>
                      <div className="contact-me-divider" />{" "}
                      <div className="contact-me contact-me-single-inner">
                        {teamMembers[1].phone.slice(0, 3)}{" "}
                        <div className="contact-me-circle" />{" "}
                        {teamMembers[1].phone.slice(3, 6)}{" "}
                        <div className="contact-me-circle" />{" "}
                        {teamMembers[1].phone.slice(6)}
                      </div>
                    </div>
                    <div className="contact-me row">
                      <p className="uppercased-contact">
                        {listgenUserFirstName} {listgenUserLastName}
                      </p>
                      <div className="contact-me-divider" />{" "}
                      <div className="contact-me contact-me-single-inner">
                        {mobilePhoneNo.slice(0, 3)}{" "}
                        <div className="contact-me-circle" />{" "}
                        {mobilePhoneNo.slice(3, 6)}{" "}
                        <div className="contact-me-circle" />{" "}
                        {mobilePhoneNo.slice(6)}
                      </div>
                    </div>
                    <div className="small-text-contact">
                      <p>Former Mayor City of Monte Sereno</p>
                    </div>
                  </div>
                </div>
                <div className="stacked-address single-agent">
                  {specialSignatureText &&
                    formatTagline(
                      specialSignatureText,
                      "address-city-mt",
                      "address-city  special-berkshire special-mt"
                    )}
                </div>
              </div>
            </div>
          ) : listgenUserID === "xx100528" ? (
            <div className="logo-and-avatar-team-contact">
              <div className="contact-section-team">
                <div className="contact-me-team-container">
                  <div className="contact-me contact-me-single-inner">
                    {mobilePhoneNo.slice(0, 3)}{" "}
                    <div className="contact-me-circle" />{" "}
                    {mobilePhoneNo.slice(3, 6)}{" "}
                    <div className="contact-me-circle" />{" "}
                    {mobilePhoneNo.slice(6)}
                  </div>
                </div>
              </div>
            </div>
          ) : listgenUserID === "100411" ? (
            <div className="contact-section-single mccaw-ml">
              <div className="two-column-single-inner">
                <div className="two-column-single-inner-col">
                  <img
                    src="https://pma-coverphotos.s3.amazonaws.com/040323_Garret-MC_QRcode_500px.jpg"
                    alt="Garrett McCaw logo"
                    className="mccaw-qr-logo-and-avatar"
                  />
                </div>
                <div className="two-column-single-inner-col">
                  <div className="mccaw-contact-me-row">
                    <p className="mccaw-agent-name">{listgenUserFirstName}</p>
                    <p className="mccaw-agent-name phone-text">
                      {mobilePhoneNo.slice(0, 3)}{" "}
                      <div className="contact-me-circle" />{" "}
                      {mobilePhoneNo.slice(3, 6)}{" "}
                      <div className="contact-me-circle" />{" "}
                      {mobilePhoneNo.slice(6)}
                    </p>
                  </div>

                  <div className="scan-text-container">
                    <p className="mccaw-scan-text">DO YOU KNOW WHAT</p>
                    <p className="mccaw-scan-text">YOUR HOME IS WORTH?</p>
                  </div>
                </div>

                {/*<p className="mccaw-scan-text">with a free market analysis</p>*/}
              </div>
              <p className="mccaw-analysis-text">
                Scan here to find out with a free market analysis
              </p>
            </div>
          ) : listgenUserID === "ambers" ? (
            <div className="contact-section-single">
              <div className="address-col">
                <div className="office-address-container">
                  <p className="office-address">
                    Amber Russell & Robert Kerchner
                  </p>
                </div>
              </div>

              <div className="contact-me contact-me-single-inner">
                {mobilePhoneNo.slice(0, 3)}{" "}
                <div className="contact-me-circle" />{" "}
                {mobilePhoneNo.slice(3, 6)}{" "}
                <div className="contact-me-circle" /> {mobilePhoneNo.slice(6)}
              </div>
              <div className="stacked-address single-agent">
                <div className="address-col">
                  <div className="office-address-container centered">
                    <p className="office-address">BROKER OWNERS</p>
                    {/*<p className="office-address">{filteredBrokerage}</p>*/}
                  </div>
                </div>
              </div>
            </div>
          ) : listgenUserID === "100190" ? (
            <div className="logo-and-avatar-team-contact">
              <div className="contact-section-team">
                <div className="contact-me-team-container">
                  <div className="contact-me-team-inner">
                    <div className="contact-me row">
                      <p>
                        {useFullName
                          ? `${teamMembers[1].firstName} ${teamMembers[1].lastName}`
                          : teamMembers[1].firstName}
                      </p>
                      <div className="contact-me-divider" />{" "}
                      <p>{formatPhoneNoWithDashes(teamMembers[1].phone)}</p>
                    </div>
                    <div className="dre-row">
                      <p className="dre-word">dre</p>
                      <p className="dre-num">
                        {" "}
                        #
                        {formatAgentDesignation(
                          teamMembers[1].agentLicenseNumber
                        )}
                      </p>
                    </div>
                    <div className="contact-me row contact-extra-mb">
                      <p>
                        {useFullName
                          ? `${listgenUserFirstName} ${listgenUserLastName}`
                          : listgenUserFirstName}
                      </p>
                      <div className="contact-me-divider" />{" "}
                      <p>{formatPhoneNoWithDashes(mobilePhoneNo)}</p>
                    </div>
                    <div className="dre-row">
                      <p className="dre-word">DRE</p>

                      <p className="dre-num"> # {agentLicenseNumber}</p>
                    </div>
                  </div>
                </div>
                <div className="stacked-address">
                  <div className="address-col team-email-address">
                    {displayEmailAddress ? displayEmailAddress : emailAddress}
                  </div>

                  {specialSignatureText &&
                    listgenUserID === "100190" &&
                    formatTagline(
                      specialSignatureText,
                      "address-city-mt",
                      "address-city special-mt special-berkshire"
                    )}
                </div>
              </div>
            </div>
          ) : switchOrder ? (
            <div className="logo-and-avatar-team-contact">
              <div className="contact-section-team">
                <div className="contact-me-team-container">
                  <div className="contact-me-team-inner">
                    <div className="contact-me row">
                      <p>
                        {useFullName
                          ? `${teamMembers[1].firstName} ${teamMembers[1].lastName}`
                          : teamMembers[1].firstName}
                      </p>
                      <div className="contact-me-divider" />{" "}
                      <p>{formatPhoneNoWithDashes(teamMembers[1].phone)}</p>
                    </div>

                    <div className="contact-me row">
                      <p>
                        {useFullName
                          ? `${listgenUserFirstName} ${listgenUserLastName}`
                          : listgenUserFirstName}
                      </p>
                      <div className="contact-me-divider" />{" "}
                      <p>{formatPhoneNoWithDashes(mobilePhoneNo)}</p>
                    </div>
                  </div>
                </div>
                <div className="stacked-address">
                  <div
                    className={`address-col team-email-address ${emailStyles}`}
                  >
                    {displayEmailAddress ? displayEmailAddress : emailAddress}
                  </div>

                  {specialSignatureText &&
                    formatTagline(
                      specialSignatureText,
                      "address-city-mt",
                      `address-city special-mt special-berkshire ${smallerSignature}`
                    )}
                </div>
              </div>
            </div>
          ) : isKyle3Team ? (
            <div
              className={`logo-and-avatar-team-contact ${backpageAgentInfoStyles}`}
            >
              <div className="contact-section-team">
                <div className="contact-me-team-container">
                  <div className="contact-me-team-inner">
                    <div className="contact-me row">
                      <p>{teamMembers[2].firstName}</p>
                      <div className="contact-me-divider" />{" "}
                      <p>{formatPhoneNoWithDashes(teamMembers[2].phone)}</p>
                    </div>

                    <div className="contact-me row">
                      <p>{listgenUserFirstName}</p>
                      <div className="contact-me-divider" />{" "}
                      <p>{formatPhoneNoWithDashes(mobilePhoneNo)}</p>
                    </div>

                    <div className="contact-me row">
                      <p>{teamMembers[1].firstName}</p>
                      <div className="contact-me-divider" />{" "}
                      <p>{formatPhoneNoWithDashes(teamMembers[1].phone)}</p>
                    </div>
                  </div>
                </div>
                <div className="stacked-address">
                  {specialSignatureText &&
                    formatTagline(
                      specialSignatureText,
                      "address-city-mt",
                      `address-city special-mt special-berkshire ${smallerSignature}`
                    )}
                </div>
              </div>
            </div>
          ) : isMemberOfTeam &&
            !isScar &&
            listgenUserID !== "100027" &&
            !isJohnsons &&
            !isLeeShantePappas ? (
            <div
              className={`logo-and-avatar-team-contact ${backpageAgentInfoStyles}`}
            >
              <div className="contact-section-team">
                <div className="contact-me-team-container">
                  <div className="contact-me-team-inner">
                    <div className="contact-me row">
                      <p>
                        {useFullName
                          ? `${listgenUserFirstName} ${listgenUserLastName}`
                          : listgenUserFirstName}
                      </p>
                      <div className="contact-me-divider" />{" "}
                      <p>{formatPhoneNoWithDashes(mobilePhoneNo)}</p>
                    </div>
                    {listgenUserID !== "104709" && (
                      <div className="contact-me row">
                        <p>
                          {useFullName
                            ? `${teamMembers[1].firstName} ${teamMembers[1].lastName}`
                            : teamMembers[1].firstName}
                        </p>
                        <div className="contact-me-divider" />{" "}
                        <p>{formatPhoneNoWithDashes(teamMembers[1].phone)}</p>
                      </div>
                    )}

                    {(listgenUserID === "101268" || isKyle3Team) && (
                      <div className="contact-me row">
                        <p>
                          {useFullName
                            ? `${teamMembers[2].firstName} ${teamMembers[2].lastName}`
                            : teamMembers[2].firstName}
                        </p>
                        <div className="contact-me-divider" />{" "}
                        <p>{formatPhoneNoWithDashes(teamMembers[2].phone)}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="stacked-address">
                  {listgenUserID === "100129" && teamMembers ? (
                    <div className="address-col team-email-address">
                      {websiteURL}
                    </div>
                  ) : listgenUserID === "101003" ? (
                    <div className="address-col team-email-address">
                      <p>
                        {displayEmailAddress
                          ? displayEmailAddress
                          : emailAddress}
                      </p>

                      <p className="team-email-mt">kaitken@bhhscal.com</p>
                    </div>
                  ) : isJenMurry ? (
                    <div className="address-col team-email-address">
                      <p>{displayEmailAddress ? displayEmailAddress : ""}</p>

                      <p className="team-email-mt">vanomaurryteam.com</p>
                    </div>
                  ) : listgenUserID === "104504" ||
                    listgenUserID === "102545" ? (
                    <>
                      <div
                        className={`address-col team-email-address ${emailStyles}`}
                      >
                        {displayEmailAddress
                          ? displayEmailAddress
                          : emailAddress}
                      </div>

                      <div
                        className={`address-col team-email-address ${emailStyles}`}
                      >
                        {teamMembers[1].email}
                      </div>
                    </>
                  ) : (
                    <div
                      className={`address-col team-email-address ${emailStyles}`}
                    >
                      {listgenUserID === "100047" ||
                      listgenUserID === "100902" ||
                      isKyle3Team ||
                      isMarkWong
                        ? ""
                        : displayEmailAddress
                        ? displayEmailAddress
                        : emailAddress}
                    </div>
                  )}

                  {specialSignatureText &&
                    formatTagline(
                      specialSignatureText,
                      "address-city-mt",
                      `address-city special-mt special-berkshire ${smallerSignature}`
                    )}

                  {isMarkWong && (
                    <div className="backpage-designations-col">
                      <div>
                        <span className="backpage-designation-bold">SRES®</span>{" "}
                        - Senior Real Estate Specialist
                      </div>

                      <div>
                        <span className="backpage-designation-bold">CRS®</span>{" "}
                        - Certified Residential Specialist
                      </div>

                      <div>
                        <span className="backpage-designation-bold">ABR®</span>{" "}
                        - Accredited Buyer's Representative
                      </div>

                      <div>
                        <span className="backpage-designation-bold">QSC®</span>{" "}
                        - Quality Service Certified PLATINUM
                      </div>

                      <div>
                        <span className="backpage-designation-bold">CIPS®</span>{" "}
                        - Certified International Property Specialist
                      </div>

                      <div>
                        <span className="backpage-designation-bold">CGRS</span>{" "}
                        - Certified Global Relocation Specialist
                      </div>

                      <div>
                        <span className="backpage-designation-bold">CREN</span>{" "}
                        - Certified Real Estate Negotiator
                      </div>

                      <div>
                        <span className="backpage-designation-bold">NCS</span> -
                        New Construction Specialist
                      </div>

                      <div>
                        <span className="backpage-designation-bold">ASP®</span>{" "}
                        - Accredited Staging Professional
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : isAnnetteVait ? (
            <div className="contact-section-single av-contact-section-single">
              <div className="contact-me contact-me-single-inner backpage-annette-phone-number">
                {mobilePhoneNo.slice(0, 3)}{" "}
                <div className="contact-me-circle" />{" "}
                {mobilePhoneNo.slice(3, 6)}{" "}
                <div className="contact-me-circle" /> {mobilePhoneNo.slice(6)}
              </div>
              <div className="stacked-address av-single-agent">
                <div className="address-col">
                  <div className="office-address-container">
                    <p className="office-address backpage-annette-office-address">
                      {displayEmailAddress ? displayEmailAddress : emailAddress}
                    </p>

                    {specialSignatureText &&
                      formatTagline(
                        specialSignatureText,
                        "address-city-mt",
                        "av-special-signature"
                      )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="contact-section-single">
              <div className="contact-me contact-me-single-inner">
                {isJohnsons ? (
                  <span className="adjacent-agent-name">
                    Dianne and Brianna
                  </span>
                ) : (
                  <span className="adjacent-agent-name">
                    {listgenUserID === "106145"
                      ? "Meg"
                      : listgenUserID !== "107019"
                      ? listgenUserFirstName
                      : ""}{" "}
                    {(listgenUserID === "103404" ||
                      listgenUserID === "100374" ||
                      listgenUserID === "103405" ||
                      listgenUserID === "106421" ||
                      listgenUserID === "106591" ||
                      listgenUserID === "105100" ||
                      listgenUserID === "105959" ||
                      listgenUserID === "105068") &&
                      listgenUserLastName}
                  </span>
                )}
                {mobilePhoneNo.slice(0, 3)}{" "}
                <div className="contact-me-circle" />{" "}
                {mobilePhoneNo.slice(3, 6)}{" "}
                <div className="contact-me-circle" /> {mobilePhoneNo.slice(6)}
              </div>
              <div className="stacked-address single-agent">
                {listgenUserID !== "100474" && (
                  <div className="address-col">
                    <div className="office-address-container">
                      <p className="office-address">
                        {displayEmailAddress
                          ? displayEmailAddress
                          : emailAddress}
                      </p>
                    </div>
                  </div>
                )}

                {mlsAreaID === "106" ||
                mlsAreaID === "101" ||
                mlsAreaID === "100" ? (
                  <div className="address-city-mt">
                    <div className="address-city  special-berkshire special-mt">
                      Your Seaside Real Estate Specialist
                    </div>
                    <div className="address-city  special-berkshire special-mt">
                      Sí, Hablo Español
                    </div>
                  </div>
                ) : listgenUserID === "x100656" ? (
                  <div className="address-city-mt">
                    <div className="address-city special-berkshire special-mt">
                      #1 Team in Santa Barbara and Montecito
                    </div>

                    <div className="address-city special-berkshire special-mt non-bolded-address-city">
                      Follow me on Instagram @lisamccollumrealestate
                    </div>
                  </div>
                ) : specialSignatureText ? (
                  formatTagline(
                    specialSignatureText,
                    "",
                    "special-berkshire special-mt"
                  )
                ) : (
                  ""
                )}
                {singleAgent2LineBerkshire && !isMemberOfTeam ? (
                  <div></div>
                ) : (
                  <div
                    className={`address-city ${
                      smallFontBrokerage && "small-brokerage-text"
                    }`}
                  >
                    {isMaryAyers && (
                      <p className="portuguese-text">Eu falo Portugues!</p>
                    )}
                    {listgenUserID === "100474" && (
                      <p className="jessie-website-text">
                        JessieSessionsRealtor.com
                      </p>
                    )}
                  </div>
                )}
                {(listgenUserID === "100138" || listgenUserID === "105042") && (
                  <div className="address-city special-mt special-berkshire">
                    {websiteURL}
                  </div>
                )}
              </div>
              {isDaphneChou && (
                <div className={`left-upper-col-agent-logo  ${logoHeight}`}>
                  <img src={logoURL} alt="Logo" />
                </div>
              )}
            </div>
          )}
        </div>
      );
    }
  }
}

export default QVFourLogoAndAvatar;
