import React, { Component, Fragment } from "react";
import classNames from "classnames";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import styles from "./styles.scss";

import { connect } from "react-redux";
// import Navbar from '../../_components/_layout/Navbar';
// import PMAHeader from '../../_components/_layout/Navbar/PMAHeader';

import { pmaActions } from "_store/_actions";
// import "./index.scss";
import DocumentTitle from "react-document-title";
//import { accountConstants } from '../../_constants';
import PMANavbar from "views/Pma/PMANavbar";
import {
  CircularProgress,
  Typography,
  ButtonGroup,
  Button,
  Grid,
} from "@material-ui/core";
import MarketIndicators from "./MarketIndicators";
import FooterButtons from "./FooterButtons";
import DataVerificationFooter from "./DataVerificationFooter";
import DataVerificationHeader from "./DataVerificationHeader";
import VerificationTable from "./VerificationTable";
import ActivePendingTable from "./ActivePendingTable";
import { Fab } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ScrollToTopComponent } from "components";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";

class VerificationScreen extends Component {
  getTitle = (area) => {
    //console.log("get title", this.props);
    const { listgenUserID } = this.props.agentData;
    const { mlsAreaID } = this.props.currentArea;
    const { demoAccountAreaName } = this.props;
    const { splitAreas } = propertyUtil;
    let areaSplit = splitAreas(area);
    //console.log("areaSplit", areaSplit);
    let characterCount = areaSplit.join("").length + areaSplit.length;
    //console.log("characterCounts", characterCount);
    let specialFontSize;
    if (characterCount > 43) {
      specialFontSize = "small-market-sales-title";
    } else if (characterCount > 27) {
      specialFontSize = "medium-market-sales-title";
    } else {
      specialFontSize = "regular-market-sales-title";
    }
    if (demoAccountAreaName && demoAccountAreaName.length > 1) {
      return (
        <div className="market-sales-title-text">{demoAccountAreaName}</div>
      );
    } else {
      return (
        <div className={`market-sales-non-sub-container ${specialFontSize}`}>
          {areaSplit.map((area, i) => {
            return (
              <div key={area} className="market-sales-inside-title-row">
                {i > 0 && (
                  <div
                    className={`market-sales-title-separator ${specialFontSize}`}
                  ></div>
                )}

                <p className={`market-sales-title-text ${specialFontSize}`}>
                  {area}
                </p>
              </div>
            );
          })}
        </div>
      );
    }
  };

  render() {
    const { getTitle } = this;
    const {
      agentData,
      mlsAreas,
      navbarData,
      enableMailProApprovalProcess,
      handlePMAIssuesDialog,
      currentStep,
      propertyData,
      pma,
      marketActivity,
      mlsAreaIds,
      MQCityDetails,
      currentArea,
      isLoading,
      mailingVersion,
      handleDrawerOpen,
      quarterlyAreaTable,
      isVerification,
      dataVerification,
      isMailProLocked,
    } = this.props;
    const { mlsNeighborhood } = currentArea;
    const {
      currentPropertyDetailsForCoverPage,
      previousPropertyDetailsForCoverPage,
    } = dataVerification;
    //console.log("verification screen props", this.props);
    const { showBox, areaCount } = propertyUtil.getUnapprovedAreaCount(
      mlsAreas
    );
    let use90Comparison = true;
    let tableData;
    let title1;
    let title2;
    let date1;
    let date2;
    let propDetails1;
    let propDetails2;
    if (use90Comparison) {
      use90Comparison = true;
      tableData = dataVerification;
      title1 = "Last 90 Days";
      title2 = "Previous 90 Days";
      date1 = "AUG 1, 2021 — NOV 1, 2021";
      date2 = "MAY 1, 2021 — AUG 1, 2021";
      propDetails1 = currentPropertyDetailsForCoverPage;
      propDetails2 = previousPropertyDetailsForCoverPage;
    } else {
      {
        /*Todo:change to 6 months*/
      }
      use90Comparison = false;
      tableData = dataVerification;
      title1 = "Last 6 Months";
      title2 = "Previous 6 Months";
      date1 = "AUG 1, 2021 — NOV 1, 2021";
      date2 = "MAY 1, 2021 — AUG 1, 2021";
      propDetails1 = previousPropertyDetailsForCoverPage;
    }
    return (
      <DocumentTitle title={"Verification Screen"}>
        <div className="verification-screen">
          <main>
            <PMANavbar
              propertyData={propertyData}
              agentData={agentData}
              pma={pma}
              mlsAreas={mlsAreas}
              currentArea={currentArea}
              navbarData={navbarData}
              handleDrawerOpen={handleDrawerOpen}
              isLoading={isLoading}
              showUnapprovedBox={showBox}
              unapprovedAreaCount={areaCount}
              isDataVerification={true}
              enableMailProApprovalProcess={enableMailProApprovalProcess}
              //isMailProLocked={isMailProLocked} link this as well from parent
              // newMailingMailProMode={
              //   currentStep === 0
              //     ? false
              //     : currentStep === 1 && showNewMailingDialog
              // }
            />
            <section
              className={`pma-margin-top ${
                currentStep === 1 ? "pma-margin-top-mailpro" : ""
              }`}
            >
              {currentArea ? (
                <Fragment>
                  {enableMailProApprovalProcess &&
                    currentArea.mailingStatus !== "Approved" && (
                      <div className="showUnApprovedMessage">
                        <div className="showUnApprovedMessageInner">
                          <p>
                            <span>
                              Please review your{" "}
                              {mailingVersion === "mq"
                                ? "Mid-Quarter"
                                : mailingVersion === "q"
                                ? "Quarterly"
                                : "Year End"}{" "}
                              PMA carefully.
                            </span>
                            If you find any errors please let us know
                            immediately.
                          </p>
                          <p>
                            If everything looks good then please click on the
                            tab below which will take you to the MailPro screen.
                          </p>
                          <div className="unApprovedButtonContainer">
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={(event) => this.props.switchStep(1)}
                            >
                              Looks good to me. Continue
                            </Button>
                            <Button
                              color="default"
                              variant="contained"
                              onClick={(event) => handlePMAIssuesDialog(true)}
                            >
                              I have found the following issues / error(s)
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}

                  <div className="verification-page">
                    <div className="verification-page-title">
                      <div className="market-sales-title-container">
                        <div className="market-sales-title-row">
                          <div className="market-sales-subtitle-text">
                            Data Verification
                          </div>

                          <div className="market-sales-review-text">
                            Please review the following data points to ensure
                            your PMA's accuracy
                          </div>
                        </div>
                        <div className="market-sales-title-divider"></div>
                        {getTitle(mlsNeighborhood)}
                      </div>
                    </div>
                    {quarterlyAreaTable &&
                      Object.keys(quarterlyAreaTable).length > 0 && (
                        <MarketIndicators
                          currentArea={currentArea}
                          quarterlyAreaTable={quarterlyAreaTable}
                          dataVerification={dataVerification}
                        />
                      )}
                    <VerificationTable
                      quarterlyAreaTable={quarterlyAreaTable}
                      currentArea={currentArea}
                      agentData={agentData}
                      dataVerification={dataVerification}
                      showTitle={true}
                      titleText={title1}
                      dateRange={date1}
                      propDetails={propDetails1}
                      coverPageAverages={
                        dataVerification.averagesForCoverPageCurrent
                      }
                    />
                    <VerificationTable
                      quarterlyAreaTable={quarterlyAreaTable}
                      currentArea={currentArea}
                      agentData={agentData}
                      dataVerification={dataVerification}
                      titleText={title2}
                      dateRange={date2}
                      pastStyles={true}
                      propDetails={propDetails2}
                      coverPageAverages={
                        dataVerification.averagesForCoverPagePrevious
                      }
                    />

                    {/*<ActivePendingTable
                      quarterlyAreaTable={quarterlyAreaTable}
                      currentArea={currentArea}
                      agentData={agentData}
                        dataVerification={dataVerification}
                    />*/}
                    <FooterButtons
                      handlePMAIssuesDialog={handlePMAIssuesDialog}
                    />
                    <DataVerificationFooter currentArea={currentArea} />
                  </div>
                </Fragment>
              ) : (
                <div>Data is loading...</div>
              )}
            </section>
          </main>
        </div>
      </DocumentTitle>
    );
  }
}
export default VerificationScreen;
