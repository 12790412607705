import React, { Component } from "react";
import QVFourFirstPage from "./QVFourFirstPage";
import QVFourSecondPage from "./QVFourSecondPage";
import PropTypes from "prop-types";

class QVFour extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./qv4print.scss");
    } else {
      require("./qv4styles.scss");
    }
  }
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      coverPageDetails,

      quarterlyAreaTable,
      quarterlyCityTable,
      testimonials,
      quadrantModalOpen,
      quadrantDetails,
      propertyPhotoDetailsDTO,
      missionStatement,
      isDemo,
      MQCityDetails,
      yearEnd,
      bubbleBoxes,
    } = this.props;

    return (
      <div>
        <div
          className={printVersion ? "qv4-print-page" : "qv4-pma-editor-page"}
        >
          <QVFourFirstPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            coverPageDetails={coverPageDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            testimonials={testimonials}
            quadrantModalOpen={quadrantModalOpen}
            quadrantDetails={quadrantDetails}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            missionStatement={missionStatement}
            isDemo={isDemo}
            bubbleBoxes={bubbleBoxes}
          />
          <QVFourSecondPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            quarterlyAreaTable={quarterlyAreaTable}
            quarterlyCityTable={quarterlyCityTable}
            isDemo={isDemo}
            MQCityDetails={MQCityDetails}
            yearEnd={yearEnd}
          />
        </div>
      </div>
    );
  }
}

export default QVFour;
