import React from "react";
import { connect } from "react-redux";
import PmaMailingList from "./PmaMailingList";
import { pmaActions } from "../../../_store/_actions";
import "../index.scss";
import DocumentTitle from "react-document-title";
import PMANavbar from "../PMANavbar";
import { CircularProgress, Typography } from "@material-ui/core";
import { propertyUtil } from "../PropertyUtil";
import { GetStateStorage } from "common/storage";
import UnderConstruction from "views/Pma/PmaEditor/UnderConstruction";
import { renderLogicUtil } from "../RenderLogicUtil";

const AgentMailProLoader = () => {
  return (
    <div
      style={{
        height: "80vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        background: "inherit",
      }}
    >
      <div>
        <CircularProgress />
      </div>
      <div>
        <Typography variant="subtitle2">&nbsp;&nbsp;Loading MailPro</Typography>
        <Typography variant="body1">
          &nbsp;&nbsp;This may take a few seconds. Lots of data is being
          collected!
        </Typography>
      </div>
    </div>
  );
};

class MailProInit extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    hoID: null,
    homeowner: 0,
  };
  this.localMailProDataRef = React.createRef(null);
}

  async componentDidUpdate() {
    this.initiMailProData();
  }

  async componentDidMount() {
    let { switchArea, paramAreaID } = this.props;
    const stateData = GetStateStorage();
    const agentData = stateData.userData;
    let navbarData =
      agentData && Array.isArray(agentData.navbarInfo)
        ? agentData.navbarInfo.filter(
          (area) => area.mlsAreaID === paramAreaID
        )[0]
        : {};
    switchArea(navbarData);
    this.initiMailProData();
    window.scrollTo(0, 0);
  }

  initiMailProData = () => {
    const {
      paramAreaID,
      agentData,
      nextPMAPrintDate,
      propertyData,
    } = this.props;
    try {
      if (!propertyData.length && nextPMAPrintDate) {
        this.props.getMailerProDetails(
          paramAreaID,
          agentData,
          nextPMAPrintDate
        );
        window.scrollTo(0, 0);
      }
    } catch {
      console.log("***error with mailpro api");
    }
  };

  forceUpdateAgentPMA = () => {
    this.forceUpdate();
  };

  resetLocalMailProRefFn = () => {
    if(this.localMailProDataRef.current){
      this.localMailProDataRef.current.resetMailProLocalState();
    }
  };

  render() {
    const {
      currentStep,
      propertyData,
      pma,
      mlsAreaIds,
      mlsAreas,
      currentArea,
      agentData,
      isLoading,
      navbarData,
      handleDrawerOpen,
      enableMailProApprovalProcess,
      singleHOData,
      isUnavailable,
    } = this.props;

    /**Get Unapproved areas */
    const { showBox, areaCount } = propertyUtil.getUnapprovedAreaCount(
      mlsAreas
    );


    const forceBlockEditing = renderLogicUtil.getForceBlockEditing(agentData);
    const isMailProLocked = propertyUtil.shouldBlockMailPro(agentData.mailProStatus) || forceBlockEditing || (currentArea || {}).mailingStatus === "Approved";

    const PMANavCopy = <PMANavbar
      propertyData={propertyData}
      agentData={agentData}
      pma={pma}
      mlsAreas={mlsAreas}
      currentArea={currentArea}
      navbarData={navbarData}
      handleDrawerOpen={handleDrawerOpen}
      isLoading={isLoading}
      showUnapprovedBox={showBox}
      unapprovedAreaCount={areaCount}
      enableMailProApprovalProcess={enableMailProApprovalProcess}
      isMailProLocked={isMailProLocked}
      resetLocalMailProRefFn={this.resetLocalMailProRefFn}
    />;

    if (isUnavailable) {
      return (
        <DocumentTitle title={"MailPro | ListGen"}>
          <div className="pma-creator">
            <main>
              {PMANavCopy}
              <section
                className={`pma-margin-top ${currentStep === 1 ? "pma-margin-top-mailpro" : ""
                  }`}
              >
                <UnderConstruction altIssue={true} />
              </section>
            </main>
          </div>
        </DocumentTitle>
      );
    } else if (!isLoading && propertyData && propertyData.length > 1) {
      return (
        <DocumentTitle title={"MailPro | ListGen"}>
          <div className="pma-creator">
            <main>
              {PMANavCopy}
              <section
                className={`pma-margin-top ${currentStep === 1 ? "pma-margin-top-mailpro" : ""
                  }`}
              >
                {singleHOData && propertyData ? (
                  <div className="section-pma-mailing-list">
                    <PmaMailingList
                      agentData={agentData}
                      propertyData={propertyData}
                      mlsAreas={mlsAreaIds}
                      currentArea={currentArea}
                      isLoading={isLoading}
                      newMailingForceUpdate={this.forceUpdateAgentPMA}
                      isMailProLocked={isMailProLocked}
                      ref={this.localMailProDataRef}
                    />
                  </div>
                ) : (
                  <div>MailPro is loading...</div>
                )}
              </section>
            </main>
          </div>
        </DocumentTitle>
      );
    } else {
      return <AgentMailProLoader />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    singleHOData: state.pma.singleHOData,
    currentStep: state.pma.currentStep,
    currentArea: state.pma.currentArea,
    propertyData: state.pma.propertyData,
    pma: state.pma,
    mlsAreaIds: state.pma.mlsAreaIds,
    isLoading: state.pma.isLoading,
    version: state.pma.version,
    explicitEnableNewMailProInit: state.pma.explicitEnableNewMailProInit,
    nextPMAPrintDate: state.pma.nextPMAPrintDate,
    xxx: state.pma,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMailerProDetails: (areaId, agentData, nextPMAPrintDate) => {
      dispatch(
        pmaActions.getMailProMailerData(areaId, agentData, nextPMAPrintDate)
      );
    },
    switchVersion: (version) => {
      dispatch(pmaActions.switchVersion(version));
    },
    switchArea: (area) => dispatch(pmaActions.switchArea(area)),
    changeLoading: (status) => {
      dispatch(pmaActions.changeLoading(status));
    },
    updateNavbar: (mlsAreas) => dispatch(pmaActions.updateNavbar(mlsAreas)),
    switchStep: (step) => dispatch(pmaActions.switchStep(step)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailProInit);
