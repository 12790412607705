import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";

import QVFourGreetingsPS from "./QVFourGreetingsPS";

class QVFourPS extends Component {
  getGlobalPSByAreaLogic = () => {
    const { currentArea } = this.props;

    return currentArea &&
      currentArea.globalPSCurrent &&
      currentArea.globalPSCurrent !== "<p></p>"
      ? currentArea.globalPSCurrent
      : null;
  };
  getNoteDataObject = () => {
    const { changeDetails, mailProStatus, propertyDetails } = this.props;
    const { personalPSHistory } = changeDetails
      ? changeDetails
      : propertyDetails
      ? propertyDetails
      : {};
    return personalPSHistory;
  };

  getAfterPrintDateNoteByType = (applyTypeCheck = true) => {
    //check this method
    //let isHavingNote = false;
    let afterDateNote = null;
    let personalPSHistory = this.getNoteDataObject();
    //const { lastPrintDate } = this.state;
    const { cameFrom, lastPMAPrintDate } = this.props;
    return renderLogicUtil.getCurrentMailingPersonalNote(
      personalPSHistory,
      lastPMAPrintDate,
      cameFrom,
      applyTypeCheck
    );
  };

  getGlobalNoteByAreaLogic = (isGlobalNoteAddedInCurrentMailing = true) => {
    /**check for latest current area data */
    const { currentArea } = this.props;
    //return currentArea && currentArea.globalPS; //old way
    //send data absed on current verion keys as mailpro only owrking with the current version.
    if (!isGlobalNoteAddedInCurrentMailing) {
      return "";
    } else {
      //we only have to ssend from current version keys
      return currentArea &&
        currentArea.globalNoteCurrent &&
        currentArea.globalNoteCurrent !== null
        ? currentArea.globalNoteCurrent
        : "";
    }
  };
  getCurrentArea = () => {
    let { currentArea, mlsAreas } = this.props;
    return propertyUtil.getCurrentArea(mlsAreas, currentArea);
  };

  getGlobalNoteDateByAreaLogic = () => {
    const { currentArea } = this.props;
    return currentArea && currentArea.globalNoteCurrentDateTime
      ? currentArea.globalNoteCurrentDateTime
      : null;
  };

  getCurrentArea = (mlsAreas, currentArea) => {
    let xArea = {};
    if (!mlsAreas || (Array.isArray(mlsAreas) && mlsAreas.length === 0)) {
      return currentArea;
    }
    (mlsAreas ? mlsAreas : []).map((area) => {
      if (area.mlsAreaID === currentArea.mlsAreaID) {
        xArea = area;
      }
    });
    return xArea;
  };

  render() {
    const { mailingNum, lastPMAPrintDate, hoData } = this.props;

    const { changeDetails, mailingDetails, propertyDetails } = hoData;
    let globalPSCreationDate = this.getGlobalNoteDateByAreaLogic();
    //we need to call here again becuase in table we dont call open method
    let globalNoteShowHideLogic = renderLogicUtil.checkGlobalNoteShowHideLogic(
      globalPSCreationDate,
      changeDetails,
      propertyDetails
    );

    //get the global note now
    let globalXNote = this.getGlobalNoteByAreaLogic(
      globalNoteShowHideLogic.isGlobalNoteAddedInCurrentMailing
    );
    let hoName = renderLogicUtil.getNoteGreetingTextV4(
      hoData.changeDetails,
      "&"
    );
    // let hoName = renderLogicUtil.getNoteGreetingTextV3(
    //   this.props.hoData.HONameProcessedLocalData,
    //   "&"
    // );
    const { personalPSHistory } = changeDetails
      ? changeDetails
      : propertyDetails
      ? propertyDetails
      : {};
    let anyPersonalNoteAddedinCurrentMailing = renderLogicUtil.getCurrentMailingPersonalNote(
      personalPSHistory,
      lastPMAPrintDate,
      "",
      false
    );
    let lastNote = this.getAfterPrintDateNoteByType(false);
    let addedGlobalPS = this.getGlobalPSByAreaLogic();
    let addedGlobalNote = this.getGlobalNoteByAreaLogic(
      globalNoteShowHideLogic.isGlobalNoteAddedInCurrentMailing
    );
    const {
      HONickname,
      agentFirstNames,
      EditedHomeownerName,
      removeTrust,
      removeInitials,
      changeDetailsHOName,
    } = propertyUtil;
    let theHomeowners;
    let editedHomeownerName = EditedHomeownerName(
      changeDetails && changeDetails.nickName
    );
    let homeownerDetailsNames = changeDetailsHOName(changeDetails);
    //console.log("inside qv4 ps", this.props);

    let {
      currentArea,
      mlsAreas,
      version,

      lastPmaPrintDate,
    } = this.props;
    /**change current area */
    currentArea = this.getCurrentArea(mlsAreas, currentArea);
    const { globalNoteCurrent, globalPSCurrent } = currentArea;

    const { OwnerFirst, OwnerLast } = mailingDetails;
    const {
      listgenUserFirstName,
      isMemberOfTeam,
      teamMembers,
      listgenUserID,
    } = this.props.agentData;

    let hoTextNote;
    if (
      !globalNoteShowHideLogic.showGlobalNote &&
      anyPersonalNoteAddedinCurrentMailing &&
      anyPersonalNoteAddedinCurrentMailing.note
    ) {
      hoTextNote = anyPersonalNoteAddedinCurrentMailing.note;
    } else if (
      changeDetails &&
      changeDetails.textPS &&
      changeDetails.textPS.length > 1
    ) {
      hoTextNote = changeDetails.textPS;
    } else if (globalNoteCurrent && globalNoteCurrent.length > 1) {
      hoTextNote = globalNoteCurrent;
    } else {
      hoTextNote = "Stay tuned for your next PMA!";
    }
    let agentSignoff = !teamMembers
      ? listgenUserFirstName
      : teamMembers[0].firstName + " and " + teamMembers[1].firstName;

    let currentPS;
    if (
      // !globalNoteShowHideLogic.showGlobalNote &&
      anyPersonalNoteAddedinCurrentMailing &&
      anyPersonalNoteAddedinCurrentMailing.ps
    ) {
      currentPS = anyPersonalNoteAddedinCurrentMailing.ps;
    } else if (
      changeDetails &&
      changeDetails.personalPS &&
      changeDetails.personalPS.length >= 10
    ) {
      currentPS = changeDetails.personalPS;
    } else if (globalPSCurrent && globalPSCurrent.length > 1) {
      currentPS = globalPSCurrent;
    } else {
      currentPS = null;
    }
    const { agentData, printVersion, isDemo } = this.props;

    if (isDemo && version === "qv4") {
      hoTextNote = `<p>Crisp mornings and warm afternoons is how we do Fall! These days are filled with the customary hustle and bustle of preparing for the holidays ahead. All the while our local real estate market has stayed strong. Take a moment to check out the market trends I’ve compiled on the opposite page. Hedgerow’s average sales price is showing 2 ouf 5 quarters of quarterly increases – indicating the market still favors sellers.</p>`;
    } else if (isDemo && version === "year-end-2022") {
      hoTextNote = `<p>Happy Summer! As we navigate the natural ups and downs of the economy, we find ourselves in
what is now a robust Seller’s market. Peaks and valleys in real estate cycles are part of a larger
pattern — and today’s current strong market conditions offer unique opportunities. Early
Summer is an ideal time to captivate potential buyer’s interest! When you’re ready, I'm happy to
provide an overview of your property’s strengths to help you get the most out of this perfect
selling season. Catch the Seller’s Wave!</p>`;
    } else if (isDemo && version === "bubble-compare") {
      hoTextNote = `<p>As your neighborhood expert, our commitment to you is to provide consistent market insights enabling you to
make well-informed decisions about your property in this dynamic market. Throughout the year, we also research
national real estate trends offering a broader perspective and comparison with our local market. (refer to the
back page). In a nutshell, market forecasts suggest a continued</p>`;
    }

    let isKylePS = ``;
    // let isCindyAndrade = listgenUserID === "102378";
    // if (isCindyAndrade) {
    //   currentPS = `The National Association of Realtors® on March 15th agreed to settle the "Sitzer Burnett Commission Lawsuit." Please contact me if you would like an update on the settlement and what it means to you as a homeowner or buyer.`;
    // }
    // console.log("currentPS", currentPS);
    return (
      <QVFourGreetingsPS
        version={version}
        theHomeowners={hoName}
        hoTextNote={hoTextNote}
        currentPS=""
        // currentPS={currentPS}
        isMemberOfTeam={isMemberOfTeam}
        teamMembers={teamMembers}
        agentFirstNames={agentFirstNames}
        listgenUserFirstName={listgenUserFirstName}
        agentSignoff={agentSignoff}
        mailingNum={mailingNum}
        currentArea={currentArea}
        listgenUserID={listgenUserID}
        agentData={agentData}
        printVersion={printVersion}
        hoData={hoData}
      />
    );
  }
}

export default QVFourPS;
