import React, { Component } from "react";
import QVFourAgentLogoHeader from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourAgentLogoHeader";
import QVFourPhotos from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotos";
import QVFourPresentedByFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPresentedByFooter";
import QVFourPhotoSection from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotoSection";
import QVFourPS from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPS";

class TableQuadrantsSecondRight extends Component {
  render() {
    const {
      currentArea,
      agentData,
      printVersion,
      version,
      hoData,
      mlsAreas,

      quarterlyAreaTable,
    } = this.props;
    console.log("second right", this.props);
    return (
      <div className="table-quadrants-second-right">
        <QVFourAgentLogoHeader
          agentData={agentData}
          currentArea={currentArea}
        />
        <QVFourPS
          agentData={agentData}
          hoData={hoData}
          currentArea={currentArea}
          mlsAreas={mlsAreas}
          version={version}
          printVersion={printVersion}
          mailingNum="qv4"
        />
        {/*<QVFourPhotos
          currentArea={currentArea}
          agentData={agentData}
          printVersion={printVersion}
          version={version}
          hoData={hoData}
          currentSoldListings={quarterlyAreaTable.currentSoldListings}
        />*/}
        <QVFourPhotoSection
          currentArea={currentArea}
          agentData={agentData}
          printVersion={printVersion}
          version="qv4"
          hoData={hoData}
          currentSoldListings={quarterlyAreaTable.currentSoldListings}
        />
        <QVFourPresentedByFooter
          agentData={agentData}
          currentArea={currentArea}
          currentSoldListings={quarterlyAreaTable.currentSoldListings}
          printVersion={printVersion}
        />
      </div>
    );
  }
}

export default TableQuadrantsSecondRight;
