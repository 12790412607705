import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles, Badge, Collapse } from "@material-ui/core";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import {
  DashboardOutlined as DashboardIcon,
  PeopleOutlined as PeopleIcon,
  ShoppingBasketOutlined as ShoppingBasketIcon,
  LockOpenOutlined as LockOpenIcon,
  TextFields as TextFieldsIcon,
  ImageOutlined as ImageIcon,
  InfoOutlined as InfoIcon,
  AccountBoxOutlined as AccountBoxIcon,
  SettingsOutlined as SettingsIcon,
  Search as SearchIcon,
  Home as HomeIcon,
  Person as PersonIcon,
  Help as HelpIcon,
  LockSharp as LockSharpIcon,
  CreditCard as CreditCardIcon,
  History as HistoryIcon,
  PageviewOutlined as PageviewIcon,
  ControlPointDuplicate as ConstantControlIcon,
  Notes as NotesIcon,
  ListAlt as ListAltIcon,
  Streetview as StreetViewIcon,
  Assessment as ReportIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  CenterFocusStrong as CenterFocusStrongIcon,
  AddComment as FeedbackIcon,
  AddToQueue as AdvantagesIcon,
  InsertInvitation as InsertInvitationIcon,
  CalendarToday as CalendarTodayIcon,
  Today as TodayIcon,
  Adjust as AdjustIcon,
  Print as ExportPrintIcon,
  Highlight as HighlightIcon,
  DateRange as DateRangeIcon,
  EventNote as EventNoteIcon,
} from "@material-ui/icons";
import styles from "./styles";
import { Store } from "Store";
import Base from "common/constants";
import { GetStateStorage, IsValidNavbarData } from "common/storage";

const Sidebar = (props) => {
  const { state, dispatch } = React.useContext(Store);
  const [openReportsList, setOpenReportsList] = useState(false);
  const { userData } = state;
  const { classes, className, sessionSwitchMode } = props;
  const rootClassName = classNames(classes.root, className);

  /**logo */
  const listgenLogo = "/images/listgen-logo.png";
  const listgenAltLogo = "/images/listgen-text-advanced.PNG";

  const stateData = GetStateStorage();
  const agentData = (stateData || {}).userData;
  let navbarData =
    agentData && Array.isArray(agentData.navbarInfo)
      ? agentData.navbarInfo[0]
      : {};

  /**Nested Dropdowns */
  let reportNestedList = [
    // {
    //   link: '/sales/report/brokerageperformance',
    //   name: 'Brokerage Performance',
    // },
    // { link: '/sales/report/countyperformance', name: 'County Performance' },
    // { link: '/sales/report/cityperformance', name: 'City Performance' },
    { link: "/sales/report/agentperformance", name: "Agent Performance" },
    // { link: '/sales/report/agentbehaviour', name: 'Agent Behaviour' },
    // { link: '/sales/report/agentdetails', name: 'Agent Details' },
  ];
  /**Count PMA Admin Variables */
  let pADesignationsCount = userData.designationsCount
    ? userData.designationsCount
    : 0;
  let pAAdvantagesCount = userData.advantagesCount
    ? userData.advantagesCount
    : 0;
  let pATestimonialsCount = userData.testimonialsCount
    ? userData.testimonialsCount
    : 0;
  let pAMissionsCount = userData.missionStatementsCount
    ? userData.missionStatementsCount
    : 0;
  let pAAreaCount = userData.navbarInfo ? userData.navbarInfo.length : 1;
  let propertyHighlightsCount = 0;
  //pAAdvantagesCount is area wise so divide by area count also
  pAAdvantagesCount = parseInt(pAAdvantagesCount / pAAreaCount);

  /**
   * Methods
   */

  const handleClickNestedList = (openListVariable, setOpenFn) => {
    setOpenFn(!openListVariable);
  };

  const isNestedListIsOpen = (reportVariable, setOpenFn) => {
    let foundLink = reportVariable.find(
      (item) => item.link === window.location.pathname
    );
    let isOpen = foundLink && Object.keys(foundLink).length > 0 ? true : false;
    setOpenFn(isOpen);
  };

  useEffect(() => {
    isNestedListIsOpen(reportNestedList, setOpenReportsList);
  }, [reportNestedList]); //reportNestedList

  return (
    <nav className={rootClassName}>
      <div className={classes.logoWrapper}>
        <div className={classes.logoInner}>
          <div className={classes.logoFirst}>
            <Link className={classes.logoLink} to="/home">
              <img
                alt="ListGen logo"
                className={classes.logoImage}
                src={listgenLogo}
              />
            </Link>
          </div>
          <div className={classes.logoSecond}>
            <Link className={classes.logoLink} to="/home">
              <img
                alt="ListGen logo"
                className={classes.logoImage}
                src={listgenAltLogo}
              />
            </Link>
          </div>
        </div>
        {/* <Typography
              className={classes.title}
              variant="h4"
            >
              ListGen Dashboard
            </Typography> */}
      </div>
      <Divider className={classes.logoDivider} />
      {/* <div className={classes.profile}>
      </div> */}
      {/* <Divider className={classes.profileDivider} /> */}
      <List component="div" disablePadding>
        <ListItem
          activeClassName={classes.activeListItem}
          className={classes.listItem}
          component={NavLink}
          to="/home"
          classes={{ gutters: classes.listItemGutter }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Home"
          />
        </ListItem>
      </List>
      <Divider className={classes.profileDivider} />
      {userData.listgenRoleID === Base.APP_DATA.APP_AGENT_ROLE_ID ? (
        <List component="div" disablePadding>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            // to="/pma"
            to={navbarData ? `/pma/${navbarData.mlsAreaID}` : "/pma"}
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="View PMA"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            // to="/mailpro"
            to={
              navbarData.mlsAreaID
                ? `/mailpro/${navbarData.mlsAreaID}`
                : "/mailpro"
            }
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="View MailPro"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to={"/agent/missionstatements"}
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AdjustIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                root: classes.listItemTextRoot,
                primary: classes.listItemText,
                secondary:
                  pAMissionsCount > 0
                    ? classes.pmaAdminBlueText
                    : classes.pmaAdminErrorText,
              }}
              primary="Mission Statements"
              secondary={
                pAMissionsCount > 0
                  ? "Add more Mission Statements"
                  : "Please add a Mission Statement"
              }
            />
            <ListItemIcon
              className={classNames(classes.listItemIcon, classes.pmaAdminIcon)}
            >
              <Badge
                badgeContent={`${pAMissionsCount}`}
                //color="primary"
                classes={{
                  badge:
                    pAMissionsCount > 0
                      ? classes.pmaAdminBlueBg
                      : classes.pmaAdminErrorBg,
                }}
                className={classes.newBadge}
              />
            </ListItemIcon>
          </ListItem>
          {/* <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to={"/agent/mystrengths"}
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CenterFocusStrongIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                root: classes.listItemTextRoot,
                primary: classes.listItemText,
                secondary: !pADesignationsCount
                  ? classes.pmaAdminErrorText
                  : pADesignationsCount === Base.PMA_ADMIN.MIN_STRENGTH_COUNT
                  ? classes.pmaAdminBlueText
                  : classes.pmaAdminGreenText,
              }}
              primary="My Strengths / Designations"
              secondary={
                !pADesignationsCount
                  ? "Please add a Strength / Designation"
                  : pADesignationsCount === Base.PMA_ADMIN.MIN_STRENGTH_COUNT
                  ? "You can add more Strengths / Designations"
                  : "All Strengths / Designations added"
              }
            />
            <ListItemIcon
              className={classNames(classes.listItemIcon, classes.pmaAdminIcon)}
            >
              <Badge
                badgeContent={`${pADesignationsCount}`}
                className={classes.newBadge}
                classes={{
                  badge: !pADesignationsCount
                    ? classes.pmaAdminErrorBg
                    : pADesignationsCount === Base.PMA_ADMIN.MIN_STRENGTH_COUNT
                    ? classes.pmaAdminBlueBg
                    : classes.pmaAdminGreenBg,
                }}
              />
            </ListItemIcon>
          </ListItem> */}
          {/*<ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to={"/agent/property-highlights"}
            classes={{
              gutters: classes.listItemGutter,
            }}
            className={classes.importantTab}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <HighlightIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                root: classes.listItemTextRoot,
                primary: classes.listItemText,
              }}
              primary="My Property Highlights"
            />
          </ListItem>*/}

          {/* <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to={"/agent/myadvantages"}
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AdvantagesIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                root: classes.listItemTextRoot,
                primary: classes.listItemText,
                secondary: !pAAdvantagesCount
                  ? classes.pmaAdminErrorText
                  : pAAdvantagesCount === Base.PMA_ADMIN.MIN_ADVANTAGE_COUNT
                  ? classes.pmaAdminBlueText
                  : classes.pmaAdminGreenText
              }}
              primary="Neighborhood Advantages"
              secondary={
                !pAAdvantagesCount
                  ? "Please add an Advantage"
                  : pAAdvantagesCount === Base.PMA_ADMIN.MIN_ADVANTAGE_COUNT
                  ? "You can add more Advantages"
                  : "All Advantages added"
              }
            />
            <ListItemIcon
              className={classNames(classes.listItemIcon, classes.pmaAdminIcon)}
            >
              <Badge
                badgeContent={`${pAAdvantagesCount}`}
                classes={{
                  badge: !pAAdvantagesCount
                    ? classes.pmaAdminErrorBg
                    : pAAdvantagesCount === Base.PMA_ADMIN.MIN_ADVANTAGE_COUNT
                    ? classes.pmaAdminBlueBg
                    : classes.pmaAdminGreenBg
                }}
                className={classNames(
                  classes.newBadge,
                  classes.pmaAdminGreenText
                )}
              />
            </ListItemIcon>
          </ListItem> */}
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to={"/agent/clienttestimonials"}
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FeedbackIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                root: classes.listItemTextRoot,
                primary: classes.listItemText,
                secondary:
                  pATestimonialsCount > 0
                    ? classes.pmaAdminBlueText
                    : classes.pmaAdminErrorText,
              }}
              primary="Client Testimonials"
              secondary={
                pATestimonialsCount > 0
                  ? "You can add more Testimonials"
                  : "Please add a Testimonial"
              }
            />
            <ListItemIcon
              className={classNames(classes.listItemIcon, classes.pmaAdminIcon)}
            >
              <Badge
                badgeContent={`${pATestimonialsCount}`}
                //color="primary"
                classes={{
                  badge:
                    pATestimonialsCount > 0
                      ? classes.pmaAdminBlueBg
                      : classes.pmaAdminErrorBg,
                }}
                className={classes.newBadge}
              />
            </ListItemIcon>
          </ListItem>
          <Divider className={classes.profileDivider} />

          {/*<ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to={"/agent/roi/form"}
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FeedbackIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                root: classes.listItemTextRoot,
                primary: classes.listItemText
              }}
              primary="Agent ROI"
            />
          </ListItem>
          <Divider className={classes.profileDivider} />*/}

          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/account"
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Update Email or Password"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to={"/agent/details/ag/" + btoa(userData.listgenUserID)}
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Update Profile"
            />
          </ListItem>

          {/* <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            // to={'/agent/invoice/history/' + userData.listgenUserID}
            to={`/agent/invoice/history/${btoa(userData.listgenUserID)}/${btoa(
              null
            )}/${btoa(null)}/${btoa(null)}`}
             classes={{gutters: classes.listItemGutter}}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Invoice History"
            />
          </ListItem> */}

          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to={`/cards/credit/ag/${btoa(userData.listgenUserID)}/${btoa(
              null
            )}/${btoa(null)}/${btoa(null)}`}
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CreditCardIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Manage Credit Cards"
            />
          </ListItem>
          {/* //show only if session switch mode is on for agent  */}
          {/* {sessionSwitchMode ? (
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/session/switch"
               classes={{gutters: classes.listItemGutter}}
            >
              <ListItemIcon
                className={classes.listItemSpecialSessionSwitchIcon}
              >
                <LockSharpIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemSpecialSessionSwitchText }}
                primary="Session Switch"
                color="primary"
              />
            </ListItem>
          ) : (
              ''
            )} */}
        </List>
      ) : userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID ? (
        <List component="div" disablePadding>
          {/* <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/dashboard"
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Dashboard"
            />
          </ListItem> */}
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/agent/mailpro/insights"
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <NotesIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                root: classes.listItemTextRoot,
                primary: classes.listItemText,
              }}
              primary={<Fragment>Mailpro Insights</Fragment>}
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/agent/print/manage"
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ExportPrintIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Export & Print Management"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/onboarding/roi/invite/agents"
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CalendarTodayIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Invite Agents for ROI"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/admin/mailing/schedule"
            classes={{ gutters: classes.listItemGutter }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DateRangeIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Mailing Schedule / Calendar"
            />
          </ListItem>

          <Divider className={classes.listDivider} />
          {/* //only show these option is its related to admin not for county admin */}
          <Fragment>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/agent/search"
              classes={{ gutters: classes.listItemGutter }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Search Agents"
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/agent/pma/schedulesv1"
              classes={{ gutters: classes.listItemGutter }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <EventNoteIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary={"Agent PMA Schedules V1"}
              />
              <ListItemIcon style={{marginRight: '12px'}}>
                <Badge badgeContent={"New"} color="error" className={classes.newBadge} />
              </ListItemIcon>
            </ListItem>

            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/agent/create"
              classes={{ gutters: classes.listItemGutter }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Create Agent"
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/agent/help/request"
              classes={{ gutters: classes.listItemGutter }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Help Requests"
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/lg/constants"
              classes={{ gutters: classes.listItemGutter }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ConstantControlIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="LG Constants"
              />
            </ListItem>
          </Fragment>


          {/* <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/admin/hoasubdivion"
               classes={{gutters: classes.listItemGutter}}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <StreetViewIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ root: classes.listItemTextRoot,  primary: classes.listItemText }}
                primary={<Fragment>Hoa/Subdivison</Fragment>}
              />
              <ListItemIcon className={classes.listItemIcon}>
              <Badge badgeContent={"New"} color="error" className={classes.newBadge}/>
              </ListItemIcon>
            </ListItem> */}
          <Fragment>
            {/* <ListItem
                activeClassName={classes.activeListItem}
                className={classes.listItem}
                component={NavLink}
                to="/onboarding/invite/agents"
                classes={{ gutters: classes.listItemGutter }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <CalendarTodayIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Invite Agents for Presentations"
                />
              </ListItem> */}
            {/* <ListItem
                activeClassName={classes.activeListItem}
                className={classes.listItem}
                component={NavLink}
                to="/onboarding/presentation/agents"
                classes={{ gutters: classes.listItemGutter }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <TodayIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Scheduled Presentations"
                />
              </ListItem> */}
            {/* <ListItem
                activeClassName={classes.activeListItem}
                className={classes.listItem}
                component={NavLink}
                to="/onboarding/view/brokerages"
                classes={{ gutters: classes.listItemGutter }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <InsertInvitationIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="View Brokerages"
                />
              </ListItem> */}

          </Fragment>
          {/* <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/agent/areas-by-city"
             classes={{gutters: classes.listItemGutter}}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Areas By City"
            />
          </ListItem> */}
          {/* Nested List Code */}
          {/* {userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID ?
            <Fragment>
          <ListItem
            onClick={event =>
              handleClickNestedList(openReportsList, setOpenReportsList)
            }
            className={classes.listItem}
            activeClassName={classes.activeListItem}
            component={Link}
             classes={{gutters: classes.listItemGutter}}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ReportIcon />
            </ListItemIcon>
            <ListItemText
              primary="Reports"
              classes={{ primary: classes.listItemText }}
            />
            {openReportsList ? (
              <ExpandLessIcon color="primary" />
            ) : (
              <ExpandMoreIcon color="primary" />
            )}
          </ListItem>
          <Collapse in={openReportsList} timeout="auto" unmountOnExit>
            {reportNestedList.map((item, key) => {
              return (
                <List
                  component="div"
                  disablePadding
                  key={key}
                  className={classes.nestedList}
                >
                  <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={item.link}
                     classes={{gutters: classes.listItemGutter}}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <ReportIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      classes={{
                        primary: classNames(
                          classes.listItemText,
                          classes.listItemTextNested
                        )
                      }}
                    />
                  </ListItem>
                </List>
              );
            })}
          </Collapse>
          </Fragment>
          :  ''} */}
        </List>
      ) : ""}
      <Divider className={classes.listDivider} />
    </nav>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Sidebar);
// import React, { Component, Fragment, useState, useEffect } from "react";
// import { Link, NavLink } from "react-router-dom";
// import classNames from "classnames";
// import PropTypes from "prop-types";
// import { withStyles, Badge, Collapse } from "@material-ui/core";
// import {
//   Avatar,
//   Divider,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   ListSubheader,
//   Typography,
// } from "@material-ui/core";
// import {
//   DashboardOutlined as DashboardIcon,
//   PeopleOutlined as PeopleIcon,
//   ShoppingBasketOutlined as ShoppingBasketIcon,
//   LockOpenOutlined as LockOpenIcon,
//   TextFields as TextFieldsIcon,
//   ImageOutlined as ImageIcon,
//   InfoOutlined as InfoIcon,
//   AccountBoxOutlined as AccountBoxIcon,
//   SettingsOutlined as SettingsIcon,
//   Search as SearchIcon,
//   Home as HomeIcon,
//   Person as PersonIcon,
//   Help as HelpIcon,
//   LockSharp as LockSharpIcon,
//   CreditCard as CreditCardIcon,
//   History as HistoryIcon,
//   PageviewOutlined as PageviewIcon,
//   ControlPointDuplicate as ConstantControlIcon,
//   Notes as NotesIcon,
//   ListAlt as ListAltIcon,
//   Streetview as StreetViewIcon,
//   Assessment as ReportIcon,
//   ExpandLess as ExpandLessIcon,
//   ExpandMore as ExpandMoreIcon,
//   CenterFocusStrong as CenterFocusStrongIcon,
//   AddComment as FeedbackIcon,
//   AddToQueue as AdvantagesIcon,
//   InsertInvitation as InsertInvitationIcon,
//   CalendarToday as CalendarTodayIcon,
//   Today as TodayIcon,
//   Adjust as AdjustIcon,
//   RotateLeft as ResetIcon,
//   Highlight as HighlightIcon,
//   DateRange as DateRangeIcon,
//   EventNote as EventNoteIcon,
// } from "@material-ui/icons";
// import styles from "./styles";
// import { Store } from "Store";
// import Base from "common/constants";
//
// const Sidebar = (props) => {
//   const { state, dispatch } = React.useContext(Store);
//   const [openReportsList, setOpenReportsList] = useState(false);
//   const { userData } = state;
//
//   const { navbarInfo } = userData;
//   const firstAreaID = navbarInfo[0];
//   console.log("firstAreaID", firstAreaID);
//   console.log("userData", userData);
//   const { classes, className, sessionSwitchMode } = props;
//   const rootClassName = classNames(classes.root, className);
//   console.log("******userData1", userData);
//   /**logo */
//   const listgenLogo = "/images/listgen-logo.png";
//   const listgenAltLogo = "/images/listgen-text-advanced.PNG";
//   /**Nested Dropdowns */
//   let reportNestedList = [
//     // {
//     //   link: '/sales/report/brokerageperformance',
//     //   name: 'Brokerage Performance',
//     // },
//     // { link: '/sales/report/countyperformance', name: 'County Performance' },
//     // { link: '/sales/report/cityperformance', name: 'City Performance' },
//     { link: "/sales/report/agentperformance", name: "Agent Performance" },
//     // { link: '/sales/report/agentbehaviour', name: 'Agent Behaviour' },
//     // { link: '/sales/report/agentdetails', name: 'Agent Details' },
//   ];
//   /**Count PMA Admin Variables */
//   let pADesignationsCount = userData.designationsCount
//     ? userData.designationsCount
//     : 0;
//   let pAAdvantagesCount = userData.advantagesCount
//     ? userData.advantagesCount
//     : 0;
//   let pATestimonialsCount = userData.testimonialsCount
//     ? userData.testimonialsCount
//     : 0;
//   let pAMissionsCount = userData.missionStatementsCount
//     ? userData.missionStatementsCount
//     : 0;
//   let pAAreaCount = userData.navbarInfo ? userData.navbarInfo.length : 1;
//   let propertyHighlightsCount = 0;
//   //pAAdvantagesCount is area wise so divide by area count also
//   pAAdvantagesCount = parseInt(pAAdvantagesCount / pAAreaCount);
//
//   /**
//    * Methods
//    */
//
//   const handleClickNestedList = (openListVariable, setOpenFn) => {
//     setOpenFn(!openListVariable);
//   };
//
//   const isNestedListIsOpen = (reportVariable, setOpenFn) => {
//     let foundLink = reportVariable.find(
//       (item) => item.link === window.location.pathname
//     );
//     let isOpen = foundLink && Object.keys(foundLink).length > 0 ? true : false;
//     setOpenFn(isOpen);
//   };
//
//   useEffect(() => {
//     isNestedListIsOpen(reportNestedList, setOpenReportsList);
//   }, [reportNestedList]); //reportNestedList
//
//   return (
//     <nav className={rootClassName}>
//       <div className={classes.logoWrapper}>
//         <div className={classes.logoInner}>
//           <div className={classes.logoFirst}>
//             <Link className={classes.logoLink} to="/home">
//               <img
//                 alt="ListGen logo"
//                 className={classes.logoImage}
//                 src={listgenLogo}
//               />
//             </Link>
//           </div>
//           <div className={classes.logoSecond}>
//             <Link className={classes.logoLink} to="/home">
//               <img
//                 alt="ListGen logo"
//                 className={classes.logoImage}
//                 src={listgenAltLogo}
//               />
//             </Link>
//           </div>
//         </div>
//         {/* <Typography
//               className={classes.title}
//               variant="h4"
//             >
//               ListGen Dashboard
//             </Typography> */}
//       </div>
//       <Divider className={classes.logoDivider} />
//       {/* <div className={classes.profile}>
//       </div> */}
//       {/* <Divider className={classes.profileDivider} /> */}
//       <List component="div" disablePadding>
//         <ListItem
//           activeClassName={classes.activeListItem}
//           className={classes.listItem}
//           component={NavLink}
//           to="/home"
//           classes={{ gutters: classes.listItemGutter }}
//         >
//           <ListItemIcon className={classes.listItemIcon}>
//             <HomeIcon />
//           </ListItemIcon>
//           <ListItemText
//             classes={{ primary: classes.listItemText }}
//             primary="Home"
//           />
//         </ListItem>
//       </List>
//       <Divider className={classes.profileDivider} />
//       {userData.listgenRoleID === Base.APP_DATA.APP_AGENT_ROLE_ID ? (
//         <List component="div" disablePadding>
//           <ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to={`/pma/${navbarInfo[0].mlsAreaID}`}
//             classes={{ gutters: classes.listItemGutter }}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <DashboardIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{ primary: classes.listItemText }}
//               primary="View PMA"
//             />
//           </ListItem>
//           <ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to={`/mailpro/${navbarInfo[0].mlsAreaID}`}
//             classes={{ gutters: classes.listItemGutter }}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <DashboardIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{ primary: classes.listItemText }}
//               primary="View MailPro"
//             />
//           </ListItem>
//           {/* //designationsCount, advantagesCount, testimonialsCount
//           //Base.PMA_ADMIN.MIN_STRENGTH_COUNT
//           //Base.PMA_ADMIN.MAX_STRENGTH_COUNT
//           //Base.PMA_ADMIN.MIN_ADVANTAGE_COUNT
//           //Base.PMA_ADMIN.MAX_ADVANTAGE_COUNT */}
//           <ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to={"/agent/missionstatements"}
//             classes={{ gutters: classes.listItemGutter }}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <AdjustIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{
//                 root: classes.listItemTextRoot,
//                 primary: classes.listItemText,
//                 secondary:
//                   pAMissionsCount > 0
//                     ? classes.pmaAdminBlueText
//                     : classes.pmaAdminErrorText,
//               }}
//               primary="Mission Statements"
//               secondary={
//                 pAMissionsCount > 0
//                   ? "Add more Mission Statements"
//                   : "Please add a Mission Statement"
//               }
//             />
//             <ListItemIcon
//               className={classNames(classes.listItemIcon, classes.pmaAdminIcon)}
//             >
//               <Badge
//                 badgeContent={`${pAMissionsCount}`}
//                 //color="primary"
//                 classes={{
//                   badge:
//                     pAMissionsCount > 0
//                       ? classes.pmaAdminBlueBg
//                       : classes.pmaAdminErrorBg,
//                 }}
//                 className={classes.newBadge}
//               />
//             </ListItemIcon>
//           </ListItem>
//           <ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to={"/agent/mystrengths"}
//             classes={{ gutters: classes.listItemGutter }}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <CenterFocusStrongIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{
//                 root: classes.listItemTextRoot,
//                 primary: classes.listItemText,
//                 secondary: !pADesignationsCount
//                   ? classes.pmaAdminErrorText
//                   : pADesignationsCount === Base.PMA_ADMIN.MIN_STRENGTH_COUNT
//                   ? classes.pmaAdminBlueText
//                   : classes.pmaAdminGreenText,
//               }}
//               primary="My Strengths / Designations"
//               secondary={
//                 !pADesignationsCount
//                   ? "Please add a Strength / Designation"
//                   : pADesignationsCount === Base.PMA_ADMIN.MIN_STRENGTH_COUNT
//                   ? "You can add more Strengths / Designations"
//                   : "All Strengths / Designations added"
//               }
//             />
//             <ListItemIcon
//               className={classNames(classes.listItemIcon, classes.pmaAdminIcon)}
//             >
//               <Badge
//                 badgeContent={`${pADesignationsCount}`}
//                 className={classes.newBadge}
//                 classes={{
//                   badge: !pADesignationsCount
//                     ? classes.pmaAdminErrorBg
//                     : pADesignationsCount === Base.PMA_ADMIN.MIN_STRENGTH_COUNT
//                     ? classes.pmaAdminBlueBg
//                     : classes.pmaAdminGreenBg,
//                 }}
//               />
//             </ListItemIcon>
//           </ListItem>
//           {/*<ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to={"/agent/property-highlights"}
//             classes={{
//               gutters: classes.listItemGutter,
//             }}
//             className={classes.importantTab}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <HighlightIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{
//                 root: classes.listItemTextRoot,
//                 primary: classes.listItemText,
//
//
//               }}
//               primary="My Property Highlights"
//             />
//
//           </ListItem>*/}
//
//           {/* <ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to={"/agent/myadvantages"}
//             classes={{ gutters: classes.listItemGutter }}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <AdvantagesIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{
//                 root: classes.listItemTextRoot,
//                 primary: classes.listItemText,
//                 secondary: !pAAdvantagesCount
//                   ? classes.pmaAdminErrorText
//                   : pAAdvantagesCount === Base.PMA_ADMIN.MIN_ADVANTAGE_COUNT
//                   ? classes.pmaAdminBlueText
//                   : classes.pmaAdminGreenText
//               }}
//               primary="Neighborhood Advantages"
//               secondary={
//                 !pAAdvantagesCount
//                   ? "Please add an Advantage"
//                   : pAAdvantagesCount === Base.PMA_ADMIN.MIN_ADVANTAGE_COUNT
//                   ? "You can add more Advantages"
//                   : "All Advantages added"
//               }
//             />
//             <ListItemIcon
//               className={classNames(classes.listItemIcon, classes.pmaAdminIcon)}
//             >
//               <Badge
//                 badgeContent={`${pAAdvantagesCount}`}
//                 classes={{
//                   badge: !pAAdvantagesCount
//                     ? classes.pmaAdminErrorBg
//                     : pAAdvantagesCount === Base.PMA_ADMIN.MIN_ADVANTAGE_COUNT
//                     ? classes.pmaAdminBlueBg
//                     : classes.pmaAdminGreenBg
//                 }}
//                 className={classNames(
//                   classes.newBadge,
//                   classes.pmaAdminGreenText
//                 )}
//               />
//             </ListItemIcon>
//           </ListItem> */}
//           <ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to={"/agent/clienttestimonials"}
//             classes={{ gutters: classes.listItemGutter }}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <FeedbackIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{
//                 root: classes.listItemTextRoot,
//                 primary: classes.listItemText,
//                 secondary:
//                   pATestimonialsCount > 0
//                     ? classes.pmaAdminBlueText
//                     : classes.pmaAdminErrorText,
//               }}
//               primary="Client Testimonials"
//               secondary={
//                 pATestimonialsCount > 0
//                   ? "You can add more Testimonials"
//                   : "Please add a Testimonial"
//               }
//             />
//             <ListItemIcon
//               className={classNames(classes.listItemIcon, classes.pmaAdminIcon)}
//             >
//               <Badge
//                 badgeContent={`${pATestimonialsCount}`}
//                 //color="primary"
//                 classes={{
//                   badge:
//                     pATestimonialsCount > 0
//                       ? classes.pmaAdminBlueBg
//                       : classes.pmaAdminErrorBg,
//                 }}
//                 className={classes.newBadge}
//               />
//             </ListItemIcon>
//           </ListItem>
//           <Divider className={classes.profileDivider} />
//
//           {/*<ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to={"/agent/roi/form"}
//             classes={{ gutters: classes.listItemGutter }}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <FeedbackIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{
//                 root: classes.listItemTextRoot,
//                 primary: classes.listItemText
//               }}
//               primary="Agent ROI"
//             />
//           </ListItem>
//           <Divider className={classes.profileDivider} />*/}
//
//           <ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to="/account"
//             classes={{ gutters: classes.listItemGutter }}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <PersonIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{ primary: classes.listItemText }}
//               primary="Update Email or Password"
//             />
//           </ListItem>
//           <ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to={"/agent/details/ag/" + btoa(userData.listgenUserID)}
//             classes={{ gutters: classes.listItemGutter }}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <AccountBoxIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{ primary: classes.listItemText }}
//               primary="Update Profile"
//             />
//           </ListItem>
//
//           {/* <ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             // to={'/agent/invoice/history/' + userData.listgenUserID}
//             to={`/agent/invoice/history/${btoa(userData.listgenUserID)}/${btoa(
//               null
//             )}/${btoa(null)}/${btoa(null)}`}
//              classes={{gutters: classes.listItemGutter}}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <HistoryIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{ primary: classes.listItemText }}
//               primary="Invoice History"
//             />
//           </ListItem> */}
//
//           <ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to={`/cards/credit/ag/${btoa(userData.listgenUserID)}/${btoa(
//               null
//             )}/${btoa(null)}/${btoa(null)}`}
//             classes={{ gutters: classes.listItemGutter }}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <CreditCardIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{ primary: classes.listItemText }}
//               primary="Manage Credit Cards"
//             />
//           </ListItem>
//           {/* //show only if session switch mode is on for agent  */}
//           {/* {sessionSwitchMode ? (
//             <ListItem
//               activeClassName={classes.activeListItem}
//               className={classes.listItem}
//               component={NavLink}
//               to="/session/switch"
//                classes={{gutters: classes.listItemGutter}}
//             >
//               <ListItemIcon
//                 className={classes.listItemSpecialSessionSwitchIcon}
//               >
//                 <LockSharpIcon />
//               </ListItemIcon>
//               <ListItemText
//                 classes={{ primary: classes.listItemSpecialSessionSwitchText }}
//                 primary="Session Switch"
//                 color="primary"
//               />
//             </ListItem>
//           ) : (
//               ''
//             )} */}
//         </List>
//       ) : (
//         <List component="div" disablePadding>
//           <ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to="/dashboard"
//             classes={{ gutters: classes.listItemGutter }}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <DashboardIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{ primary: classes.listItemText }}
//               primary="Dashboard"
//             />
//           </ListItem>
//           {/* //only show these option is its related to admin not for county admin */}
//           {userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID ? (
//             <Fragment>
//               <ListItem
//                 activeClassName={classes.activeListItem}
//                 className={classes.listItem}
//                 component={NavLink}
//                 to="/agent/search"
//                 classes={{ gutters: classes.listItemGutter }}
//               >
//                 <ListItemIcon className={classes.listItemIcon}>
//                   <SearchIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   classes={{ primary: classes.listItemText }}
//                   primary="Search Agents"
//                 />
//               </ListItem>
//
//               <ListItem
//                 activeClassName={classes.activeListItem}
//                 className={classes.listItem}
//                 component={NavLink}
//                 to="/agent/print/manage"
//                 classes={{ gutters: classes.listItemGutter }}
//               >
//                 <ListItemIcon className={classes.listItemIcon}>
//                   <ResetIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   classes={{ primary: classes.listItemText }}
//                   primary="Print Management"
//                 />
//               </ListItem>
//
//
//               <ListItem
//                 activeClassName={classes.activeListItem}
//                 className={classes.listItem}
//                 component={NavLink}
//                 to="/admin/mailing/schedule"
//                 classes={{ gutters: classes.listItemGutter }}
//               >
//                 <ListItemIcon className={classes.listItemIcon}>
//                   <DateRangeIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   classes={{ primary: classes.listItemText }}
//                   primary="Mailing Schedule / Calendar"
//                 />
//               </ListItem>
//
//               <ListItem
//                 activeClassName={classes.activeListItem}
//                 className={classes.listItem}
//                 component={NavLink}
//                 to="/agent/create"
//                 classes={{ gutters: classes.listItemGutter }}
//               >
//                 <ListItemIcon className={classes.listItemIcon}>
//                   <PeopleIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   classes={{ primary: classes.listItemText }}
//                   primary="Create Agent"
//                 />
//               </ListItem>
//               <ListItem
//                 activeClassName={classes.activeListItem}
//                 className={classes.listItem}
//                 component={NavLink}
//                 to="/agent/help/request"
//                 classes={{ gutters: classes.listItemGutter }}
//               >
//                 <ListItemIcon className={classes.listItemIcon}>
//                   <HelpIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   classes={{ primary: classes.listItemText }}
//                   primary="Help Requests"
//                 />
//               </ListItem>
//               <ListItem
//                 activeClassName={classes.activeListItem}
//                 className={classes.listItem}
//                 component={NavLink}
//                 to="/lg/constants"
//                 classes={{ gutters: classes.listItemGutter }}
//               >
//                 <ListItemIcon className={classes.listItemIcon}>
//                   <ConstantControlIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   classes={{ primary: classes.listItemText }}
//                   primary="LG Constants"
//                 />
//               </ListItem>
//             </Fragment>
//           ) : (
//             ""
//           )}
//           <ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to="/agent/mailpro/insights"
//             classes={{ gutters: classes.listItemGutter }}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <NotesIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{
//                 root: classes.listItemTextRoot,
//                 primary: classes.listItemText,
//               }}
//               primary={<Fragment>Mailpro Insights</Fragment>}
//             />
//           </ListItem>
//
//           {/* <ListItem
//               activeClassName={classes.activeListItem}
//               className={classes.listItem}
//               component={NavLink}
//               to="/admin/hoasubdivion"
//                classes={{gutters: classes.listItemGutter}}
//             >
//               <ListItemIcon className={classes.listItemIcon}>
//                 <StreetViewIcon />
//               </ListItemIcon>
//               <ListItemText
//                 classes={{ root: classes.listItemTextRoot,  primary: classes.listItemText }}
//                 primary={<Fragment>Hoa/Subdivison</Fragment>}
//               />
//               <ListItemIcon className={classes.listItemIcon}>
//               <Badge badgeContent={"New"} color="error" className={classes.newBadge}/>
//               </ListItemIcon>
//             </ListItem> */}
//           {userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID ? (
//             <Fragment>
//               <ListItem
//                 activeClassName={classes.activeListItem}
//                 className={classes.listItem}
//                 component={NavLink}
//                 to="/onboarding/invite/agents"
//                 classes={{ gutters: classes.listItemGutter }}
//               >
//                 <ListItemIcon className={classes.listItemIcon}>
//                   <CalendarTodayIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   classes={{ primary: classes.listItemText }}
//                   primary="Invite Agents for Presentations"
//                 />
//               </ListItem>
//               <ListItem
//                 activeClassName={classes.activeListItem}
//                 className={classes.listItem}
//                 component={NavLink}
//                 to="/onboarding/presentation/agents"
//                 classes={{ gutters: classes.listItemGutter }}
//               >
//                 <ListItemIcon className={classes.listItemIcon}>
//                   <TodayIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   classes={{ primary: classes.listItemText }}
//                   primary="Scheduled Presentations"
//                 />
//               </ListItem>
//               <ListItem
//                 activeClassName={classes.activeListItem}
//                 className={classes.listItem}
//                 component={NavLink}
//                 to="/onboarding/view/brokerages"
//                 classes={{ gutters: classes.listItemGutter }}
//               >
//                 <ListItemIcon className={classes.listItemIcon}>
//                   <InsertInvitationIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   classes={{ primary: classes.listItemText }}
//                   primary="View Brokerages"
//                 />
//               </ListItem>
//               <ListItem
//                 activeClassName={classes.activeListItem}
//                 className={classes.listItem}
//                 component={NavLink}
//                 to="/onboarding/roi/invite/agents"
//                 classes={{ gutters: classes.listItemGutter }}
//               >
//                 <ListItemIcon className={classes.listItemIcon}>
//                   <CalendarTodayIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   classes={{ primary: classes.listItemText }}
//                   primary="Invite Agents for ROI"
//                 />
//               </ListItem>
//             </Fragment>
//           ) : (
//             ""
//           )}
//           {/* <ListItem
//             activeClassName={classes.activeListItem}
//             className={classes.listItem}
//             component={NavLink}
//             to="/agent/areas-by-city"
//              classes={{gutters: classes.listItemGutter}}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <ListAltIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{ primary: classes.listItemText }}
//               primary="Areas By City"
//             />
//           </ListItem> */}
//           {/* Nested List Code */}
//           {/* {userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID ?
//             <Fragment>
//           <ListItem
//             onClick={event =>
//               handleClickNestedList(openReportsList, setOpenReportsList)
//             }
//             className={classes.listItem}
//             activeClassName={classes.activeListItem}
//             component={Link}
//              classes={{gutters: classes.listItemGutter}}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <ReportIcon />
//             </ListItemIcon>
//             <ListItemText
//               primary="Reports"
//               classes={{ primary: classes.listItemText }}
//             />
//             {openReportsList ? (
//               <ExpandLessIcon color="primary" />
//             ) : (
//               <ExpandMoreIcon color="primary" />
//             )}
//           </ListItem>
//           <Collapse in={openReportsList} timeout="auto" unmountOnExit>
//             {reportNestedList.map((item, key) => {
//               return (
//                 <List
//                   component="div"
//                   disablePadding
//                   key={key}
//                   className={classes.nestedList}
//                 >
//                   <ListItem
//                     activeClassName={classes.activeListItem}
//                     className={classes.listItem}
//                     component={NavLink}
//                     to={item.link}
//                      classes={{gutters: classes.listItemGutter}}
//                   >
//                     <ListItemIcon className={classes.listItemIcon}>
//                       <ReportIcon />
//                     </ListItemIcon>
//                     <ListItemText
//                       primary={item.name}
//                       classes={{
//                         primary: classNames(
//                           classes.listItemText,
//                           classes.listItemTextNested
//                         )
//                       }}
//                     />
//                   </ListItem>
//                 </List>
//               );
//             })}
//           </Collapse>
//           </Fragment>
//           :  ''} */}
//         </List>
//       )}
//       <Divider className={classes.listDivider} />
//       {/* <List
//           component="div"
//           disablePadding
//           subheader={
//             <ListSubheader className={classes.listSubheader}>
//               Support
//             </ListSubheader>
//           }
//         >
//           <ListItem
//             className={classes.listItem}
//             component="a"
//             href="/contact"
//              classes={{gutters: classes.listItemGutter}}
//           >
//             <ListItemIcon className={classes.listItemIcon}>
//               <InfoIcon />
//             </ListItemIcon>
//             <ListItemText
//               classes={{ primary: classes.listItemText }}
//               primary="Customer Support"
//             />
//           </ListItem>
//         </List> */}
//     </nav>
//   );
// };
//
// Sidebar.propTypes = {
//   className: PropTypes.string,
//   classes: PropTypes.object.isRequired,
// };
//
// export default withStyles(styles)(Sidebar);
