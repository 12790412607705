import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";

class DataVerificationHeader extends Component {
  render() {
    const { mlsNeighborhood } = this.props.currentArea;
    const { marketActivityTable } = this.props.marketActivity;
    const marketStatuses = (marketActivityTable &&
      marketActivityTable.map(prop => prop.status)) || [
      "Sold",
      "Pending",
      "Active"
    ];
    const uniqueStatuses = [...new Set(marketStatuses)].length;
    const { splitAreas, slashSeparatedArea } = propertyUtil;
    const formattedArea = splitAreas(mlsNeighborhood).join("/");

    let allAreas;
    const areaSplit = splitAreas(mlsNeighborhood);
    const areaCount = areaSplit.length;
    allAreas = slashSeparatedArea(mlsNeighborhood);

    let spacedOutAreas;
    return (
      <div className="mq-second-header-body-container">
        <div className="mq-second-header-body-1">
          <h4>Market Activity</h4>
          <div className="padding-bw">
            {areaCount === 4 ? (
              <div>
                <h3>
                  {areaSplit[0]} / {areaSplit[1]} /
                </h3>
                <h3>
                  {areaSplit[2]} / {areaSplit[3]}
                </h3>
              </div>
            ) : areaCount === 3 ? (
              <div>
                <h3>
                  {areaSplit[0]} / {areaSplit[1]} /
                </h3>
                <h3>{areaSplit[2]}</h3>
              </div>
            ) : (
              <h3>{formattedArea}</h3>
            )}

            <h5>&nbsp;(your neighborhood)</h5>
          </div>
          <div
            style={{
              justifyContent: uniqueStatuses === 1 ? "center" : "space-around",

              paddingLeft:
                uniqueStatuses > 3
                  ? "-0.25em"
                  : uniqueStatuses === 3
                  ? "2.5em"
                  : uniqueStatuses === 2
                  ? "3.5em"
                  : null
            }}
            className="salesTableHeader"
          >
            {marketStatuses.includes("Sold") && (
              <span className="sold">SOLDS</span>
            )}
            {marketStatuses.includes("Pending") && (
              <span className="primary">PENDING SALES</span>
            )}
            {marketStatuses.includes("Contingent") && (
              <span className="primary">CONTINGENT SALES</span>
            )}
            {marketStatuses.includes("Active") && (
              <span className="active">ACTIVE LISTINGS</span>
            )}
          </div>
          <div className="arrow_box arrow-first" />
        </div>

        <div>
          <div className="mq-second-header-body-2">
            <h3>Recent Market Activity In Your Neighborhood</h3>
            <h4>{formattedArea}</h4>
          </div>

          <div className="arrow_box arrow-second" />
        </div>
      </div>
    );
  }
}

export default DataVerificationHeader;
